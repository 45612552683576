define('ember-components/user-profile/controller', ['exports', 'ember-cp-validations', 'ember-components/constants'], function (exports, _emberCpValidations, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var requiredValidator = (0, _emberCpValidations.validator)('presence', {
    presence: true,
    ignoreBlank: true,
    message: 'Required'
  });

  var Validations = (0, _emberCpValidations.buildValidations)({
    firstName: [requiredValidator, (0, _emberCpValidations.validator)('length', {
      min: 1,
      max: 30
    })],
    lastName: [requiredValidator, (0, _emberCpValidations.validator)('length', {
      min: 1,
      max: 30
    })]
  });

  exports.default = Ember.Controller.extend(Validations, {
    currentUser: Ember.inject.service(),
    store: Ember.inject.service(),
    firstName: Ember.computed.reads('userModel.first_name'),
    lastName: Ember.computed.reads('userModel.last_name'),
    isBuyerUsageSelected: Ember.computed('usage', function () {
      return this.get('usage') === _constants.default.USAGE_TYPE.BUYER;
    }),
    isFormValid: Ember.computed('buyerCompanyModel.validations.isValid', 'validations', function () {
      return this.get('buyerCompanyModel.validations.isValid') || this.get('validations.isValid');
    }),
    isBusinessInfoFormValid: Ember.computed('model.supplierCompanyModel.validations.isValid', 'userModel.isBuyer', 'isBuyerUsageSelected', function () {
      return this.get('model.supplierCompanyModel.validations.isValid') || this.get('userModel.isBuyer') || this.get('isBuyerUsageSelected');
    }),
    showBusinessInfoForm: Ember.computed('userModel.isBuyer', 'usage', function () {
      return !this.get('userModel.isBuyer') && !this.get('isBuyerUsageSelected');
    }),
    facilityDetailsUrl: window.localStorage ? window.localStorage.getItem(_constants.default.STORAGE_FACILITY_LINK) : ''
  });
});