define('ember-components/components/interactive/panels/messages/messages-panel/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    willUpdate: function willUpdate() {
      var _this = this;

      setTimeout(function () {
        $('#' + _this.get('elementId') + '-list').scrollTop(100000);
      }, 500);
    },
    didInsertElement: function didInsertElement() {
      this.willUpdate();
    },


    actions: {
      scrollTop: function scrollTop() {
        this.willUpdate();
      }
    }
  });
});