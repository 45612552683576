define('ember-components/ask-form/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  exports.default = Controller.extend({
    queryParams: ['facilityName', 'facilityCity', 'facilityId', 'isRequestTour'],

    latitude: 34.038173,
    longitude: -118.441237,
    adminMail: 'admin@warehouseexchange.com',
    phone: '424-239-2738',
    zoom: 15,
    infoWindowContent: ['We are headquartered in Los Angeles, CA.<br />', '<a target="_blank" href="https://www.google.com/', 'maps/search/34.038173,+-118.441237">Open in Goog', 'le Maps</a>'].join(''),
    circleRadius: 160,
    mapOptions: {
      zoomControl: false,
      mapTypeControl: false,
      fullscreenControl: false,
      streetViewControl: false,
      scaleControl: false
    }
  });
});