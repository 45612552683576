define("ember-components/utils/addSchemaToUrl", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (url) {
    var schema_delimiter_regexp = /(:\/\/)/g;

    return !schema_delimiter_regexp.test(url) ? "http://" + url : url;
  };
});