define('ember-components/components/presenter/tables/billing-history/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    columnsDefinitions: Ember.computed('userType', function () {
      return [{
        propertyName: 'id',
        title: 'Invoice Number',
        template: 'custom/billing-history/td/id',
        disableFiltering: true
      }, {
        propertyName: 'start_date',
        title: 'Billing Period',
        template: 'custom/billing-history/td/billing-period',
        disableFiltering: true
      }, {
        propertyName: 'formattedDueDate',
        title: 'Due Date',
        disableFiltering: true
      }, {
        propertyName: 'formattedTotal',
        title: 'Total',
        disableFiltering: true
      }, {
        propertyName: 'statusNormalize',
        title: 'Status',
        disableFiltering: true
      }, {
        propertyName: 'invoice_pdf_url',
        title: '',
        template: 'custom/billing-history/td/pdf-url',
        disableFiltering: true
      }];
    }),
    classes: {
      pageSizeWrapper: '',
      paginationWrapperNumeric: ''
    }
  });
});