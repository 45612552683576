define('ember-components/components/payment/check-payment-modal/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      computed = Ember.computed,
      get = Ember.get;
  exports.default = Component.extend({
    store: Ember.inject.service('store'),
    currentUser: Ember.inject.service(),

    userIsAdmin: computed('currentUser.user.company', function () {
      return get(this, 'currentUser.user.company.admin_user') === Number(get(this, 'currentUser.user.id'));
    }),

    addPayment: function addPayment() {
      var storageHub = this.get('storageHub');
      var facility = storageHub.get('facility');
      var route = Ember.getOwner(this).lookup('route:application');

      route.transitionTo('preferences-supplier', {
        queryParams: { from: facility.getWithDefault('id', 0) }
      });
    },
    sendRequest: function sendRequest(model) {
      this.sendAction('action', model);
    },


    actions: {
      ok: function ok() {
        if (this.get('userIsAdmin')) {
          this.addPayment();
        } else {
          this.sendRequest(this.get('model'));
        }
      }
    }
  });
});