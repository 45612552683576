define('ember-components/components/new-listing-details/facility-features/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      computed = Ember.computed,
      get = Ember.get;
  exports.default = Component.extend({
    amenitiesForklift: computed('facility.amenities_forklift', function () {
      var amenitiesForklift = get(this, 'facility.amenities_forklift');

      if (amenitiesForklift === 'available_for_fee') {
        return 'Yes (fee)';
      }
      if (amenitiesForklift === 'available') {
        return 'Yes (free)';
      }
      return 'No';
    })
  });
});