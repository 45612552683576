define('ember-components/components/business-information-form/component', ['exports', 'ember-components/utils/addSchemaToUrl'], function (exports, _addSchemaToUrl) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      service = Ember.inject.service,
      computed = Ember.computed,
      get = Ember.get;
  exports.default = Component.extend({
    store: service(),

    states: computed(function () {
      return get(this, 'store').peekAll('state');
    }),

    showTaxIdField: computed('model.business_tax_id_provided', 'account.business_tax_id_provided', function () {
      return !this.get('account.business_tax_id_provided') && !this.get('model.business_tax_id_provided');
    }),

    willDestroyElement: function willDestroyElement() {
      this.set('showErrors', false);
    },


    actions: {
      /**
       * Add protocol to the url if it was not specified
       */
      validateUrl: function validateUrl() {
        var isEmpty = !this.get('model.url').length;
        if (!isEmpty) {
          this.set('model.url', (0, _addSchemaToUrl.default)(this.get('model.url')));
        }
        this.set('showUrlError', true);
      }
    }
  });
});