define('ember-components/models/engagement-revision', ['exports', 'ember-data', 'ember-cp-validations', 'ember-api-actions'], function (exports, _emberData, _emberCpValidations, _emberApiActions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _emberCpValidations.buildValidations)({
    new_service_pallet_receiving_rate: [(0, _emberCpValidations.validator)('presence', { presence: Ember.computed.bool('model.engagement.service_pallet_receiving_rate') }), (0, _emberCpValidations.validator)('number', {
      allowString: true,
      gt: 0,
      disabled: Ember.computed.not('model.engagement.service_pallet_receiving_volume')
    }), (0, _emberCpValidations.validator)('decimal-places')],
    new_service_carton_receiving_rate: [(0, _emberCpValidations.validator)('presence', { presence: Ember.computed.bool('model.engagement.service_carton_receiving_rate') }), (0, _emberCpValidations.validator)('number', {
      allowString: true,
      gt: 0,
      disabled: Ember.computed.not('model.engagement.service_carton_receiving_volume')
    }), (0, _emberCpValidations.validator)('decimal-places')],
    new_service_carton_pick_rate: [(0, _emberCpValidations.validator)('presence', { presence: Ember.computed.bool('model.engagement.service_carton_pick_rate') }), (0, _emberCpValidations.validator)('number', {
      allowString: true,
      gt: 0,
      disabled: Ember.computed.not('model.engagement.service_carton_pick_volume')
    }), (0, _emberCpValidations.validator)('decimal-places')],
    new_service_pallet_pick_rate: [(0, _emberCpValidations.validator)('presence', { presence: Ember.computed.bool('model.engagement.service_pallet_pick_rate') }), (0, _emberCpValidations.validator)('number', {
      allowString: true,
      gt: 0,
      disabled: Ember.computed.not('model.engagement.service_pallet_pick_volume')
    }), (0, _emberCpValidations.validator)('decimal-places')],
    new_service_unit_pack_rate: [(0, _emberCpValidations.validator)('presence', { presence: Ember.computed.bool('model.engagement.service_unit_pack_rate') }), (0, _emberCpValidations.validator)('number', {
      allowString: true,
      gt: 0,
      disabled: Ember.computed.not('model.engagement.service_unit_pack_volume')
    }), (0, _emberCpValidations.validator)('decimal-places')],
    new_service_unit_pick_rate: [(0, _emberCpValidations.validator)('presence', { presence: Ember.computed.bool('model.engagement.service_unit_pick_rate') }), (0, _emberCpValidations.validator)('number', {
      allowString: true,
      gt: 0,
      disabled: Ember.computed.not('model.engagement.service_unit_pick_volume')
    }), (0, _emberCpValidations.validator)('decimal-places')],
    new_storage_original_rate: [(0, _emberCpValidations.validator)('presence', { presence: true }), (0, _emberCpValidations.validator)('number', {
      allowString: true,
      gt: 0
    }), (0, _emberCpValidations.validator)('decimal-places')]
  });

  exports.default = _emberData.default.Model.extend(Validations, {

    ratesChanged: Ember.computed('new_service_pallet_receiving_rate', 'new_service_carton_receiving_rate', 'new_service_carton_pick_rate', 'new_service_pallet_pick_rate', 'new_service_unit_pack_rate', 'new_service_unit_pick_rate', 'new_storage_original_rate', 'engagement', function () {
      if (this.get('new_service_pallet_receiving_rate') !== this.get('engagement.service_pallet_receiving_rate')) {
        return true;
      }
      if (this.get('new_service_carton_receiving_rate') !== this.get('engagement.service_carton_receiving_rate')) {
        return true;
      }
      if (this.get('new_service_carton_pick_rate') !== this.get('engagement.service_carton_pick_rate')) {
        return true;
      }
      if (this.get('new_service_pallet_pick_rate') !== this.get('engagement.service_pallet_pick_rate')) {
        return true;
      }
      if (this.get('new_service_unit_pack_rate') !== this.get('engagement.service_unit_pack_rate')) {
        return true;
      }
      if (this.get('new_service_unit_pick_rate') !== this.get('engagement.service_unit_pick_rate')) {
        return true;
      }
      if (this.get('new_storage_original_rate') !== this.get('engagement.storage_original_rate')) {
        return true;
      }
      return false;
    }),

    engagement: _emberData.default.belongsTo('engagement'),
    accepted_date: _emberData.default.attr('date'),
    declined_date: _emberData.default.attr('date'),
    new_service_pallet_receiving_rate: _emberData.default.attr('number'),
    new_service_carton_receiving_rate: _emberData.default.attr('number'),
    new_service_carton_pick_rate: _emberData.default.attr('number'),
    new_service_pallet_pick_rate: _emberData.default.attr('number'),
    new_service_unit_pack_rate: _emberData.default.attr('number'),
    new_service_unit_pick_rate: _emberData.default.attr('number'),
    new_storage_original_rate: _emberData.default.attr('number'),

    accept: (0, _emberApiActions.memberAction)({ path: 'accept/', type: 'put' }),
    decline: (0, _emberApiActions.memberAction)({ path: 'decline/', type: 'put' })
  });
});