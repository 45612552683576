define('ember-components/models/invoice', ['exports', 'ember-data', 'ember-api-actions', 'ember-cp-validations', 'ember-components/libs/computed/format-date', 'ember-components/libs/types-shims', 'ember-components/config/environment'], function (exports, _emberData, _emberApiActions, _emberCpValidations, _formatDate, _typesShims, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var MAX_TOTAL_VALUE = 999999;

  var Validations = (0, _emberCpValidations.buildValidations)({
    sent_to: (0, _emberCpValidations.validator)('presence', true),
    billing_address: (0, _emberCpValidations.validator)('presence', true),
    storages: (0, _emberCpValidations.validator)('has-many'),
    services: (0, _emberCpValidations.validator)('has-many'),
    totalSum: (0, _emberCpValidations.validator)('number', {
      allowString: true,
      integer: false,
      gt: 0,
      lte: MAX_TOTAL_VALUE
    }),
    start_date: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: "This fields can't be blank"
    })],
    end_date: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: "This fields can't be blank"
    }), (0, _emberCpValidations.validator)('date', {
      onOrAfter: Ember.computed.alias('model.start_date'),
      message: 'The end of the period must be on or after the start'
    })]
  });

  var computed = Ember.computed;

  var STATUSES = {
    ready: 'Unpaid',
    pending: 'Unpaid',
    late: 'Late',
    payment_processing: 'In process',
    paid: 'Paid',
    on_review: 'Revisions',
    invalid: 'Invalid'
  };

  function rangeZeroLeft(num) {
    var size = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 9;

    var strNum = String(num);
    return '0'.repeat(size - strNum.length) + strNum;
  }

  exports.default = _emberData.default.Model.extend(Validations, {
    intl: Ember.inject.service(),

    service_pallet_receiving: _typesShims.boolean,
    service_pallet_receiving_rate: _typesShims.number,
    service_pallet_receiving_volume: _emberData.default.attr('number', { defaultValue: 0 }),

    service_carton_receiving: _typesShims.boolean,
    service_carton_receiving_rate: _typesShims.number,
    service_carton_receiving_volume: _emberData.default.attr('number', { defaultValue: 0 }),

    service_carton_pick: _typesShims.boolean,
    service_carton_pick_rate: _typesShims.number,
    service_carton_pick_volume: _emberData.default.attr('number', { defaultValue: 0 }),

    service_pallet_pick: _typesShims.boolean,
    service_pallet_pick_rate: _typesShims.number,
    service_pallet_pick_volume: _emberData.default.attr('number', { defaultValue: 0 }),

    service_unit_pack: _typesShims.boolean,
    service_unit_pack_rate: _typesShims.number,
    service_unit_pack_volume: _emberData.default.attr('number', { defaultValue: 0 }),

    service_unit_pick: _typesShims.boolean,
    service_unit_pick_rate: _typesShims.number,
    service_unit_pick_volume: _emberData.default.attr('number', { defaultValue: 0 }),

    status: _typesShims.string,
    subtotal: _typesShims.number,
    payment_terms: _typesShims.string,
    created_date: _typesShims.string,
    due_date: _typesShims.string,
    start_date: _typesShims.string,
    end_date: _typesShims.string,
    invoice_date: _typesShims.string,
    payment_received_date: _typesShims.string,
    total: _emberData.default.attr('number', { defaultValue: 0 }),
    commission: _typesShims.number,
    app_fee_percent: _emberData.default.attr('number', { defaultValue: 0 }),
    days_count: _typesShims.number,
    payment_frequency: _typesShims.string,
    additional_services_fees: _typesShims.number,
    pallet_storage: _typesShims.number,
    storage_units: _emberData.default.attr('number', { defaultValue: 0 }),

    is_first: _typesShims.boolean,
    is_final: _typesShims.boolean,

    engagement: (0, _typesShims.belongsTo)('engagement'),
    engagement_id: _emberData.default.attr('number'),
    invoices_additional_services: (0, _typesShims.hasMany)('invoicesAdditionalServices'),
    attachments: (0, _typesShims.hasMany)('attachments'),
    services: (0, _typesShims.hasMany)('invoice-service'),
    storages: (0, _typesShims.hasMany)('invoice-storage'),
    service_fee: _emberData.default.attr('number', { defaultValue: 0 }),
    storage_fee: _emberData.default.attr('number', { defaultValue: 0 }),
    sent_to: _typesShims.string,
    billing_address: _typesShims.string,
    notes: _emberData.default.attr('string', { defaultValue: '' }),
    stripe_cc_commission: _emberData.default.attr('number', { defaultValue: 0 }),
    stripe_cc_commission_amount: _emberData.default.attr('number', { defaultValue: 0 }),
    invoice_pdf_url: _emberData.default.attr('string'),

    storagesHasErrors: computed.alias('validations.attrs.storages.isInvalid'),
    servicesHasErrors: computed.alias('validations.attrs.services.isInvalid'),

    relationshipsHasErrors: computed.or('storagesHasErrors', 'servicesHasErrors'),

    hasErrors: computed.alias('validations.isInvalid'),

    hasProratedStorage: computed('storages.@each.{isDeleted,is_prorated}', function () {
      var proratedStorage = this.get('storages').find(function (item) {
        if (item.get('isDeleted')) {
          return false;
        }
        return item.get('is_prorated') === true;
      });

      return proratedStorage !== undefined;
    }),

    hasProratedService: computed('services.@each.{isDeleted,is_prorated}', function () {
      var proratedService = this.get('services').find(function (item) {
        if (item.get('isDeleted')) {
          return false;
        }
        return item.get('is_prorated') === true;
      });

      return proratedService !== undefined;
    }),

    serviceFee: computed('services.@each.{isDeleted,sub_total,is_prorated,prorated_sub_total}', 'isReleased', function () {
      var totalSum = 0;

      this.get('services').forEach(function (item) {
        if (!item.get('isDeleted')) {
          var isProrated = item.get('is_prorated');
          if (isProrated) {
            totalSum += item.get('prorated_sub_total') || 0;
          } else {
            totalSum += item.get('sub_total') || 0;
          }
        }
      });

      var isReleased = this.get('isReleased');
      if (isReleased) {
        return Number(this.get('service_fee'));
      }
      return totalSum;
    }),

    storageFee: computed('storages.@each.{isDeleted,sub_total,is_prorated,prorated_sub_total}', 'isReleased', function () {
      var totalSum = 0;

      this.get('storages').forEach(function (item) {
        if (!item.get('isDeleted')) {
          var isProrated = item.get('is_prorated');
          if (isProrated) {
            totalSum += item.get('prorated_sub_total') || 0;
          } else {
            totalSum += item.get('sub_total') || 0;
          }
        }
      });

      var isReleased = this.get('isReleased');
      if (isReleased) {
        return Number(this.get('storage_fee'));
      }
      return totalSum;
    }),

    totalSum: computed('storageFee', 'serviceFee', 'isReleased', function () {
      var totalSum = this.get('serviceFee') + this.get('storageFee');

      var isReleased = this.get('isReleased');
      if (isReleased) {
        return Number(this.get('total'));
      }
      return totalSum;
    }),

    _formatNumber: function _formatNumber(propertyName) {
      return this.get('intl').formatNumber(this.get(propertyName), {
        style: 'currency',
        currency: 'USD'
      });
    },


    isReleased: computed('status', function () {
      var status = this.get('status');
      return ['pending', 'payment_processing', 'paid'].includes(status);
    }),

    totalServiceFees: computed('services_fees', 'additional_services_fees', function () {
      return this.get('services_fees') + this.get('additional_services_fees');
    }),

    // sendTo: computed.reads('sent_to'),
    facility: computed.reads('engagement.facility'),
    billingAddress: computed.reads('engagement.facility.address'),
    paymentTerms: computed.reads('engagement.facility.paymentTerms'),
    dueDate: computed.reads('created_date'),

    formattedDueDate: (0, _formatDate.default)('due_date'),
    formattedDueDateInvoice: (0, _formatDate.default)('due_date', _environment.default.date.dateSigned),

    formattedStartDate: (0, _formatDate.default)('start_date'),

    formattedEndDate: (0, _formatDate.default)('end_date'),

    createdDate: (0, _formatDate.default)('created_date'),

    invoiceDate: (0, _formatDate.default)('invoice_date'),

    statusLastChange: (0, _formatDate.default)('status_last_change', _environment.default.date.dateSigned),

    formattedPaymentReceivedDate: (0, _formatDate.default)('payment_received_date'),

    savedAttachments: Ember.computed.filterBy('attachments', 'isNew', false),

    formattedTotal: Ember.computed('total', function () {
      return this._formatNumber('total');
    }),

    zeroId: computed('facility.id', function () {
      var facilityId = this.get('id');
      if (facilityId) {
        return rangeZeroLeft(facilityId);
      }
    }),

    shortId: computed('id', function () {
      var id = this.get('id');
      return '' + rangeZeroLeft(id);
    }),

    // First three letters of city + Fac + id (9 цифр)
    contractorId: computed('facility.city', function () {
      var facilityCity = this.get('facility.city');
      if (facilityCity) {
        var id = this.get('engagement.id');
        var city = facilityCity.slice(0, 3);
        return city + 'Fac' + id;
      }
    }),

    statusNormalize: computed('status', function () {
      var status = this.get('status');
      if (status in STATUSES) {
        return STATUSES[status];
      }
    }),

    getLast: computed('engagement.invoices', function () {
      var invoices = this.get('engagement.invoices');
      return invoices.get('firstObject');
    }),

    save: function save() {
      var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      var attachments = this.get('attachments');
      var additional_services = this.get('invoices_additional_services');

      var _options = {
        adapterOptions: {
          additional_services: additional_services,
          attachments: attachments
        }
      };
      if (options.adapterOptions && options.adapterOptions.postFix) {
        _options.adapterOptions.postFix = options.adapterOptions.postFix;
      }
      if (options.adapterOptions && options.adapterOptions.type) {
        _options.adapterOptions.type = options.adapterOptions.type;
      }
      return this._super(_options);
    },


    pay: (0, _emberApiActions.memberAction)({ path: 'pay/', type: 'put' }),
    requestRevision: (0, _emberApiActions.memberAction)({ path: 'request-review/', type: 'put' }),

    additionalServices: computed.filterBy('invoices_additional_services', 'isNew', false),

    unitPickChanged: Ember.observer('service_unit_pick_volume', function () {
      if (!this.get('service_unit_pick_volume')) {
        this.set('service_unit_pack_volume', 0);
      }
    }),

    loadStorages: function loadStorages() {
      var _this = this;

      return this.store.query('invoice-storage', {
        invoice: this.get('id')
      }).then(function (result) {
        _this.set('storages', result);
      });
    },
    loadServices: function loadServices() {
      var _this2 = this;

      return this.store.query('invoice-service', {
        invoice: this.get('id')
      }).then(function (result) {
        _this2.set('services', result);
      });
    },
    loadInvoicePdfUrl: function loadInvoicePdfUrl(_ref) {
      var _this3 = this;

      var type = _ref.type;

      return this.store.findRecord('invoice-pdf-url', this.get('id'), {
        adapterOptions: { type: type }
      }).then(function (result) {
        _this3.set('invoice_pdf_url', result);
      });
    }
  });
});