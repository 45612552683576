define('ember-components/components/control/input/custom-radio-button/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  exports.default = Ember.Component.extend({
    tagName: '',
    store: service(),

    actions: {
      onChanged: function onChanged(newValue) {
        this.sendAction('changed', newValue);
      }
    }
  });
});