define('ember-components/helpers/format-volume', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.formatVolume = formatVolume;
  function formatVolume(params) {
    if (params[0] > 0) {
      return new Intl.NumberFormat('en-US', { maximumSignificantDigits: 3 }).format(params[0]);
    }
    return params[0];
  }

  exports.default = Ember.Helper.helper(formatVolume);
});