define('ember-components/components/interactive/modals/payment-confirmation/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    actions: {
      submit: function submit(value) {
        this.sendAction('handlerSubmit', value);
      },
      close: function close() {
        this.set('open', false);
      }
    }
  });
});