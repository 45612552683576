define('ember-components/adapters/service', ['exports', 'ember-components/adapters/application', 'ember-components/config/environment'], function (exports, _application, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    namespace: '' + _environment.default.APP.API_NAMESPACE,

    urlForQuery: function urlForQuery() {
      var baseUrl = this.buildURL();

      return baseUrl + 'invoices/service';
    }
  });
});