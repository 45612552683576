define('ember-components/components/facility-form/facility-located/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    actions: {

      /**
       * Select
       *
       * Makes responsible parent component
       *
       * @param {object} selected
       * @return {void}
       * @public
       */
      select: function select(selected) {
        this.sendAction('select', selected);
      }
    }
  });
});