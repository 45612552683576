define('ember-components/serializers/invoice', ['exports', 'ember-components/serializers/drf'], function (exports, _drf) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _drf.default.extend({
    configProvider: Ember.inject.service(),

    serialize: function serialize(snapshot) {
      var json = this._super.apply(this, arguments);
      var configProvider = this.get('configProvider');
      var defaultFormat = configProvider.get('dateFormat');

      if (!json.attachments && snapshot.adapterOptions.attachments) {
        json.attachments = snapshot.adapterOptions.attachments.map(function (item) {
          return item.get('id');
        });
      }
      delete json.total;
      delete json.service_fee;
      delete json.storage_fee;
      delete json.engagement_id;

      // Remove nulls
      json.attachments = json.attachments.filter(Boolean);

      if (!json.additional_services && snapshot.adapterOptions.additional_services) {
        json.additional_services = snapshot.adapterOptions.additional_services.map(function (item) {
          return item.get('id');
        }).filter(function (item) {
          return Boolean(item);
        });
      }
      if (json.start_date) {
        json.start_date = moment.utc(json.start_date).format(defaultFormat);
      }
      if (json.end_date) {
        json.end_date = moment.utc(json.end_date).format(defaultFormat);
      }
      if (json.invoice_date) {
        json.invoice_date = moment.utc(json.invoice_date).format(defaultFormat);
      }
      return json;
    },
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, _id) {
      /* eslint-disable no-param-reassign */
      if (!payload.service_pallet_receiving_volume) {
        payload.service_pallet_receiving_volume = 0;
      }
      if (!payload.service_carton_receiving_volume) {
        payload.service_carton_receiving_volume = 0;
      }
      if (!payload.service_carton_pick_volume) {
        payload.service_carton_pick_volume = 0;
      }
      if (!payload.service_pallet_pick_volume) {
        payload.service_pallet_pick_volume = 0;
      }
      if (!payload.service_unit_pack_volume) {
        payload.service_unit_pack_volume = 0;
      }
      if (!payload.service_unit_pick_volume) {
        payload.service_unit_pick_volume = 0;
      }
      if (!payload.storage_units) {
        payload.storage_units = 0;
      }
      if (payload.additional_services) {
        payload.invoice_additional_services = payload.additional_services;
        payload.invoice_additional_services.map(function (item) {
          return store.findRecord('invoicesAdditionalService', item);
        });
        delete payload.additional_services;
      }
      return this._super.apply(this, arguments);
    },
    normalizeQueryResponse: function normalizeQueryResponse(store, primaryModelClass, payload) {
      payload.invoice.forEach(function (item) {
        item.engagement_id = item.engagement;
      });
      return this._super.apply(this, arguments);
    }
  });
});