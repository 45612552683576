define('ember-components/helpers/format-price', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.extend({
    compute: function compute(price) {
      Ember.warn('Warnin! This method is deprecated. Please usage https://github.com/cibernox/ember-cli-accounting', false, { id: Ember.guidFor({}) });
      return '$' + (price[0] || 0).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
    }
  });
});