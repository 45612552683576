define('ember-components/components/dashboard-notifications-supplier/component', ['exports', 'ember-components/mixins/end-engagement-notifications'], function (exports, _endEngagementNotifications) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      computed = Ember.computed;
  exports.default = Component.extend(_endEngagementNotifications.default, {
    customEngagements: computed('model.requestedEngagements', function () {
      var requestedEngagements = this.get('model.requestedEngagements');
      return requestedEngagements.filterBy('supplier_sign_date', null).sortBy('id');
    }),
    engagementsAmendments: computed('model.requestedAmendments', function () {
      var requestedAmendments = this.get('model.requestedAmendments');
      return requestedAmendments.toArray();
    }),

    isNotificationsShown: computed('defaultCard', 'currentUser.user.company.name', 'customEngagements', 'engagementsAmendments', function () {
      return this.get('defaultCard') == null || this.get('currentUser.user.company.name') == null || this.get('currentUser.user.company.name') === '' || this.get('customEngagements').length > 0 || this.get('engagementsAmendments').length > 0 || this.get('ableToTerminateEngagements').length > 0 || this.get('inTerminationEngagements').length > 0 || this.get('afterTerminationEngagements').length > 0 || this.get('beforeEndingEngagements').length > 0;
    })
  });
});