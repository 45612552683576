define('ember-components/adapters/application', ['exports', 'ember-simple-auth/mixins/data-adapter-mixin', 'ember-components/adapters/drf', 'ember-components/config/environment'], function (exports, _dataAdapterMixin, _drf, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      service = Ember.inject.service,
      get = Ember.get;
  exports.default = _drf.default.extend(_dataAdapterMixin.default, {
    namespace: '' + _environment.default.APP.API_NAMESPACE,

    authorizer: 'authorizer:token',

    /**
     * Cookies service
     *
     * @property cookies
     * @type {object}
     * @public
     */
    cookies: service(),

    session: service(),

    /**
     * Add CSRF token to headers
     *
     * @property headers
     * @type {Ember.computed}
     * @public
     */
    headers: computed(function () {
      var cookies = get(this, 'cookies');
      var token = cookies.read('csrftoken');
      var authToken = this.get('session.data.authenticated.key');

      var headers = {};

      if (token) {
        headers['X-CSRFToken'] = token;
      }

      if (authToken) {
        headers.Authorization = 'token ' + authToken;
      }

      return headers;
    })
  });
});