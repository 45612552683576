define('ember-components/services/upload-file-service', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    uploadSingleFile: function uploadSingleFile(event) {
      var files = event.target.files;
      var selectedFile = files[0];
      var reader = new FileReader();

      reader.readAsDataURL(selectedFile);

      return new Promise(function (resolve) {
        reader.onload = function (readerEvent) {
          resolve([selectedFile, readerEvent]);
        };
      });
    }
  });
});