define('ember-components/models/amendment', ['exports', 'ember-data', 'ember-api-actions', 'ember-components/mixins/model-validator', 'ember-components/mixins/validators/engagement-model', 'ember-components/config/environment'], function (exports, _emberData, _emberApiActions, _modelValidator, _engagementModel, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr,
      hasMany = _emberData.default.hasMany,
      belongsTo = _emberData.default.belongsTo;
  var computed = Ember.computed;
  exports.default = _emberData.default.Model.extend(_modelValidator.default, _engagementModel.default, {
    PAYMENT_TERMS: {
      default: 'Due upon Acceptance',
      net_7: 'Net 7',
      net_15: 'Net 15',
      net_30: 'Net 30',
      net_60: 'Net 60'
    },

    STATUSES: {
      pending: 'Pending',
      buyer_signed: 'Buyer Signed',
      supplier_signed: 'Supplier Signed',
      approved: 'Approved',
      rejected: 'Rejected',
      canceled: 'Canceled'
    },

    engagement: attr('number'),
    engagement_data: belongsTo('amendment-engagement-data'),
    description: attr('string'),

    storage_uom: attr('string'),
    storage_units: attr('number', { defaultValue: 0 }),
    storage_type: attr('string'),
    storage_original_rate: attr('number'),
    usage: attr('string'),

    storage_fee: attr('number'),
    monthly_fee: attr('number'),

    buyer_app_fee: attr('number'),
    buyer_app_fee_percent: attr('number'),
    buyer_total_fee: attr('number'),
    supplier_app_fee: attr('number'),
    supplier_app_fee_percent: attr('number'),
    supplier_total_fee: attr('number'),

    first_month_invoice: attr('number'),
    buyer_first_month_fee: attr('number'),
    supplier_first_month_fee: attr('number'),
    buyer_due_today_total: attr('number'),
    supplier_due_today_total: attr('number'),

    stripe_cc_commission: attr('number', { defaultValue: 0 }),
    stripe_cc_commission_amount: attr('number', { defaultValue: 0 }),

    deposit: attr('number'),

    // Only Buyer API returns this property.
    previous_deposit: attr('number'),

    start: attr('string'),
    end: attr('string'),

    is_agreed: attr('boolean', { defaultValue: false }),
    status: attr('string'),

    startFormatted: computed('start', function () {
      return this._formatDate('start');
    }),

    endFormatted: computed('end', function () {
      return this._formatDate('end');
    }),

    paymentTerms: computed('engagement_data.payment_terms', function () {
      return this.PAYMENT_TERMS[this.get('engagement_data.payment_terms')];
    }),

    rentPerMonth: computed(function () {
      return Number((this.get('storage_original_rate') * this.get('storage_units')).toFixed(2));
    }),

    securityDeposit: computed('deposit', 'rentPerMonth', function () {
      var deposit = this.get('deposit');
      if (deposit !== undefined) {
        return deposit;
      }
      return this.get('rentPerMonth');
    }),

    titledStatus: computed('status', function () {
      return this.STATUSES[this.get('status')];
    }),

    persistent_services: hasMany('persistent_services'),
    persistent_services_fee: attr('number'),
    monthly_persistent_services_fee: attr('number', { defaultValue: 0 }),

    changed_fields: belongsTo('changed-fields'),
    changed_persistent_services: belongsTo('changed-persistent-services'),

    _formatDate: function _formatDate(field) {
      return moment.utc(this.get(field)).format(_environment.default.date.engagementFormat);
    },
    changeStatus: function changeStatus(type, status) {
      var reason = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;

      var action = (0, _emberApiActions.memberAction)({
        path: status + '/?type=' + type,
        type: 'post'
      }).bind(this);

      return action({ reason: reason });
    },
    rejectAmendment: function rejectAmendment(reason) {
      return this.changeStatus('supplier', 'reject', reason);
    },
    signBySupplier: function signBySupplier() {
      return this.changeStatus('supplier', 'approve');
    },
    signByBuyer: function signByBuyer() {
      return this.changeStatus('buyer', 'approve');
    }
  });
});