define('ember-components/components/bootstrap-modal-payment/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    actions: {
      processCard: function processCard(card) {
        this.sendAction('processCard', card);
      }
    }
  });
});