define('ember-components/models/account-file', ['exports', 'ember-data', 'ember-cp-validations'], function (exports, _emberData, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _emberCpValidations.buildValidations)({
    upload: [(0, _emberCpValidations.validator)('image-format'), (0, _emberCpValidations.validator)('image-size')]
  });

  exports.default = _emberData.default.Model.extend(Validations, {
    upload: _emberData.default.attr()
  });
});