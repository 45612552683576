define('ember-components/components/volumes-range-slider/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  exports.default = Ember.Component.extend({
    classNames: ['form-group', 'form-group-dropdown', 'form-group-dropdown-slider'],

    didInsertElement: function didInsertElement() {
      this.set('valueStart', 0);
      if (this.get('valueEnd') == null) {
        this.set('isNull', true);
      }
    },


    actions: {
      setInput: function setInput(value) {
        if (value > 0) {
          this.set('hasvolumeError', false);
          this.set('isNull', false);
        } else {
          this.set('hasvolumeError', true);
          this.set('isNull', true);
        }
      }
    }
  });
});