define('ember-components/home/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route,
      service = Ember.inject.service;
  exports.default = Route.extend({
    headData: service(),

    afterModel: function afterModel() {
      this.set('headData.title', 'Warehouse Exchange - Your Virtual Place for Space | Search & List Available Warehouse Space');
      this.set('headData.description', 'Warehouse Exchange is an online warehousing marketplace that connects warehouses for rent and lease with businesses in need for available warehouse space. Search & List Available On Demand Warehouses.');
      this.set('headData.keywords', 'Rent warehouse space, lease warehouse space, list warehouse list, warehouse marketing, list rental online, warehouse marketplace, find private renters, list my warehouse, flex warehouse space, on demand warehouse, short term warehouse, warehouse for rent near me, Search warehouses, find tenants, flex warehouse, 3pl warehouses, on demand warehouses, warehouse exchange');
    }
  });
});