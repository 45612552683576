define('ember-components/resources/privacy-policy/route', ['exports', 'ember-components/libs/base/route'], function (exports, _route) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _route.default.extend({
    model: function model() {
      return Ember.RSVP.hash({
        termsOfService: this.store.queryRecord('privacy-policy', {})
      });
    }
  });
});