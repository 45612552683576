define('ember-components/components/interactive/forms/draggable-uploader/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({

    /**
     * Prevent default drag over action
     *
     * @method dragOver
     * @param {Object} event
     * @return {void}
     * @public
     */
    dragOver: function dragOver(event) {
      event.preventDefault();
    },


    /**
     * Send action to external component
     *
     * @method drop
     * @param {Object} event
     * @return {void}
     * @public
     */
    drop: function drop(event) {
      this.sendAction('action', event);
      event.preventDefault();
    }
  });
});