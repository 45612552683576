define('ember-components/adapters/bank-account', ['exports', 'ember-components/adapters/card'], function (exports, _card) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _card.default.extend({
    pathForType: function pathForType() {
      return 'banks';
    }
  });
});