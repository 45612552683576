define('ember-components/adapters/seo-city', ['exports', 'ember-components/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    urlForQuery: function urlForQuery() {
      var baseUrl = this.buildURL();

      return baseUrl + 'seo/cities';
    }
  });
});