define('ember-components/components/control/input/invoice/service-input-wrap/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      service = Ember.inject.service;
  exports.default = Ember.Component.extend({
    store: service(),
    classNames: ['row'],
    services: computed.alias('invoice.services'),
    hasErrors: computed.alias('invoice.servicesHasErrors'),

    actions: {
      addService: function addService() {
        var services = this.get('services');

        var newService = this.get('store').createRecord('invoice-service', {
          invoice: this.get('invoice.id'),
          is_prorated: false,
          prorated_quantity: 0
        });

        services.pushObject(newService);
      }
    }
  });
});