define('ember-components/adapters/move-out-photo', ['exports', 'ember-components/adapters/application', 'ember-components/config/environment'], function (exports, _application, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    namespace: _environment.default.APP.API_NAMESPACE + '/engagements',

    prepareMoveOutPhoto: function prepareMoveOutPhoto(snapshot) {
      var formData = new FormData();
      formData.append('name', snapshot.record.get('name'));
      formData.append('description', snapshot.record.get('description'));
      formData.append('move_out', snapshot.record.get('moveOut'));

      return formData;
    },
    prepareMoveOutPhotoCreation: function prepareMoveOutPhotoCreation(snapshot) {
      var formData = this.prepareMoveOutPhoto(snapshot);
      formData.append('file', snapshot.record.get('file'));

      return formData;
    },
    prepareMoveOutPhotoUpdation: function prepareMoveOutPhotoUpdation(snapshot) {
      var file = snapshot.record.get('file');
      var isReplacedByNewFile = file instanceof File;
      var formData = this.prepareMoveOutPhoto(snapshot);

      if (isReplacedByNewFile) {
        formData.append('file', snapshot.record.get('file'));
      }

      return formData;
    },
    createRecord: function createRecord(store, type, snapshot) {
      var baseUrl = this.buildURL();
      var url = baseUrl + 'move-out-photos/';
      var formData = this.prepareMoveOutPhotoCreation(snapshot);

      return $.ajax({
        type: 'POST',
        url: url,
        headers: this.get('headers'),
        contentType: false,
        processData: false,
        data: formData
      });
    },
    updateRecord: function updateRecord(store, type, snapshot) {
      var baseUrl = this.buildURL();
      var url = baseUrl + 'move-out-photos/' + snapshot.record.get('id') + '/';
      var formData = this.prepareMoveOutPhotoUpdation(snapshot);

      return $.ajax({
        type: 'PATCH',
        url: url,
        headers: this.get('headers'),
        contentType: false,
        processData: false,
        data: formData
      });
    }
  });
});