define('ember-components/components/clearable-date-range-picker/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      computed = Ember.computed;

  var DateStringFormat = 'YYYY-MM-DD';

  exports.default = Component.extend({
    overlay: Ember.inject.service(),

    _datePicker: null,

    disabled: false,

    isOpened: false,

    isShowLabel: true,

    showError: false,

    placeholder: 'Enter Date Range',

    dropDownedClass: computed('dropdowned', 'showError', function () {
      var dropdowned = this.get('dropdowned');
      var classes = dropdowned ? 'btn-parent-daterange2 dropdown-toggle' : '';
      var showError = this.get('showError') ? 'has-error' : '';
      return 'form-control ' + classes + ' ' + showError;
    }),

    /**
     * Pass this option if you want to insert custom template at the bottom of the calendar.
     * @example
     * ```html
     * {{clearable-date-range-picker calendarBottomTemplateSelector="calendar-availability-template"}}
     * <div class="calendar-availability-template">
     *   <!-- Template. -->
     * </div>
     * ```
     */
    calendarBottomTemplateSelector: '',

    /** Class names for ``ember-view`` wrapper. */
    classNames: ['form-group', 'has-feedback', 'ico-left'],

    /** Initialize jQuery daterangepicker component. */
    didInsertElement: function didInsertElement() {
      var _this = this;

      var self = this;
      var el = $(this.$('[data-role="daterangepicker"]')).daterangepicker({
        autoApply: true,
        autoUpdateInput: false,
        isCustomDate: function isCustomDate(date) {
          // Make custom class as date to be able to use it in query selector.
          return date.format(DateStringFormat);
        },
        isInvalidDate: function isInvalidDate(date) {
          var availableDates = self.get('availableDates');
          if (availableDates) {
            return !availableDates.some(function (availableDate) {
              return availableDate.format('YYYY-MM-DD') === date.format('YYYY-MM-DD');
            });
          }
        },

        startDate: this.get('startDate') || moment(),
        endDate: this.get('endDate'),
        minDate: this.get('minDate'),
        maxDate: this.get('maxDate')
      }, this.handleDateChange.bind(this));

      this._datePicker = el.data('daterangepicker');

      $('[data-role="daterangepicker"]').on('hide.daterangepicker', function () {
        var overlaySearch = _this.get('overlay.search');
        overlaySearch.hide();
        _this.sendAction('focusOutAction');
      });

      $('[data-role="daterangepicker"]').on('show.daterangepicker', function () {
        if (!_this.get('calendarBottomTemplateSelector')) {
          return;
        }
        var calendar = _this._datePicker.container;
        var calendarBottomTemplate = document.querySelector('.' + _this.get('calendarBottomTemplateSelector'));
        var bottomContainerClassName = 'daterangepicker__bottom-container';
        if (!calendar.find('.' + bottomContainerClassName)[0] && calendarBottomTemplate) {
          calendarBottomTemplate.style.clear = 'both';
          calendarBottomTemplate.style.display = 'block';
          calendarBottomTemplate.classList.add(bottomContainerClassName);
          calendar.append(calendarBottomTemplate);
        }
      });

      if (this.get('week-selection')) {
        this._setWeekSelection();
      }

      if (!this.get('startDate') || !this.get('endDate')) {
        $('[data-role="daterangepicker"]').val(this.placeholder);
      } else {
        this._datePicker.setStartDate(moment(this.get('startDate')));
        this._datePicker.setEndDate(moment(this.get('endDate')));
        this.set('showClearIcon', true);
        $('[data-role="daterangepicker"]').val(moment(this.get('startDate')).format('MM/DD/YYYY') + ' - ' + moment(this.get('endDate')).format('MM/DD/YYYY'));
      }
    },
    willDestroyElement: function willDestroyElement() {
      this._datePicker.container.remove();
    },
    handleDateChange: function handleDateChange(start, end) {
      if (start && start.isValid() && end && end.isValid()) {
        this.set('startDate', start);
        this.set('endDate', end);
        this.set('showClearIcon', true);
        $('[data-role="daterangepicker"]').val(moment(start).format('MM/DD/YYYY') + ' - ' + moment(end).format('MM/DD/YYYY'));
      }
    },


    /** Fix internal component behavior to select only weeks. */
    _setWeekSelection: function _setWeekSelection() {
      // Override default clickDate behavior to select start or end of week.
      this._datePicker.container.find('.calendar').off('mousedown.daterangepicker');
      this._datePicker.container.find('.calendar').on('mousedown.daterangepicker', 'td.available', $.proxy(this._weekClickDate, this._datePicker, this));

      // Override default hoverDate behavior to highlight whole week.
      this._datePicker.container.find('.calendar').off('mouseenter.daterangepicker');
      this._datePicker.container.find('.calendar').on('mouseenter.daterangepicker', 'td.available', $.proxy(this._weekHoverDate, this._datePicker, this));

      // Make input read only to do not allow set not week align range.
      this.$('[data-role="daterangepicker"]').prop('readonly', true);
    },
    _weekClickDate: function _weekClickDate(self, e) {
      self._datePicker.clickDate.call(this, e);
      if (!$(e.target).hasClass('available')) {
        return;
      }
      if (this.startDate) {
        this.setStartDate(this.startDate.startOf('week'));
      }
      if (this.endDate) {
        this.setEndDate(this.endDate.endOf('week'));
      }
      this.callback(this.startDate, this.endDate, this.chosenLabel);

      this.updateView();
    },
    _weekHoverDate: function _weekHoverDate(self, e) {
      self._datePicker.hoverDate.call(this, e); // virtual method call
      if (!$(e.target).hasClass('available') || !this.endDate) {
        return;
      }
      var title = $(e.target).attr('data-title');
      var row = title.substr(1, 1);
      var col = title.substr(3, 1);
      var cal = $(e.target).parents('.calendar');
      var date = cal.hasClass('left') ? this.leftCalendar.calendar[row][col] : this.rightCalendar.calendar[row][col];
      var originalDate = date.clone();

      // Reset whole calendar.
      this.container.find('.calendar tbody td').each(function (index, el) {
        $(el).removeClass('daterangepicker-dt-bg-grey');
      });
      // Highlight whole week.
      var startOfWeek = originalDate.clone().startOf('week');
      var currentDate = originalDate.clone().startOf('day');
      while (startOfWeek.diff(currentDate, 'seconds') !== 0) {
        currentDate.add(-1, 'day');
        this.container.find('td.' + currentDate.format(DateStringFormat)).addClass('daterangepicker-dt-bg-grey');
      }
      var endOfWeek = originalDate.clone().endOf('week').add(-1, 'day');
      currentDate = originalDate.clone().startOf('day');
      while (endOfWeek.diff(currentDate, 'seconds') !== 0) {
        currentDate.add(1, 'day');
        this.container.find('td.' + currentDate.format(DateStringFormat)).addClass('daterangepicker-dt-bg-grey');
      }
    },


    actions: {
      clearDateRange: function clearDateRange() {
        $('[data-role="daterangepicker"]').val(this.get('placeholder'));
        this.set('startDate', null);
        this.set('endDate', null);
        this.set('showClearIcon', false);
        this._datePicker.setStartDate(moment());
        this._datePicker.setEndDate(moment());
      }
    }

  });
});