define('ember-components/components/interactive/modals/base-modal-with-logo/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    submitButtonText: 'Submit',
    cancelButtonText: 'Cancel',

    actions: {
      submit: function submit() {
        this.set('open', false);
        this.sendAction('handleSubmit');
      },
      close: function close() {
        this.set('open', false);
      }
    }
  });
});