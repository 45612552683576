define('ember-components/components/presenter/engagement-dashboard/tracker/component', ['exports', 'ember-components/libs/computed/format-date'], function (exports, _formatDate) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;


  var receiving = ['service_pallet_receiving', 'service_carton_receiving'];
  var outbound = ['service_carton_pick', 'service_pallet_pick', 'service_unit_pack', 'service_unit_pick'];

  var mapConvert = {
    service_pallet_receiving: 'Pallet Receipts',
    service_carton_receiving: 'Carton Receipts',
    service_carton_pick: 'Carton Shipments',
    service_pallet_pick: 'Pallet Shipments',
    service_unit_pack: 'Unit Pack',
    service_unit_pick: 'Unit Pick'
  };

  function computedFilterServices(target) {
    return computed('model', 'responseModel', function () {
      var responseModel = this.get('responseModel');
      var model = this.get('model');
      if (!responseModel) {
        return [];
      }
      return target.map(function (item) {
        var expected = responseModel.services_volumes.expected[item];
        var actual = responseModel.services_volumes.actual[item];
        return {
          title: mapConvert[item],
          write: model.get(item),
          expected: expected,
          actual: actual
        };
      }).filter(function (item) {
        return item.write === true;
      });
    });
  }

  function computedIconValid(path) {
    return computed(path, function () {
      return this.get(path) === ' - ' ? '' : 'icon-validation-correct';
    });
  }

  exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    notify: Ember.inject.service(),

    receiving: computedFilterServices(receiving),
    outbound: computedFilterServices(outbound),

    lastUpdateDate: (0, _formatDate.default)('responseModel.last_updated_date', 'll'),

    firstReceiptDate: (0, _formatDate.default)('responseModel.first_receipt_date', 'MM/DD/YY'),
    checkoutDate: (0, _formatDate.default)('responseModel.checkout_date', 'MM/DD/YY'),

    iconValidInitialReceipt: computedIconValid('firstReceiptDate'),
    iconValidCheckout: computedIconValid('checkoutDate'),

    didInsertElement: function didInsertElement() {
      var _this = this;

      this.get('model').trackerInfo().then(function (responseModel) {
        _this.set('responseModel', responseModel);
      });
    }
  });
});