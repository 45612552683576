define('ember-components/engagements/request-amendment/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  exports.default = Controller.extend({
    queryParams: ['engagement_id', 'amendment_id'],

    addedPersistentServicesString: Ember.computed('model.engagement.changed_persistent_services', function () {
      var array = this.get('model.engagement.changed_persistent_services.added');
      return array.filter(function (item) {
        return item != null;
      }).join(', ');
    }),

    deletedPersistentServicesString: Ember.computed('model.engagement.changed_persistent_services', function () {
      var array = this.get('model.engagement.changed_persistent_services.deleted');
      return array.filter(function (item) {
        return item != null;
      }).join(', ');
    })
  });
});