define('ember-components/serializers/user', ['exports', 'ember-data', 'ember-components/serializers/drf'], function (exports, _emberData, _drf) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var EmbeddedRecordsMixin = _emberData.default.EmbeddedRecordsMixin;
  exports.default = _drf.default.extend(EmbeddedRecordsMixin, {
    attrs: {
      company: { embedded: 'always' },
      customer: { embedded: 'always' }
    }
  });
});