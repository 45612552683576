define('ember-components/facility-order/route', ['exports', 'ember-components/libs/base/route'], function (exports, _route) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var calculation = {
    days_count: 0,
    storage_original_rate: null,
    storage_original_rate_daily: null,
    storage_units: null,
    storage_fee: null,
    service_inbound_fee: null,
    service_outbound_fee: null,
    tax_fee: null,
    subtotal_fee: null,
    buyer_total_fee: null,
    buyer_app_fee: null,
    buyer_app_fee_percent: null
  };

  exports.default = _route.default.extend({
    session: Ember.inject.service(),
    titleToken: 'Complete Form',

    model: function model(params) {
      var _this = this;

      var engagement = this.store.createRecord('engagement');
      var facility = this.store.findRecord('facility', params.facilityID).then(function (model) {
        return model;
      });
      var hash = {
        facility: facility,
        engagement: engagement,
        card: this.store.createRecord('card'),
        bankAccount: this.store.createRecord('bank-account', { account_holder_type: 'individual' }),
        accountFile: this.store.createRecord('account-file'),
        states: this.store.findAll('state'),
        calculation: calculation,
        params: params,
        storageAvailability: {}
      };
      if (this.get('session.isAuthenticated')) {
        this.store.queryRecord('customer', { me: true }).then(function (customer) {
          hash.customer = customer;
          hash.cards = _this.store.findAll('card');
          hash.bankAccounts = _this.store.findAll('bank-account');
        });
      }
      return Ember.RSVP.hash(hash);
    },
    afterModel: function afterModel(model) {
      var _this2 = this;

      var facility = model.facility;
      var engagement = model.engagement;
      var params = model.params;
      var storageParams = {
        date_from: params.startDate,
        date_to: params.endDate,
        storage_method: params.storageMethod,
        storage_type: params.storageType,
        volume: params.volume
      };
      var calculationParams = {
        start: params.startDate,
        end: params.endDate,
        facility: facility.id,
        storage_uom: params.storageMethod,
        storage_type: params.storageType[0],
        storage_units: params.volume
      };

      facility.getStorageAvailability(storageParams).then(function (response) {
        _this2.set('controller.model.storageAvailability', response);
      });
      engagement.calculate(calculationParams).then(function (feesData) {
        _this2.set('controller.model.calculation', feesData);
      });
    }
  });
});