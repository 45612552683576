define('ember-components/validators/zip', ['exports', 'ember-cp-validations/validators/base'], function (exports, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Zip = _base.default.extend({
    validate: function validate(value) {
      if (/(^\d{5}$)|(^\d{5}-\d{4}$)/.test(value)) {
        return true;
      }
      return 'Should be valid postal code';
    }
  });

  exports.default = Zip;
});