define('ember-components/components/includes/-header/main/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      service = Ember.inject.service;
  exports.default = Component.extend({
    session: service('session'),
    firstConversation: Ember.computed.reads('messagesHub.firstConversation'),
    currentUser: service(),

    isImpersonate: Ember.computed('currentUser.user', function () {
      return localStorage.getItem('isImpersonate');
    })
  });
});