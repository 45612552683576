define('ember-components/components/facility-form/facility-payment/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      service = Ember.inject.service;
  exports.default = Component.extend({
    store: service(),
    notify: service(),
    openModalPaymentAdd: false,
    cards: Ember.computed.filterBy('model.cards', 'id'),
    bankAccounts: Ember.computed.filterBy('model.bankAccounts', 'id'),
    states: Ember.computed.alias('model.states'),
    accountFile: Ember.computed.alias('model.accountFile'),
    account: Ember.computed.alias('model.account'),
    card: Ember.computed.alias('model.card'),
    bankAccount: Ember.computed.alias('model.bankAccount'),
    stepThreeValidation: Ember.computed.alias('model.stepThreeValidation'),
    selectedPaymentAccount: Ember.computed.alias('model.selectedPaymentAccount'),
    accountsListChanged: Ember.observer('cards', 'bankAccounts', function () {
      this._setSelectedPaymentAccount();
    }),
    isInvalid: Ember.computed('stepThreeValidation', 'selectedPaymentAccount', function () {
      return this.get('stepThreeValidation') && !this.get('selectedPaymentAccount');
    }),

    hasAccount: Ember.computed(function () {
      return this.get('model.account.id');
    }),

    _setSelectedPaymentAccount: function _setSelectedPaymentAccount() {
      // TODO: Need to use another component of the select to get rid of the start value setting.
      // X-select cannot update the input and output by itself.
      var selectedCard = this.get('cards').filter(function (card) {
        return card.get('default');
      })[0];
      var selectedBankAccount = this.get('bankAccounts').filter(function (account) {
        return account.get('default');
      })[0];
      var selectedPaymentAccountType = selectedCard ? 'card' : 'ach';

      if (selectedCard || selectedBankAccount) {
        var value = '' + selectedPaymentAccountType + (selectedCard ? selectedCard.get('id') : selectedBankAccount.get('id'));

        this.set('selectedPaymentAccount', value);
        this.send('setSelectedValue', value);
      }
    },
    didInsertElement: function didInsertElement() {
      this._setSelectedPaymentAccount();
    },


    actions: {
      selectCard: function selectCard(value) {
        var _this = this;

        var type = null;
        var id = null;

        if (!value) {
          return;
        }

        if (value.indexOf('ach') !== -1) {
          type = 'ach';
          id = parseInt(value.replace('ach', ''), 10);
        } else {
          type = 'card';
          id = parseInt(value.replace('card', ''), 10);
        }

        this.get('store').peekRecord(type === 'ach' ? 'supplier-bank-account' : 'supplier-card', id).setDefault().then(function () {
          _this.send('setSelectedValue', value);
        }).catch(function () {
          _this.get(_this, 'notify').error('Error when setting default payment method');
        });
      },


      // TODO: Need to use another component of the select to get rid of the start value setting.
      // X-select cannot update the input and output by itself.
      setSelectedValue: function setSelectedValue(value) {
        this.$('#payment-setup').val(value);
        this.set('selectedPaymentAccount', value);
      },
      submitNewPaymentAccount: function submitNewPaymentAccount() {
        var eventEmitter = this.get('eventEmitter');
        eventEmitter.trigger('submit-form-payment-method');
      },
      addPaymentAccount: function addPaymentAccount() {
        this.set('openModalPaymentAdd', true);
      }
    }
  });
});