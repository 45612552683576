define('ember-components/components/presenter/engagement-dashboard/engagement-summary/supplier/component', ['exports', 'ember-components/components/presenter/engagement-dashboard/engagement-summary/component', 'ember-components/constants'], function (exports, _component, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _component.default.extend({
    isShowWarehouseLicense: false,
    showDeposit: Ember.computed('model.engagement.substatus', function () {
      return this.get('model.engagement.substatus') !== 'past';
    }),
    isDisableApprove: Ember.computed('engagement.is_agreed', function () {
      return this.get('engagement.is_agreed') ? '' : 'disabled';
    }),
    showApprove: Ember.computed('engagementStatus', 'model.amendment', 'amendmentStatus', function () {
      if (this.get('model.amendment')) {
        var _status = this.get('amendmentStatus');
        return _status === 'pending' || _status === 'buyer_signed';
      }
      var status = this.get('engagementStatus');
      return status === 'buyer_requested' || status === 'buyer_signed';
    }),
    licensePreviewOptions: Ember.computed('model.amendment', 'model.engagement', 'type', function () {
      if (this.get('model.amendment')) {
        return {
          amendment: this.get('model.amendment.id'),
          type: 'supplier'
        };
      }
      return { engagement: this.get('model.engagement.id') };
    }),

    actions: {
      createInvoice: function createInvoice() {
        var route = this.get('currentRoute').getRoute();

        route.transitionTo('engagements.invoice-edit', this.get('model.engagement.id'), _constants.default.NEW_INVOICE_ID);
      },
      signBySupplier: function signBySupplier() {
        this.sendAction('signBySupplier');
      }
    }
  });
});