define('ember-components/dashboard-supplier/route', ['exports', 'ember-components/libs/base/route'], function (exports, _route) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _route.default.extend({
    titleToken: 'Supplier Dashboard',
    templateName: 'dashboard-common/supplier',

    model: function model() {
      var _this = this;

      return Ember.RSVP.hash({
        facility: this.store.findAll('facility'),
        account: this.store.queryRecord('account', { me: true }).catch(function () {
          return _this.store.createRecord('account');
        }),
        cards: this.store.findAll('supplier-card').catch(function () {
          return [];
        }),
        bankAccounts: this.store.findAll('supplier-bank-account').catch(function () {
          return [];
        }),
        engagementStats: this.store.queryRecord('engagement-stat', {
          type: 'supplier'
        }),
        requestedEngagements: this.store.query('engagement', {
          status: 'requested',
          type: 'supplier'
        }),
        currentEngagements: this.store.query('engagement', {
          status: 'current',
          type: 'supplier'
        }),
        requestedAmendments: this.store.query('amendment', {
          type: 'supplier',
          status__in: 'pending,buyer_signed'
        })
      });
    }
  });
});