define('ember-components/serializers/company', ['exports', 'ember-data', 'ember-components/serializers/drf'], function (exports, _emberData, _drf) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _drf.default.extend(_emberData.default.EmbeddedRecordsMixin, {
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload) {
      // eslint-disable-next-line no-param-reassign
      payload.business_tax_id_provided = Boolean(payload.business_tax_id);
      return this._super.apply(this, arguments);
    }
  });
});