define('ember-components/adapters/wmsvendor', ['exports', 'ember-components/adapters/application', 'ember-components/config/environment'], function (exports, _application, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    /**
     * Custom path for adapter
     *
     * @property pathForType
     * @returns {string}
     * @public
     */
    pathForType: function pathForType() {
      return 'vendors';
    },


    /**
     * Namespace
     *
     * @property namespace
     * @type {string}
     * @default 'api/v1/facilities'
     * @public
     */
    namespace: _environment.default.APP.API_NAMESPACE + '/facilities'
  });
});