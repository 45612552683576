define('ember-components/components/interactive/modals/engagement-license/component', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    type: '',
    licensePreviewError: '',
    licensePreviewOptions: null,
    engagementOptions: null,
    store: Ember.inject.service(),
    acceptedLicense: Ember.computed('type', 'engagementOptions', function () {
      var type = this.get('type');
      var licenseTitle = '';
      if (type) {
        licenseTitle = type === 'license-agreement-preview' ? 'Basic License & Licensee Information' : 'Warehouse Use and License Agreement and Terms of Service';
      }

      var engagementOptions = this.get('engagementOptions');
      if (engagementOptions) {
        var engagement = this.get('store').peekRecord('engagement', engagementOptions.engagement);
        if (engagementOptions.amendment) {
          var amendment = engagement.get('amendments').find(function (item) {
            return item.id === engagementOptions.amendment;
          });
          return amendment.get('acceptances').find(function (item) {
            return item.get('title') === licenseTitle;
          });
        }
        return engagement.get('acceptance_set').find(function (item) {
          return item.get('title') === licenseTitle;
        });
      }
      return undefined;
    }),
    license: Ember.computed('type', 'licensePreviewOptions', function () {
      var _this = this;

      var type = this.get('type');
      var licensePreviewOptions = this.get('licensePreviewOptions');
      var acceptedLicense = this.get('acceptedLicense');
      if (acceptedLicense) {
        return acceptedLicense;
      }
      var promise = this.get('store').queryRecord(type, licensePreviewOptions).catch(function (error) {
        if (error.errors && error.errors.length > 0) {
          _this.set('licensePreviewError', error.errors[0].detail);
        }
      });
      return _emberData.default.PromiseObject.create({ promise: promise });
    }),
    actions: {
      close: function close() {
        this.set('open', false);
      }
    }
  });
});