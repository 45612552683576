define('ember-components/components/email-confirm/component', ['exports', 'ember-components/mixins/redirect-to-home'], function (exports, _redirectToHome) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend(_redirectToHome.default, {
    /**
     * Show modal
     *
     * @method didInsertElement
     * @return {void}
     * @public
     */
    didInsertElement: function didInsertElement() {
      $('#emailConfirmModal').modal();
    }
  });
});