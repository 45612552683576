define('ember-components/services/google-tag-manager', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    sendEvent: function sendEvent(eventName, payload) {
      if (window.dataLayer) {
        window.dataLayer.push({
          event: eventName,
          payload: payload
        });
      }
    }
  });
});