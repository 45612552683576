define('ember-components/components/search-filtering/side-bar/space-table/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service,
      computed = Ember.computed;
  exports.default = Ember.Component.extend({
    store: service(),

    isUomPallets: computed(function () {
      var model = this.get('model');
      return model.storage_uom === 'p';
    }),

    actions: {
      showSpaceTable: function showSpaceTable() {
        var _this = this;

        var queryParams = this.get('queryParams');

        var model = this.get('model');
        var dsFacility = this.get('store').peekRecord('facility', model.id);
        if (!dsFacility) {
          dsFacility = this.get('store').createRecord('facility', model);
        }

        dsFacility.getStorageAvailability({
          date_from: queryParams.startDate,
          date_to: queryParams.endDate,
          storage_method: queryParams.storageMethod,
          volume: queryParams.volume
        }).then(function (data) {
          _this.set('availability', data);
        });
      }
    }
  });
});