define('ember-components/home/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller,
      service = Ember.inject.service;
  exports.default = Controller.extend({
    session: service()
  });
});