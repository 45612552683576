define('ember-components/helpers/has-error', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.hasError = hasError;


  /**
   * Helper for determining if there error occurs in some field
   *
   * @function hasError
   * @param {Array} params
   * @return {boolean} true if field in error list, false otherwise
   * @public
   */
  function hasError(params) {
    var errors = params[0];
    var field = params[1];
    if (errors) {
      return errors.validation_errors.reduce(function (acc, value) {
        return acc || value.field === field;
      }, false);
    }
    return false;
  }

  exports.default = Ember.Helper.helper(hasError);
});