define('ember-components/components/interactive/payment-ach/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    actions: {
      openDeleteModal: function openDeleteModal(card) {
        this.set('open', true);
        this.set('cardToDelete', card);
      },
      delete: function _delete() {
        this.sendAction('cardDelete', this.get('cardToDelete'));
      }
    }
  });
});