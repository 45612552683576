define('ember-components/facility-listing-details/controller', ['exports', 'ember-components/mixins/default-payment-property'], function (exports, _defaultPaymentProperty) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      service = Ember.inject.service,
      get = Ember.get;
  exports.default = Ember.Controller.extend(_defaultPaymentProperty.default, {
    cards: computed.reads('model.cards'),
    supplierCards: computed.reads('model.supplierCards'),
    currentUser: service(),
    // isDefaultCardExists: computed.reads('model.cards.length'),
    store: service(),

    queryParams: ['latitude', 'longitude', 'cityCode', 'stateCode', 'storageMethod', 'storageType', 'volume', 'startDate', 'endDate', 'from', 'usage', 'skus', 'cartonCubeMin', 'cartonCubeMax', 'cartonWeightMin', 'cartonWeightMax'],

    volume: 100,
    storageMethod: 'p',
    storageType: 'ambient',
    startDate: null,
    endDate: null,
    from: '',
    usage: '',

    noticeLineViewModel: computed(function () {
      return {
        type: 'presenter',
        name: 'notice-line',
        params: {
          value: 'Here\'s a quick preview to show you want the facility detail page will look like to prospective buyers.'
        }
      };
    }),

    actions: {
      sendRequest: function sendRequest() {
        var payment = this.store.createRecord('payment');
        payment.set('company', get(this, 'currentUser.user.company.id'));
        payment.save().catch(function () {
          $('#paymentRequestModal').modal();
        });
      }
    }

  });
});