define('ember-components/constants', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    NEW_INVOICE_ID: 'new',
    STORAGE_FACILITY_LINK: 'facilityLink',
    STORAGE_FACILITY_DETAILS: 'facilityDetails',
    IS_CREATE_ACCOUNT: 'isCreate',
    USAGE_TYPE: {
      BUYER: 'buyer',
      SUPPLIER: 'supplier',
      BOTH: 'both'
    },
    COMPANY_TYPE: {
      BUYER: 'b',
      SUPPLIER: 's'
    }
  };
});