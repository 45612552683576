define('ember-components/engagements/request-amendment/route', ['exports', 'ember-components/libs/base/route'], function (exports, _route) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _route.default.extend({
    wLicensePreviewError: '',
    licensePreviewError: '',

    model: function model(params) {
      var _this = this;

      var amendment = this.store.findRecord('amendment', params.amendment_id, {
        adapterOptions: { type: 'buyer' }
      });
      var facility = this.store.findRecord('facility', params.facility_id);

      var licensePreviewOptions = {
        amendment: params.amendment_id,
        type: 'buyer'
      };

      var wLicensePreview = this.get('store').queryRecord('warehouse-license-agreement-preview', licensePreviewOptions).catch(function (error) {
        if (error.errors && error.errors.length > 0) {
          _this.set('wLicensePreviewError', error.errors[0].detail);
        }
      });
      var licensePreview = this.get('store').queryRecord('license-agreement-preview', licensePreviewOptions).catch(function (error) {
        if (error.errors && error.errors.length > 0) {
          _this.set('licensePreviewError', error.errors[0].detail);
        }
      });

      return Ember.RSVP.hash({
        facility: facility,
        engagement: amendment,
        wLicensePreview: wLicensePreview,
        licensePreview: licensePreview,
        params: params
      });
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);
      controller.set('wLicensePreviewError', this.get('wLicensePreviewError'));
      controller.set('licensePreviewError', this.get('licensePreviewError'));
    }
  });
});