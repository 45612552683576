define('ember-components/adapters/terms-of-service', ['exports', 'ember-components/adapters/application', 'ember-components/config/environment'], function (exports, _application, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    namespace: _environment.default.APP.API_NAMESPACE + '/documents',

    pathForType: function pathForType() {
      return 'terms-of-service';
    }
  });
});