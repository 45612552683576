define('ember-components/engagements/invoice-edit/route', ['exports', 'ember-components/libs/base/route', 'ember-components/constants'], function (exports, _route, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _route.default.extend({
    store: Ember.inject.service(),

    newInvoice: null,

    /**
     * Get invoice relationships.
     * @param {number} invoiceId
     * @return {Promise}
     */
    getInvoiceRelationships: function getInvoiceRelationships(invoiceId) {
      return Ember.RSVP.hash({
        services: this.store.query('invoice-service', {
          invoice: invoiceId
        }),
        storages: this.store.query('invoice-storage', {
          invoice: invoiceId
        }),
        invoice_pdf_url: this.store.findRecord('invoice-pdf-url', invoiceId, {
          adapterOptions: { type: 'supplier' }
        })
      });
    },
    deactivate: function deactivate() {
      var newInvoice = this.get('newInvoice');
      if (newInvoice) {
        newInvoice.deleteRecord();
      }
    },
    model: function model(params) {
      var _this = this;

      var invoiceId = params.invoice_id,
          engagementId = params.engagement_id;

      var isNewInvoice = invoiceId === _constants.default.NEW_INVOICE_ID;

      var engagement = this.store.findRecord('engagement', engagementId, {
        adapterOptions: { type: 'supplier' }
      });

      if (isNewInvoice) {
        var newInvoice = this.store.createRecord('invoice', {
          adapterOptions: { type: 'supplier' }
        });
        this.set('newInvoice', newInvoice);

        return Ember.RSVP.hash({
          engagement: engagement,
          invoice: this.newInvoice,
          revisionRequest: this.store.createRecord('revision-request')
        });
      }

      var invoice = this.store.findRecord('invoice', invoiceId, {
        adapterOptions: { type: 'supplier' }
      }).then(function (invoiceRecord) {
        _this.getInvoiceRelationships(invoiceId).then(function (_ref) {
          var services = _ref.services,
              storages = _ref.storages,
              invoice_pdf_url = _ref.invoice_pdf_url;

          invoiceRecord.set('services', services);
          invoiceRecord.set('storages', storages);
          invoiceRecord.set('invoice_pdf_url', invoice_pdf_url);
        });

        return invoiceRecord;
      });

      return Ember.RSVP.hash({
        engagement: engagement,
        invoice: invoice,
        revisionRequest: this.store.createRecord('revision-request')
      });
    },
    afterModel: function afterModel(model) {
      var invoice = model.invoice,
          engagement = model.engagement;


      if (!invoice.get('sent_to')) {
        invoice.set('sent_to', engagement.get('user.customer.getCompanyName'));
      }

      if (!invoice.get('billing_address')) {
        invoice.set('billing_address', engagement.get('user.customer.fullAddress'));
      }

      invoice.set('engagement', engagement);

      return model;
    }
  });
});