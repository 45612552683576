define('ember-components/components/dashboard-notifications-end-engagement/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    engagementLink: Ember.computed('type', function () {
      return this.get('type') === 'supplier' ? 'engagements.supplier' : 'engagements.buyer';
    })
  });
});