define('ember-components/models/move-out-photo', ['exports', 'ember-data', 'ember-cp-validations', 'ember-api-actions'], function (exports, _emberData, _emberCpValidations, _emberApiActions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _emberCpValidations.buildValidations)({
    file: (0, _emberCpValidations.validator)('presence', true),
    name: (0, _emberCpValidations.validator)('presence', true),
    description: (0, _emberCpValidations.validator)('presence', true)
  });

  exports.default = _emberData.default.Model.extend(Validations, {
    file: _emberData.default.attr('string'),
    name: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    moveOut: _emberData.default.attr('number', { allowNull: true }),

    delete: (0, _emberApiActions.memberAction)({ path: '/', type: 'DELETE' })
  });
});