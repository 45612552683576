define('ember-components/components/presenter/bootstrap-tab/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      set = Ember.set,
      service = Ember.inject.service;
  exports.default = Component.extend({
    tabCounter: null,
    store: service(),
    currentUser: service(),
    tabName: {
      requested: 'Requested',
      upcoming: 'Upcoming',
      current: 'Current',
      past: 'Past'
    },

    actions: {
      updateModel: function updateModel(tab) {
        set(this, 'openedTab', tab);
      }
    }
  });
});