define('ember-components/components/system/render-view-model/component', ['exports', 'ember-components/components/system/render-view-model/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = Ember.Component.extend({
    layout: _template.default,
    tagName: '',
    name: computed.reads('viewModel.name'),
    type: computed.reads('viewModel.type'),

    componentName: computed('name', 'type', function () {
      var name = this.get('name');
      var type = this.get('type');
      return type + '/' + name;
    })

  });
});