define('ember-components/components/facility-form/storage-pricing/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      get = Ember.get,
      set = Ember.set;
  exports.default = Component.extend({
    _checkIsStorageType: function _checkIsStorageType() {
      var fields = ['model.facility.special_storage_dry_ambient', 'model.facility.special_storage_temperature_controlled', 'model.facility.special_storage_frozen_refrigerated', 'model.facility.special_storage_fda_registered', 'model.facility.special_storage_hazmat_certified', 'model.facility.special_storage_aib_certified', 'model.facility.special_storage_cbp_bonded', 'model.facility.special_storage_foreign_trade_zone', 'model.facility.special_storage_food_grade', 'model.facility.special_storage_iso', 'model.facility.special_storage_c_tpat', 'model.facility.special_storage_tapa'];
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = fields[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var field = _step.value;

          if (get(this, field)) {
            set(this, 'isSpecialType', '');
            return;
          }
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      set(this, 'model.facility.storage_special_available', 0);
      set(this, 'model.facility.storage_special_rate', 0);
      set(this, 'isSpecialType', 'disabled');
    },

    special_storage_type_changed: Ember.observer('model.facility.special_storage_dry_ambient', 'model.facility.special_storage_temperature_controlled', 'model.facility.special_storage_frozen_refrigerated', 'model.facility.special_storage_fda_registered', 'model.facility.special_storage_hazmat_certified', 'model.facility.special_storage_aib_certified', 'model.facility.special_storage_cbp_bonded', 'model.facility.special_storage_foreign_trade_zone', 'model.facility.special_storage_food_grade', 'model.facility.special_storage_iso', 'model.facility.special_storage_c_tpat', 'model.facility.special_storage_tapa', function () {
      this._checkIsStorageType();
    }),

    didInsertElement: function didInsertElement() {
      this._checkIsStorageType();
    }
  });
});