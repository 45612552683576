define('ember-components/components/facility-form/storage-list/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      set = Ember.set,
      get = Ember.get;
  exports.default = Component.extend({
    /**
     * Page size
     * @property pageSize
     * @type {object}
     * @public
     */
    pageSize: 5,

    /**
     * Table columns
     *
     * @property columns
     * @type {object}
     * @public
     */
    columns: [{
      propertyName: 'start',
      title: 'Date(s)',
      template: 'custom/storage-list/columns/dates'
    }, {
      propertyName: 'storage_ambient_available',
      title: 'Available Space',
      template: 'custom/storage-list/columns/ambient',
      disableSorting: true
    },
    // {
    //   propertyName: 'storage_cold_available',
    //   title: 'Cold',
    //   template: 'custom/storage-list/columns/cold',
    //   disableSorting: true,
    // },
    // {
    //   propertyName: 'storage_special_available',
    //   title: 'Special',
    //   template: 'custom/storage-list/columns/special',
    //   disableSorting: true,
    // },
    {
      title: '',
      template: 'custom/storage-list/actions/template',
      disableSorting: true
    }],

    /**
     * Overrides for css-classes (excluding icons) used in the component
     *
     * @property classes
     * @type {object}
     * @public
     */
    classes: Ember.Object.create({
      outerTableWrapper: '',
      innerTableWrapper: 'table table-main mt30',
      table: 'table table-responsive',
      footerSummaryNumericPagination: 'col-md-4 col-sm-4 col-xs-4',
      pageSizeWrapper: '',
      paginationWrapperNumeric: ''
    }),

    /**
     * Overrides for icons-classes used in the component
     *
     * @property icons
     * @type {object}
     * @public
     */
    icons: Ember.Object.create({
      'sort-asc': 'icon-dropdown-up',
      'sort-desc': 'icon-dropdown',
      caret: 'caret'
    }),

    actions: {
      /**
       * Action to delete record
       *
       * @method deleteRecord
       * @param facilityListingModel
       * @public
       */
      deleteRecord: function deleteRecord(facilityListingModel) {
        var _this = this;

        facilityListingModel.destroyRecord().then(function () {
          get(_this, 'model.facility_listings').removeObject(facilityListingModel);
        });
      },


      /**
       * Action to edit record
       *
       * @method editRecord
       * @param record
       * @public
       */
      editRecord: function editRecord(record) {
        var current = get(this, 'model.facility_listing');
        if (!current.id) {
          current.destroyRecord();
        }

        current = get(this, 'model.facility_listing');
        set(this, 'model.facility_listing', record);
        set(this, 'startDate', moment.utc(get(this, 'model.facility_listing.start')));
        set(this, 'endDate', moment.utc(get(this, 'model.facility_listing.end')));
      }
    }
  });
});