define('ember-components/models/seo-city', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr;
  exports.default = Model.extend({
    seo_city: attr('string'),
    seo_title: attr('string'),
    seo_copy: attr('string'),
    is_active: attr('boolean')
  });
});