define('ember-components/components/interactive/payment-methods/component', ['exports', 'ember-components/mixins/default-payment-property'], function (exports, _defaultPaymentProperty) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      set = Ember.set;
  exports.default = Ember.Component.extend(_defaultPaymentProperty.default, {
    store: Ember.inject.service(),
    openModalPaymentMethod: false,
    isAchEntryModalOpen: false,
    isSubmited: false,

    isErrors: computed('card.validations.isValid', 'isSubmited', function () {
      var isValid = this.get('card.validations.isValid') || this.get('bankAccount.validations.isValid');
      var isSubmited = this.get('isSubmited');
      return !isValid && isSubmited;
    }),

    msgErrors: computed('isErrors', function () {
      var msg = '* One or more required field is incomplete or incorrect. Please check.';
      return this.get('isErrors') ? msg : '';
    }),

    hasAccount: computed('account.id', 'customer.id', function () {
      return this.get('account.id') || this.get('customer.id');
    }),

    actions: {
      submit: function submit() {
        var eventEmitter = this.get('eventEmitter');
        eventEmitter.trigger('submit-form-payment-method');
        this.set('isSubmited', true);
      },
      showModal: function showModal() {
        this.set('openModalPaymentMethod', true);
        this.set('isSubmited', false);
      },
      cardDelete: function cardDelete(card) {
        this.sendAction('cardDelete', card);
      },
      processPayment: function processPayment(card) {
        this.sendAction('processPayment', card);
      },
      setDefaultPaymentMethod: function setDefaultPaymentMethod(value) {
        set(this, 'defaultCard', value);
        if (value.indexOf('ach') !== -1) {
          this.sendAction('setDefaultPaymentMethod', 'ach', parseInt(value.replace('ach', ''), 10));
        } else {
          this.sendAction('setDefaultPaymentMethod', 'card', parseInt(value.replace('card', ''), 10));
        }
      },
      processCard: function processCard(card) {
        this.sendAction('processCard', card);
      },
      closeAchEntryModal: function closeAchEntryModal() {
        this.set('isAchEntryModalOpen', false);
      }
    }
  });
});