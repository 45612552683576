define('ember-components/adapters/engagement', ['exports', 'ember-components/adapters/application', 'ember-components/config/environment'], function (exports, _application, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    namespace: '' + _environment.default.APP.API_NAMESPACE,
    addTrailingSlashes: true,

    buildURL: function buildURL(modelName, id, snapshot, requestType, query) {
      var url = new URL(this._super.apply(this, arguments));
      var record = snapshot ? snapshot.record : null;
      var queryType = query ? query.type : null;

      if (record && requestType === 'findRecord' && !url.searchParams.has('type') && !queryType) {
        var type = snapshot.record.get('bayer_id') ? 'bayer' : 'supplier';

        return this._super.apply(this, arguments) + '?type=' + type;
      }

      return this._super.apply(this, arguments);
    },
    shouldReloadAll: function shouldReloadAll() {
      return true;
    },
    urlForQuery: function urlForQuery(_query, _modelName) {
      this.set('addTrailingSlashes', true);
      return this._super.apply(this, arguments);
    },
    urlForFindRecord: function urlForFindRecord(id, modelName, snapshot) {
      this.set('addTrailingSlashes', false);
      var url = this._super(id, modelName, snapshot);
      if (snapshot && snapshot.adapterOptions && snapshot.adapterOptions.type) {
        var type = snapshot.adapterOptions.type;
        return url + '/?type=' + type;
      }
      return '' + url;
    }
  });
});