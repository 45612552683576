define('ember-components/dashboard-buyer/controller', ['exports', 'ember-components/mixins/default-payment-property'], function (exports, _defaultPaymentProperty) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller,
      computed = Ember.computed,
      service = Ember.inject.service;
  exports.default = Controller.extend(_defaultPaymentProperty.default, {
    queryParams: ['openVerified', 'accountId'],
    session: service('session'),
    currentUser: service(),
    list: [1, 2, 3, 4],
    cards: computed.reads('model.cards'),
    bankAccounts: computed.reads('model.bankAccounts')
  });
});