define('ember-components/engagements/invoice-details/route', ['exports', 'ember-components/libs/base/route'], function (exports, _route) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _route.default.extend({
    store: Ember.inject.service(),

    model: function model(params) {
      var _this = this;

      return this.store.findRecord('engagement', params.engagement_id, {
        adapterOptions: { type: 'buyer' }
      }).then(function (engagement) {
        return Ember.RSVP.hash({
          engagement: engagement,
          invoice: _this.store.findRecord('invoice', params.invoice_id, {
            adapterOptions: { type: 'buyer' }
          }),
          revisionRequest: _this.store.createRecord('revision-request')
        });
      });
    },
    afterModel: function afterModel(model) {
      model.invoice.loadServices();
      model.invoice.loadStorages();
      model.invoice.loadInvoicePdfUrl({ type: 'buyer' });
    },


    actions: {
      requestRevision: function requestRevision(request) {
        var _this2 = this;

        request.set('invoice', this.controller.get('model.invoice'));
        request.validate().then(function (_ref) {
          var validations = _ref.validations;

          if (validations.get('isValid')) {
            _this2.controller.get('model.invoice').requestRevision({ reason: request.get('reason') }).then(function () {
              // Reset value of form
              _this2.controller.set('model.revisionRequest', _this2.store.createRecord('revision-request'));
              _this2.controller.set('requestRevisionModalOpen', false);
              _this2.controller.set('revisionRequestSuccess', true);

              _this2.controller.set('model.invoice.status', 'on_review');
            }).catch(function (e) {
              Ember.Logger.error(e);
            });
          }
        });
      }
    }

  });
});