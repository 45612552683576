define('ember-components/errors/401/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({});


  Ember.run.schedule('afterRender', function () {
    $('#loginModal401').modal('show');
  });
});