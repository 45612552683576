define('ember-components/fastboot/initializers/ajax', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    name: 'ajax-service',
    initialize: function initialize() {
      // noop
      // This is to override Fastboot's initializer which prevents ember-fetch from working
      // https://github.com/ember-fastboot/ember-cli-fastboot/blob/master/packages/ember-cli-fastboot/fastboot/initializers/ajax.js
    }
  };
});