define('ember-components/services/current-user', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Service = Ember.Service,
      service = Ember.inject.service,
      RSVP = Ember.RSVP;
  exports.default = Service.extend({
    session: service(),
    store: service(),
    flashMessages: service(),

    load: function load() {
      var _this = this;

      this._super.apply(this, arguments);

      if (this.get('session.isAuthenticated')) {
        // eslint-disable-next-line new-cap
        return new RSVP.Promise(function (resolve, reject) {
          _this.get('store').queryRecord('user', { me: true }).then(function (data) {
            _this.set('user', data);

            resolve(_this);
          }).catch(function (error) {
            _this.get('session').invalidate();
            reject(error);
          });
        });
      }

      return RSVP.resolve(this);
    }
  });
});