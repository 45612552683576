define('ember-components/adapters/attachment', ['exports', 'ember-cli-form-data/mixins/form-data-adapter', 'ember-components/adapters/application', 'ember-components/config/environment'], function (exports, _formDataAdapter, _application, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend(_formDataAdapter.default, {
    namespace: '' + _environment.default.APP.API_NAMESPACE,

    pathForType: function pathForType() {
      return 'invoices-attachments';
    },


    /**
     * Update record.
     * No API for updates.
     * Just return model data object.
     * @param {DS.Store} store
     * @param {DS.Model} type
     * @param {object} snapshot
     * @return {object}
     */
    updateRecord: function updateRecord(store, type, snapshot) {
      return this.serialize(snapshot, { includeId: true });
    },


    // Workaround for ember-data related issue:
    // https://github.com/funtusov/ember-cli-form-data/issues/30#issuecomment-503610291
    ajaxOptions: function ajaxOptions() {
      var ajaxOptions = this._super.apply(this, arguments);
      delete ajaxOptions.headers['content-type'];

      return ajaxOptions;
    }
  });
});