define('ember-components/utils/clearCreateAccountStorage', ['exports', 'ember-components/constants'], function (exports, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.clearCreateAccountStorage = clearCreateAccountStorage;


  // eslint-disable-next-line import/prefer-default-export
  function clearCreateAccountStorage() {
    // eslint-disable-next-line no-console
    console.log('clearCreateAccountStorageclearCreateAccountStorage');
    window.localStorage.removeItem(_constants.default.STORAGE_FACILITY_LINK);
    window.localStorage.removeItem(_constants.default.STORAGE_FACILITY_DETAILS);
    window.localStorage.removeItem(_constants.default.IS_CREATE_ACCOUNT);
  }
});