define('ember-components/components/interactive/panels/messages/conversations-panel/item-list/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',

    active: Ember.computed.gt('model.new_messages_count', 0),
    activeClass: Ember.computed('active', function () {
      var active = this.get('active');
      return active ? 'active' : '';
    }),

    actions: {
      transitionToChat: function transitionToChat() {
        var model = this.get('model');
        var route = this.get('currentRoute').getRoute();
        route.transitionTo('messages.chat', model.id);
      }
    }
  });
});