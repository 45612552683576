define('ember-components/libs/computed/format-date', ['exports', 'moment', 'ember-components/config/environment'], function (exports, _moment, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = formatDate;
  function formatDate(propertyName) {
    var format = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : _environment.default.date.engagementFormat;
    var emptyChar = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : ' - ';

    return Ember.computed(propertyName, function () {
      var _date = this.get(propertyName);
      if (!_date) {
        return emptyChar;
      }
      var date = _moment.default.utc(_date);
      return date.isValid() ? date.format(format) : emptyChar;
    });
  }
});