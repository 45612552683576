define('ember-components/adapters/invoice-service', ['exports', 'ember-components/adapters/application', 'ember-components/config/environment'], function (exports, _application, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    namespace: '' + _environment.default.APP.API_NAMESPACE,

    pathForType: function pathForType() {
      return 'invoices/services';
    },
    updateRecord: function updateRecord(store, type, snapshot) {
      var _this = this;

      var baseUrl = this.buildURL();
      var data = this.serialize(snapshot, { includeId: true });
      var url = baseUrl + 'invoices/services/' + data.id + '/';

      if (!Object.keys(snapshot.changedAttributes()).length) {
        return data;
      }

      return new Ember.RSVP.Promise(function (resolve, reject) {
        $.ajax({
          type: 'PUT',
          url: url,
          headers: _this.get('headers'),
          data: data
        }).then(function (responseData) {
          resolve(responseData);
        }, function (error) {
          reject(error);
        });
      });
    }
  });
});