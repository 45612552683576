define('ember-components/messages/chat/route', ['exports', 'ember-components/libs/base/route'], function (exports, _route) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _route.default.extend({
    titleToken: 'Message',

    conversationId: Ember.inject.service(),

    model: function model(params) {
      var conversationId = params.conversationId;


      this.set('conversationId.id', conversationId);

      return Ember.RSVP.hash({
        conversation: this.store.findRecord('conversation', conversationId),
        messages: this.store.query('message', {
          conversation: conversationId
        })
      });
    },
    afterModel: function afterModel(model) {
      var conversation = model.conversation;

      if (Number(conversation.get('new_messages_count')) > 0) {
        conversation.read();
        conversation.set('new_messages_count', 0);
      }
    },
    createMessage: function createMessage(text) {
      return this.store.createRecord('message', {
        conversation: this.get('currentModel.conversation'),
        text: text
      }).save();
    }
  });
});