define('ember-components/services/session', ['exports', 'ember-data', 'ember-simple-auth/services/session'], function (exports, _emberData, _session) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _session.default.extend({

    store: Ember.inject.service(),

    currentUser: Ember.computed('isAuthenticated', function () {
      if (this.get('isAuthenticated')) {
        var promise = this.get('store').queryRecord('user', {});

        return _emberData.default.PromiseObject.create({ promise: promise });
      }
    })
  });
});