define('ember-components/models/acceptance', ['exports', 'ember-data', 'ember-components/config/environment'], function (exports, _emberData, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr;
  var computed = Ember.computed;
  exports.default = _emberData.default.Model.extend({
    document_title: attr('string'),
    matter: attr('string'),
    user: attr('number'),
    created: attr('string'),
    title: computed('document_title', function () {
      return this.get('document_title');
    }),
    url: computed('matter', function () {
      return this.get('matter');
    }),
    createdDate: computed('created', function () {
      return moment.utc(this.get('created')).format(_environment.default.date.dateSigned);
    })
  });
});