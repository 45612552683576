define('ember-components/components/interactive/upload-documents/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      service = Ember.inject.service,
      computed = Ember.computed,
      get = Ember.get;


  var MAX_FILE_SIZE = 10 * 1024 * 1024;
  var ERROR_FILE_SIZE = 'File size must be less than 10 MB';
  var ERROR_WRONG_FORMAT = 'Only PDF, JPEG, JPG, PNG files';
  var FORMATS = ['jpg', 'jpeg', 'pdf', 'png'];

  exports.default = Component.extend({
    notify: service(),
    store: service(),

    sortedAttachments: computed('model.attachments.@each.{isDeleted}', function () {
      var attachments = this.get('model.attachments');

      return attachments.filterBy('isDeleted', false).sortBy('created_date');
    }),

    fileIsAllowed: function fileIsAllowed(name, formats) {
      return formats.includes(name.split('.').slice(-1)[0].toLowerCase());
    },


    actions: {
      uploadFiles: function uploadFiles(event) {
        var files = Array.from(event.dataTransfer ? event.dataTransfer.files : event.target.files);
        var invoice = this.get('model');

        // Validation before uploading
        var _iteratorNormalCompletion = true;
        var _didIteratorError = false;
        var _iteratorError = undefined;

        try {
          for (var _iterator = files[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
            var file = _step.value;

            if (file.size > MAX_FILE_SIZE) {
              get(this, 'notify').warning(ERROR_FILE_SIZE);
              return;
            }
            if (!this.fileIsAllowed(file.name, FORMATS)) {
              get(this, 'notify').warning(ERROR_WRONG_FORMAT);
              return;
            }
          }
        } catch (err) {
          _didIteratorError = true;
          _iteratorError = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion && _iterator.return) {
              _iterator.return();
            }
          } finally {
            if (_didIteratorError) {
              throw _iteratorError;
            }
          }
        }

        var _iteratorNormalCompletion2 = true;
        var _didIteratorError2 = false;
        var _iteratorError2 = undefined;

        try {
          for (var _iterator2 = files[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
            var _file = _step2.value;

            this.get('store').createRecord('attachment', {
              content_type: _file.type,
              invoice: invoice,
              file: _file
            });
          }
        } catch (err) {
          _didIteratorError2 = true;
          _iteratorError2 = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion2 && _iterator2.return) {
              _iterator2.return();
            }
          } finally {
            if (_didIteratorError2) {
              throw _iteratorError2;
            }
          }
        }
      },
      removeItem: function removeItem(item) {
        item.deleteRecord();
      }
    }
  });
});