define('ember-components/components/search-filtering/dropdown-item/range-slider/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  var Component = Ember.Component,
      get = Ember.get,
      set = Ember.set,
      computed = Ember.computed;
  exports.default = Component.extend({
    didInsertElement: function didInsertElement() {
      var _this = this;

      var initialValueStart = get(this, 'initialValueStart');
      var initialValueEnd = get(this, 'initialValueEnd');
      var notRange = get(this, 'notRange');
      var endValue = get(this, 'endValue');
      var format = get(this, 'format');
      var maxPlus = get(this, 'maxPlus');

      this.set('endValueFormatted', endValue);
      var endValueFormatted = endValue;

      if (endValue > 0 && format === true) {
        endValueFormatted = new Intl.NumberFormat('en-US', { maximumSignificantDigits: 3 }).format(endValue);
        if (get(this, 'maxPlus') === true) {
          endValueFormatted = endValueFormatted + '+';
        }
        this.set('endValueFormatted', endValueFormatted);
      }

      var data = {
        tooltip: 'always',
        tooltip_position: get(this, 'tooltipPosition'),
        format: format,
        step: get(this, 'step'),
        formatter: function formatter(value) {
          if (this.format === true) {
            var formattedValue = new Intl.NumberFormat('en-US', { maximumSignificantDigits: 3 }).format(value);
            if (value === this.max && this.maxPlus === true) {
              formattedValue = formattedValue + '+';
            }
            return formattedValue;
          }
          return value;
        }
      };

      if (notRange) {
        data = Object.assign(data, {
          max: get(this, 'max'),
          value: initialValueEnd,
          formatter: function formatter(value) {
            return '$ ' + value.toFixed(2);
          }
        });
      } else {
        data = Object.assign(data, {
          tooltip_split: true,
          min: get(this, 'min'),
          max: get(this, 'max'),
          value: [initialValueStart, initialValueEnd],
          formatter: function formatter(value) {
            return '$ ' + value.toFixed(2);
          }
        });
      }
      var slider = new Slider('#' + this.elementId + '  .volume', data);

      set(this, 'valueStart', initialValueStart.toLocaleString());
      set(this, 'valueEnd', initialValueEnd.toLocaleString());

      slider.setAttribute('format', format);
      slider.setAttribute('maxPlus', maxPlus);
      if (format === true) {
        slider.setValue(data.value, true, false);
      }

      slider.on('slideStop', function () {
        var valueStart = void 0;
        var valueEnd = void 0;
        if (notRange) {
          valueStart = get(_this, 'initialValueStart');
          valueEnd = slider.getValue();
        } else {
          var _slider$getValue = slider.getValue();

          var _slider$getValue2 = _slicedToArray(_slider$getValue, 2);

          valueStart = _slider$getValue2[0];
          valueEnd = _slider$getValue2[1];
        }
        set(_this, 'valueStart', valueStart);
        set(_this, 'valueEnd', valueEnd);
      });
    },

    checkEndValue: computed('valueEnd', function () {
      if (get(this, 'max') === 200000) {
        return true;
      }
    }),
    checkValue: computed('valueEnd', function () {
      if (get(this, 'max') === 20) {
        return true;
      }
    })

  });
});