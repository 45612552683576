define('ember-components/components/presenter/engagement-dashboard/engagement-summary/buyer/component', ['exports', 'ember-components/components/presenter/engagement-dashboard/engagement-summary/component'], function (exports, _component) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _component.default.extend({
    showDeposit: Ember.computed('model.engagement.status', 'model.engagement.substatus', function () {
      return this.get('engagement.status') === 'active' && this.get('engagement.substatus') !== 'past';
    })
  });
});