define('ember-components/components/facility-form/facility-videos/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      get = Ember.get,
      service = Ember.inject.service;
  exports.default = Component.extend({
    store: service(),
    notify: service(),

    actions: {
      addVideo: function addVideo() {
        get(this, 'model.facility.videos').pushObject(get(this, 'store').createRecord('video', { url: '' }));
      },
      removeVideo: function removeVideo(video) {
        var videos = get(this, 'model.facility.videos');
        if (videos.content.length > 1) {
          videos.removeObject(video);
          video.destroyRecord();
        }
      }
    }
  });
});