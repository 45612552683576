define('ember-components/mixins/validators/engagement-model', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var MAX_DECIMAL_VALUE = 99999999;
  var MAX_SKU_VALUE = 32000;

  var serviceVolumeFieldValidator = {
    numericality: {
      if: function _if(key, value, _this) {
        // flag key for service rate has the same core in name but without
        // suffix '_volume'
        var flagKey = key.replace('_volume', '');
        return _this.get(flagKey);
      },

      onlyInteger: true,
      greaterThanOrEqualTo: 0.0,
      lessThanOrEqualTo: MAX_DECIMAL_VALUE,
      message: 'Value must be in range between 0 and ' + MAX_DECIMAL_VALUE
    }
  };

  var skuInfoVolumeFieldValidator = {
    numericality: {
      allowBlank: true,
      greaterThanOrEqualTo: 0.0,
      lessThanOrEqualTo: MAX_DECIMAL_VALUE,
      message: 'Value must be in range between 0 and ' + MAX_DECIMAL_VALUE
    }
  };

  exports.default = Ember.Mixin.create({
    validations: {
      sku_count: {
        numericality: {
          allowBlank: true,
          onlyInteger: true,
          greaterThanOrEqualTo: 0,
          lessThanOrEqualTo: MAX_SKU_VALUE,
          message: 'Value must be in range between 0 and ' + MAX_SKU_VALUE
        }
      },
      service_pallet_receiving_volume: serviceVolumeFieldValidator,
      service_carton_receiving_volume: serviceVolumeFieldValidator,
      service_carton_pick_volume: serviceVolumeFieldValidator,
      service_unit_pick_volume: serviceVolumeFieldValidator,
      service_unit_pack_volume: serviceVolumeFieldValidator,
      service_pallet_pick_volume: serviceVolumeFieldValidator,

      carton_cube_min_volume: skuInfoVolumeFieldValidator,
      carton_cube_max_volume: skuInfoVolumeFieldValidator,
      carton_weight_min_volume: skuInfoVolumeFieldValidator,
      carton_weight_max_volume: skuInfoVolumeFieldValidator,

      skuInfoServices: {
        custom: {
          validation: function validation(key, value, _this) {
            var carton_cube_min = parseFloat(_this.get('carton_cube_min_volume'));
            var carton_cube_max = parseFloat(_this.get('carton_cube_max_volume'));
            var carton_weight_min = parseFloat(_this.get('carton_weight_min_volume'));
            var carton_weight_max = parseFloat(_this.get('carton_weight_max_volume'));

            var isValidCube = false;
            var isValidWeight = false;

            if (!isNaN(carton_cube_min) || !isNaN(carton_cube_max)) {
              if (carton_cube_min < carton_cube_max) {
                isValidCube = true;
              }
            } else {
              isValidCube = true;
            }

            if (!isNaN(carton_weight_min) || !isNaN(carton_weight_max)) {
              if (carton_weight_min < carton_weight_max) {
                isValidWeight = true;
              }
            } else {
              isValidWeight = true;
            }

            return isValidWeight && isValidCube;
          },
          message: function message() {
            return 'The Min. Value must be less than the Max. Value';
          }
        }
      },

      inboundServices: {
        custom: {
          validation: function validation(key, value, _this) {
            var flagsToCheck = ['service_pallet_receiving', 'service_carton_receiving'];

            var atLeastOnServiceActivated = false;
            var _iteratorNormalCompletion = true;
            var _didIteratorError = false;
            var _iteratorError = undefined;

            try {
              for (var _iterator = flagsToCheck[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
                var flag = _step.value;

                if (_this.get(flag)) {
                  atLeastOnServiceActivated = true;
                  break;
                }
              }
            } catch (err) {
              _didIteratorError = true;
              _iteratorError = err;
            } finally {
              try {
                if (!_iteratorNormalCompletion && _iterator.return) {
                  _iterator.return();
                }
              } finally {
                if (_didIteratorError) {
                  throw _iteratorError;
                }
              }
            }

            return atLeastOnServiceActivated;
          },
          message: function message() {
            return 'At least one of inbound services must be selected';
          }
        }
      },
      outboundServices: {
        custom: {
          validation: function validation(key, value, _this) {
            var flagsToCheck = ['service_pallet_pick', 'service_carton_pick', 'service_unit_pick', 'service_unit_pack'];

            var atLeastOnServiceActivated = false;
            var _iteratorNormalCompletion2 = true;
            var _didIteratorError2 = false;
            var _iteratorError2 = undefined;

            try {
              for (var _iterator2 = flagsToCheck[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
                var flag = _step2.value;

                if (_this.get(flag)) {
                  atLeastOnServiceActivated = true;
                  break;
                }
              }
            } catch (err) {
              _didIteratorError2 = true;
              _iteratorError2 = err;
            } finally {
              try {
                if (!_iteratorNormalCompletion2 && _iterator2.return) {
                  _iterator2.return();
                }
              } finally {
                if (_didIteratorError2) {
                  throw _iteratorError2;
                }
              }
            }

            return atLeastOnServiceActivated;
          },
          message: function message() {
            return 'At least one of outbound services must be selected';
          }
        }
      }
    }

  });
});