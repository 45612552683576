define('ember-components/components/new-listing-details/approved-uses/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var DEFAULT_ITEMS_VISIBLE = 12;

  var Component = Ember.Component,
      computed = Ember.computed,
      get = Ember.get,
      set = Ember.set;
  exports.default = Component.extend({
    hasMaxLength: true,

    defaultItemsVisible: DEFAULT_ITEMS_VISIBLE,

    approvedUses: computed('facility.selectedIndustriesServed', function () {
      return get(this, 'facility.selectedIndustriesServed');
    }),

    cutApprovedUses: computed('approvedUses', 'hasMaxLength', function () {
      var approvedUses = get(this, 'approvedUses');
      var hasMaxLength = get(this, 'hasMaxLength');

      if (approvedUses.length > DEFAULT_ITEMS_VISIBLE && hasMaxLength) {
        return approvedUses.slice(0, DEFAULT_ITEMS_VISIBLE - 1);
      }
      return approvedUses;
    }),

    actions: {

      /** Handle shows the whole approved uses list which is folded if its length > 12. */
      onShowMore: function onShowMore() {
        set(this, 'hasMaxLength', false);
      }
    }
  });
});