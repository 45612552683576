define('ember-components/components/presenter/table-engagement/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      get = Ember.get,
      set = Ember.set,
      computed = Ember.computed,
      service = Ember.inject.service;
  exports.default = Component.extend({
    store: service(),

    filterOptions: Ember.A(),
    usedFilterOptions: {},
    id: {
      propertyName: 'getIdAsNumber',
      title: 'Contract #',
      template: 'custom/table-engagement/td/id'
    },
    facility: {
      propertyName: 'getFacilityNickname',
      title: 'Details',
      template: 'custom/table-engagement/td/facility',
      filterWithSelect: true,
      filterFunction: function filterFunction(cellValue, filterString) {
        return cellValue === filterString;
      },

      templateForFilterCell: 'custom/table-engagement/filters/facility'
    },
    dates: {
      className: 'dates',
      propertyName: 'start',
      title: 'Dates',
      template: 'custom/table-engagement/td/dates'
    },
    end: {
      propertyName: 'end',
      title: 'End Date',
      template: 'custom/table-engagement/td/end',
      sortDirection: 'desc',
      sortPrecedence: 0,
      alert: false
    },
    volume: {
      propertyName: 'storage_units',
      title: 'Volume',
      template: 'custom/table-engagement/td/volume',
      disableSorting: true
    },
    totalCost: Ember.computed(function () {
      var isBuyer = this.get('isBuyer');

      return {
        propertyName: isBuyer ? 'buyer_total_fee' : 'supplier_total_fee',
        title: 'Total Cost',
        template: 'custom/table-engagement/td/total-cost',
        disableSorting: true
      };
    }),
    submitted: {
      propertyName: 'created',
      title: 'Submitted On',
      template: 'custom/table-engagement/td/submitted',
      sortDirection: 'desc',
      sortPrecedence: 0,
      alert: false
    },
    reviewRequest: {
      title: '',
      template: 'custom/table-engagement/td/review-request'
    },
    viewEngagement: {
      title: '',
      template: 'custom/table-engagement/td/view-engagement'
    },
    review: {
      className: 'review',
      title: 'Review',
      template: 'custom/table-engagement/td/review',
      sortDirection: 'desc',
      sortPrecedence: 1
    },
    submitReview: {
      title: '',
      template: 'custom/table-engagement/td/submit-review'
    },
    cancelRequest: {
      title: '',
      template: 'custom/table-engagement/td/cancel-request'
    },
    reviewTerms: {
      title: '',
      template: 'custom/table-engagement/td/review-terms'
    },
    contactSupplier: {
      title: '',
      template: 'custom/table-engagement/td/contact-supplier'
    },
    data: [],
    columns: [],
    getData: computed.alias('data'),
    getColumns: computed.alias('columns'),

    getTableColumns: function getTableColumns() {
      var extraColumns = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];

      return [this.get('facility'), this.get('id'), this.get('dates'), this.get('volume'), this.get('totalCost')].concat(extraColumns);
    },


    isBuyer: computed(function () {
      return get(this, 'model.userType') === 'buyer';
    }),

    columns_requested: computed(function () {
      var extraColumns = this.get('isBuyer') ? [this.submitted, this.cancelRequest] : [this.submitted, this.reviewRequest];

      return this.getTableColumns(extraColumns);
    }),

    columns_upcoming: computed(function () {
      var extraColumns = this.get('isBuyer') ? [this.submitted, this.reviewTerms] : [this.submitted, this.viewEngagement];

      return this.getTableColumns(extraColumns);
    }),

    columns_current: computed(function () {
      var extraColumns = this.get('isBuyer') ? [this.end, this.contactSupplier] : [this.submitted, this.viewEngagement];

      return this.getTableColumns(extraColumns);
    }),

    columns_past: computed(function () {
      var extraColumns = this.get('isBuyer') ? [this.review] : [this.review, this.submitReview];

      return this.getTableColumns(extraColumns);
    }),

    _setUpModel: function _setUpModel() {
      var _this = this;

      var model = [];
      var newModel = Ember.A();
      var user_type = get(this, 'model.userType');
      var tab = get(this, 'openedTab');
      $('#spinner').show();

      switch (tab) {
        case 'requested':
          model = {
            requested: get(this, 'store').query('engagement', {
              status: 'requested',
              type: user_type
            }).finally(function () {
              $('#spinner').hide();
            })
          };
          break;
        case 'upcoming':
          model = {
            upcoming: get(this, 'store').query('engagement', {
              status: 'upcoming',
              type: user_type
            }).finally(function () {
              $('#spinner').hide();
            })
          };
          break;
        case 'current':
          model = {
            current: get(this, 'store').query('engagement', {
              status: 'current',
              type: user_type
            }).finally(function () {
              $('#spinner').hide();
            })
          };
          break;
        case 'past':
          model = {
            past: get(this, 'store').query('engagement', {
              status: 'past',
              type: user_type
            }).finally(function () {
              $('#spinner').hide();
            })
          };
          break;
        default:
          model = {};
      }

      Object.keys(model).forEach(function (key) {
        model[key].then(function (res) {
          res.forEach(function (item) {
            newModel.pushObject(item);
            _this._updateFacilityFilter(item);
          });
        });
      });

      set(this, 'data', newModel);
    },
    _updateFacilityFilter: function _updateFacilityFilter(element) {
      var filterOptions = get(this, 'filterOptions');
      var id = element.get('facility.id');
      var object = get(this, 'store').peekRecord('facility', id);
      var used = get(this, 'usedFilterOptions');

      if (!object) {
        get(this, 'store').findRecord('facility', id).then(function (res) {
          var nickname = res.get('nickname');
          var data = {
            label: nickname,
            value: nickname
          };
          if (!(nickname in used)) {
            filterOptions.addObject(data);
          }
          used[nickname] = true;
        });
      } else {
        var nickname = object.get('nickname');
        var data = {
          label: nickname,
          value: nickname
        };
        if (!(nickname in used)) {
          filterOptions.addObject(data);
        }
        used[nickname] = true;
      }
    },
    _updateColumns: function _updateColumns() {
      var tab = get(this, 'openedTab');

      switch (tab) {
        case 'requested':
          set(this, 'columns', this.columns_requested);
          break;
        case 'upcoming':
          set(this, 'columns', this.columns_upcoming);
          break;
        case 'current':
          set(this, 'columns', this.columns_current);
          break;
        case 'past':
          set(this, 'columns', this.columns_past);
          break;
        default:
          break;
      }
    },


    classes: {
      pageSizeWrapper: '',
      paginationWrapperNumeric: ''
    },

    _setUpData: function _setUpData() {
      set(this, 'filterOptions', [{ label: 'All', value: 'All' }]);
      set(this, 'usedFilterOptions', {});
      this._setUpModel();
      this._updateColumns();
    },
    init: function init() {
      this._super.apply(this, arguments);
      this._setUpData();
      $('#spinner').hide();
    },


    actions: {
      readAlert: function readAlert(alerts, engagement) {
        alerts.forEach(function (item) {
          item.readAlert();
          item.reload();
        });
        engagement.reload();
        this._setUpModel();
      }
    }
  });
});