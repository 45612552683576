define('ember-components/pages/invoice-edit/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  exports.default = Controller.extend({
    dataDocuments: [{
      name: 'Filename.jpg',
      type: 'Image / jpg',
      date: '04/21/17'
    }, {
      name: 'Filename.pdf',
      type: 'Document / pdf',
      date: '04/21/17'
    }]
  });
});