define('ember-components/components/search-page/component', ['exports', 'ember-components/constants'], function (exports, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      service = Ember.inject.service;


  var BasicCities = {
    Los_Angeles: [-118.2436849, 34.0522342],
    Orange: [-117.8531007, 33.7879139],
    San_Bernardino: [-117.2897652, 34.1083449],
    Riverside: [-117.3961564, 33.9533487]
  };

  exports.default = Ember.Component.extend({
    session: service(),
    showAlertNoAvailability: false,
    showAlertSubmit: false,
    overlay: service(),
    store: service(),
    currentUser: service(),
    userAgentCheck: service(),
    isOpenMoreFilters: false,
    isCrawler: Ember.computed.alias('userAgentCheck.isCrawler'),
    zoomValue: Ember.computed('zoom', {
      get: function get() {
        return this.get('zoom') ? parseInt(this.get('zoom'), 10) : 10;
      },
      set: function set(key, value) {
        this.set('zoom', value);
      }
    }),
    api: service(),

    minDate: moment.utc(),

    selectedLocation: Ember.computed(function () {
      return this.get('location');
    }),

    /**
     * Should we refresh search on map move.
     *
     * @type {boolean}
     */
    redoSearchOnMove: false,

    /**
     * Google maps options.
     */
    mapOptions: {
      zoomControl: true,
      maxZoom: 13,
      minZoom: 9,
      zoomControlOptions: {
        position: google.maps.ControlPosition.RIGHT_CENTER
      },
      disableDefaultUI: true,
      scrollwheel: false
    },

    typeNumber: computed('agriculture', 'footwearTextiles', 'aerospace', 'baseMetals', 'consumerProducts', 'electronics', 'manufacturingMaterials', 'machinery', 'naturalGas', 'pharmaceuticals', function () {
      var _this = this;

      var fields = ['agriculture', 'footwearTextiles', 'aerospace', 'baseMetals', 'consumerProducts', 'electronics', 'manufacturingMaterials', 'machinery', 'naturalGas', 'pharmaceuticals'];
      return fields.reduce(function (prev, next) {
        return prev + (_this.get(next) ? 1 : 0);
      }, 0);
    }),

    startDateMoment: Ember.computed('startDate', {
      get: function get() {
        if (this.get('startDate')) {
          return moment.utc(this.get('startDate'), 'YYYY-MM-DD');
        }
        return null;
      },
      set: function set(key, value) {
        this.set('startDate', value ? value.format('YYYY-MM-DD') : undefined);
      }
    }),

    endDateMoment: Ember.computed('endDate', {
      get: function get() {
        if (this.get('endDate')) {
          return moment.utc(this.get('endDate'), 'YYYY-MM-DD');
        }
        return null;
      },
      set: function set(key, value) {
        this.set('endDate', value ? value.format('YYYY-MM-DD') : undefined);
      }
    }),

    checkAvailableFacility: function checkAvailableFacility() {
      var eps = 1.e-6;
      var _ref = [this.get('longitude'), this.get('latitude')],
          lon = _ref[0],
          lat = _ref[1];

      var facilities = this.get('model.facilities').length;

      var isNearby = false;
      Object.values(BasicCities).forEach(function (item) {
        isNearby = Math.abs(item[0] - lon) + Math.abs(item[1] - lat) < eps || isNearby;
      });
      if (!isNearby && !facilities) {
        this.set('showAlertNoAvailability', true);
      }
    },
    didInsertElement: function didInsertElement() {
      window.localStorage.removeItem(_constants.default.IS_CREATE_ACCOUNT);
      this._super.apply(this, arguments);
      this.checkAvailableFacility();
      this._setupMapListeners();
      this._setupMobileHandlers();
      this._setupRedoSearchControl();
    },
    _setupMapListeners: function _setupMapListeners() {
      var _this2 = this;

      this.get('map').addListener('dragend', function () {
        if (_this2.get('redoSearch')) {
          var pos = _this2.get('map').getCenter();
          var updateLatLng = _this2.get('updateLatLng');
          updateLatLng(pos.lat(), pos.lng());
        }
      });
      this.get('map').addListener('zoom_changed', function () {
        if (_this2.get('redoSearch')) {
          var zoom = _this2.get('map').getZoom();
          _this2.set('zoom', zoom);
        }
      });
    },
    _setupRedoSearchControl: function _setupRedoSearchControl() {
      var self = this;
      var redoControlDiv = document.createElement('div');
      redoControlDiv.index = 1;

      // Set CSS for the control border.
      var controlUI = $('<div />').css({
        'background-color': '#fff',
        'box-shadow': '0 2px 6px rgba(0,0,0,.3)',
        'border-radius': '2px',
        position: 'relative',
        top: '-27px',
        left: '-50px'
      })[0];
      redoControlDiv.appendChild(controlUI);
      $(controlUI).on('click', function () {
        self.set('redoSearchOnMove', !self.get('redoSearchOnMove'));
      });

      // Set CSS for the control interior.
      var controlText = $('<div />').css({
        padding: '6px 15px 3px',
        cursor: '#pointer',
        'font-size': '12px'
      }).html('<label style="font-weight: 400; color: #4A4A4A;position: relative;top: 4px;"><input type="checkbox" style=" display: none;"/><span for="id-redo-search" style="padding-left: 20px;">Re-do search when map is moved</span></label>')[0];
      controlUI.appendChild(controlText);

      this.get('map').controls[google.maps.ControlPosition.BOTTOM_LEFT].push(redoControlDiv);
    },


    /**
     * @todo: This methd need rewrite!
     */
    _setupMobileHandlers: function _setupMobileHandlers() {
      var _this3 = this;

      // Switcher.
      this.$('.wrap-switcher button').on('click', function (event) {
        _this3.$('.wrap-switcher button').removeClass('active');
        $(event.target).addClass('active');
      });

      $('.event-space').on('click', function () {
        _this3.$('.search-page__map-content').addClass('hide').removeClass('show');
        _this3.$('.search-page__space-bar-content').removeClass('hide').addClass('show');

        if (!_this3.$('.js-show-filter').hasClass('active')) {
          $('.wrap-results-spaces').removeClass('add-space');
        }
      });

      $('.event-map').on('click', function () {
        _this3.$('.search-page__map-content').addClass('show').removeClass('hide');
        google.maps.event.trigger(_this3.get('map'), 'resize');
        _this3.$('.search-page__space-bar-content').addClass('hide').removeClass('show');
      });

      $(window).on('orientationchange', function () {
        if (!_this3.$('.wrap-switcher').is(':visible')) {
          _this3.$('.search-page__map-content').addClass('show').removeClass('hide');
          google.maps.event.trigger(_this3.get('map'), 'resize');
        }
      });
    },
    hideOverlay: function hideOverlay() {
      this.toggleMenuAndOverlay();
    },


    // for mobile
    toggleMenuAndOverlay: function toggleMenuAndOverlay() {
      var _this4 = this;

      if (this.get('isShowFilters')) {
        this.set('isShowFilters', false);
        this.$('.overlay-for-dropdown').removeClass('show');
        $('.navbar-toggle').off('click.toggleMenu');
      } else {
        this.set('isShowFilters', true);
        this.$('.overlay-for-dropdown').addClass('show');
        $('.navbar-toggle').on('click.toggleMenu', function () {
          _this4.hideOverlay();
        });
      }
    },


    actions: {
      showFilterMobile: function showFilterMobile() {
        this.toggleMenuAndOverlay();
      },
      clickByOverlay: function clickByOverlay() {
        this.toggleMenuAndOverlay();

        return false;
      },
      dropDownItemClick: function dropDownItemClick(currentDropDownIndex) {
        var overlaySearch = this.get('overlay.search');
        overlaySearch.show();
        this.set('isShowFilters', true);
        $('.dropdown:has(.dropdown-toggle)').removeClass('open');
        $('.dropdown:has(#dropdownMenu' + currentDropDownIndex + ')').addClass('open');
      },
      toggleMoreFilters: function toggleMoreFilters() {
        this.toggleProperty('isOpenMoreFilters');
      },
      searchAgain: function searchAgain() {
        this.sendAction('searchAgain');
      }
    }
  });
});