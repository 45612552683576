define('ember-components/components/login-popup/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    actions: {
      submitRequest: function submitRequest() {
        $('#signUpModal').modal();
        if (this.callbackAction) {
          this.callbackAction();
        }
      }
    }
  });
});