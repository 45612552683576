define('ember-components/components/interactive/panels/messages/send-panel/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['chat-bottom'],
    value: '',
    isEmpty: Ember.computed.empty('value'),

    actions: {
      send: function send() {
        var _this = this;

        var route = this.get('currentRoute').getRoute();
        route.createMessage(this.get('value')).then(function () {
          _this.set('value', '');
          _this.sendAction('handlerScrollTop');
          route.refresh();
        });
      }
    }

  });
});