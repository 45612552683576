define('ember-components/adapters/drf', ['exports', 'ember-django-adapter/adapters/drf', 'ember-components/config/environment'], function (exports, _drf, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _drf.default.extend({
    host: Ember.computed(function () {
      return _environment.default.APP.API_HOST;
    }),

    namespace: Ember.computed(function () {
      return _environment.default.APP.API_NAMESPACE;
    })
  });
});