define('ember-components/components/search-page/more-filters-modal/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      service = Ember.inject.service;
  exports.default = Component.extend({
    store: service(),
    storageType: {
      specialStorageDryAmbient: false,
      specialStorageTemperatureControlled: false,
      specialStorageFrozenRefrigerated: false,
      specialStorageFDARegistered: false,
      specialStorageHazMatCertified: false,
      specialStorageAIBCertified: false,
      specialStorageCBPBonded: false,
      specialStorageForeignTradeZone: false,
      specialStorageFoodGrade: false,
      specialStorageIso: false,
      specialStorageCtpat: false,
      specialStorageTapa: false,
      cartonLabeling: false,
      kitting: false,
      crossDock: false,
      priceTickerting: false,
      repacking: false,
      shipLabeling: false,
      priceLabeling: false,
      floorSpace: false,
      racking: false,
      securedRoom: false,
      pickModule: false,
      dedicatedRoom: false,
      gatedAccess: false,
      siteGuards: false,
      securitySystem: false,
      videoSurveillance: false,
      agriculture: false,
      footwearTextiles: false,
      aerospace: false,
      baseMetals: false,
      consumerProducts: false,
      electronics: false,
      manufacturingMaterials: false,
      machinery: false,
      naturalGas: false,
      pharmaceuticals: false,
      palletReceiving: false,
      cartonReceiving: false,
      unitPack: false,
      cartonPick: false,
      palletPick: false
    },

    didInsertElement: function didInsertElement() {
      this._setupFilters();
    },
    _setupFilters: function _setupFilters() {
      var _this = this;

      Object.keys(this.storageType).forEach(function (key) {
        _this.set('storageType.' + key, _this.get(key));
      });
    },


    isOpen: false,

    _setDataFromFilters: function _setDataFromFilters() {
      var _this2 = this;

      Object.keys(this.storageType).forEach(function (key) {
        _this2.set(key, _this2.storageType[key]);
      });

      return false;
    },


    actions: {
      close: function close() {
        this.sendAction('close');
        return false;
      },
      apply: function apply() {
        this._setDataFromFilters();
        this.sendAction('close');
        return false;
      },
      resetFilters: function resetFilters() {
        this.set('storageType', {});
        this._setupFilters();
        return false;
      }
    }
  });
});