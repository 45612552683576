define('ember-components/models/engagement-calculation', ['exports', 'ember-data', 'ember-components/mixins/model-validator', 'ember-components/mixins/validators/facility-model'], function (exports, _emberData, _modelValidator, _facilityModel) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr;
  exports.default = _emberData.default.Model.extend(_modelValidator.default, _facilityModel.default, {
    currentUser: Ember.inject.service(),

    start: attr('string'),
    end: attr('string'),

    facility: attr('number'),

    storage_uom: attr('string'),
    storage_units: attr('number'),
    storage_type: attr('string'),
    storage_original_rate: attr('number'),

    storage_fee: attr('number'),
    service_inbound_fee: attr('number'),
    service_outbound_fee: attr('number'),
    tax_fee: attr('number'),
    subtotal_fee: attr('number'),

    buyer_app_fee: attr('number'),
    buyer_app_fee_percent: attr('number'),
    buyer_total_fee: attr('number'),
    supplier_app_fee: attr('number'),
    supplier_app_fee_percent: attr('number'),
    supplier_total_fee: attr('number'),

    service_pallet_receiving: attr('boolean', { defaultValue: false }),
    service_pallet_receiving_volume: attr('number', { defaultValue: 0.0 }),

    service_carton_receiving: attr('boolean', { defaultValue: false }),
    service_carton_receiving_volume: attr('number', { defaultValue: 0.0 }),

    service_carton_pick: attr('boolean', { defaultValue: false }),
    service_carton_pick_volume: attr('number', { defaultValue: 0.0 }),

    service_pallet_pick: attr('boolean', { defaultValue: false }),
    service_pallet_pick_volume: attr('number', { defaultValue: 0.0 }),

    service_unit_pack: attr('boolean', { defaultValue: false }),
    service_unit_pack_volume: attr('number', { defaultValue: 0.0 }),

    service_unit_pick: attr('boolean', { defaultValue: false }),
    service_unit_pick_volume: attr('number', { defaultValue: 0.0 })

  });
});