define('ember-components/models/fields-needed', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    fields_needed: _emberData.default.attr('boolean'),

    account_type: _emberData.default.attr('boolean', { defaultValue: false }),
    address_line1: _emberData.default.attr('boolean', { defaultValue: false }),
    ssn: _emberData.default.attr('boolean', { defaultValue: false }),
    business_tax_id: _emberData.default.attr('boolean', { defaultValue: false }),
    city: _emberData.default.attr('boolean', { defaultValue: false }),
    day_of_birth: _emberData.default.attr('boolean', { defaultValue: false }),
    first_name: _emberData.default.attr('boolean', { defaultValue: false }),
    last_name: _emberData.default.attr('boolean', { defaultValue: false }),
    ssn_last_4: _emberData.default.attr('boolean', { defaultValue: false }),
    state: _emberData.default.attr('boolean', { defaultValue: false }),
    tos_accepted: _emberData.default.attr('boolean', { defaultValue: false }),
    zip: _emberData.default.attr('boolean', { defaultValue: false })
  });
});