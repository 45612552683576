define('ember-components/services/error-service', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Service = Ember.Service,
      get = Ember.get,
      service = Ember.inject.service;
  exports.default = Service.extend({
    notify: service(),

    showBackendValidationErrors: function showBackendValidationErrors(error) {
      var _this = this;

      if (error.validation_errors) {
        error.validation_errors.forEach(function (fieldError) {
          fieldError.errors.forEach(function (errorMsg) {
            get(_this, 'notify').error(errorMsg);
          });
        });
      } else {
        get(this, 'notify').error(error.detail);
      }
    }
  });
});