define('ember-components/components/confirm-popup/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    /**
    * Attach event handler on element
    *
    * @method didInsertElement
    * @return {void}
    * @public
    */
    didInsertElement: function didInsertElement() {
      var modal = $('#confirmModal-' + this.elementId);
      var triggers = $('#' + this.elementId);

      triggers.each(function () {
        var trigger = $(this);

        if (trigger.has(modal).length) {
          return;
        }

        trigger.on('click', function () {
          modal.modal();
        });
      });
    },


    /**
     * Text to show confirmation
     *
     * @type {string}
     */
    text: 'Are you sure?',

    /**
     * Element to attach
     *
     * @type {string}
     */
    elementId: '',

    /**
     * Default handler to be used for yes button click
     *
     * @method {onYes}
     */
    onYes: function onYes() {}
  });
});