define('ember-components/libs/types-shims', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.hasMany = exports.belongsTo = exports.structure = exports.string = exports.number = exports.boolean = undefined;
  var attr = _emberData.default.attr;
  var boolean = exports.boolean = attr('boolean');
  var number = exports.number = attr('number');
  var string = exports.string = attr('string');
  var structure = exports.structure = attr();

  var belongsTo = exports.belongsTo = _emberData.default.belongsTo;
  var hasMany = exports.hasMany = _emberData.default.hasMany;
});