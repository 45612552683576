define('ember-components/components/control/input/we-datepicker/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = Ember.Component.extend({
    tagName: '',

    id: computed(function () {
      return Ember.guidFor(this);
    }),

    name: computed('id', function () {
      return 'datepicker-' + this.get('id');
    }),

    didInsertElement: function didInsertElement() {
      var _this = this;

      var format = this.get('configProvider.dateSlashesFormat');
      var name = this.get('name');

      var startDate = null;
      if (this.get('value')) {
        startDate = this.get('value');
      } else if (this.get('minValue')) {
        startDate = moment(this.get('minValue'));
      } else {
        startDate = moment();
      }

      var minDate = null;
      if (this.get('minValue')) {
        minDate = moment(this.get('minValue'));
      } else {
        minDate = moment().subtract(100, 'y');
      }

      var maxDate = null;
      if (this.get('maxValue')) {
        maxDate = moment(this.get('maxValue'));
      } else if (this.get('noMaxDate')) {
        maxDate = moment().add(100, 'y');
      } else {
        maxDate = moment();
      }

      var isEmptyInitInput = this.get('noStartDate') === true;

      var el = $('input[name="' + name + '"]').daterangepicker({
        singleDatePicker: true,
        showDropdowns: true,
        locale: { format: format },
        startDate: startDate,
        minDate: minDate,
        maxDate: maxDate,
        autoUpdateInput: !isEmptyInitInput
      }, function (start) {
        if (isEmptyInitInput) {
          return;
        }

        var date = moment.utc(start).format(format);
        _this.changeDate(date);
      });

      this._datePicker = el.data('daterangepicker');

      // If we lock "autoUpdateInput" option of daterangepicker for don't change empty initial input,
      // We need add handle apply selected date.
      // And also we need lock handle set value at callback function of daterangepicker.
      if (isEmptyInitInput) {
        $('input[name="' + name + '"]').on('apply.daterangepicker', function (_event, picker) {
          var date = picker.startDate.format(picker.locale.format);
          picker.element.val(date);

          _this.changeDate(date);
        });
      }

      if (this.get('hasAvailability')) {
        var calendar = this._datePicker.container;
        var calendarBottomTemplate = document.querySelector('.we-datepicker-availability');
        var bottomContainerClassName = 'daterangepicker__bottom-container';
        if (!calendar.find('.' + bottomContainerClassName)[0] && calendarBottomTemplate) {
          calendarBottomTemplate.style.clear = 'both';
          calendarBottomTemplate.style.display = 'block';
          calendarBottomTemplate.classList.add(bottomContainerClassName);
          calendar.append(calendarBottomTemplate);
        }
      }
    },
    changeDate: function changeDate(date) {
      this.set('value', date);
      if (this.get('handlerChange')) {
        this.sendAction('handlerChange');
      }
    }
  });
});