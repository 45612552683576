define('ember-components/models/facility-search', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;
  exports.default = _emberData.default.Model.extend({
    additional_carton_kitting: attr('boolean'),
    additional_carton_labeling: attr('boolean'),
    additional_cross_dock: attr('boolean'),
    additional_price_labeling: attr('boolean'),
    additional_price_ticketing: attr('boolean'),
    additional_repacking: attr('boolean'),
    additional_ship_labeling: attr('boolean'),
    address: attr('string'),
    ambient_avg_rate: attr('number'),
    ambient_feet_amount: attr('number'),
    available_24h: attr('boolean'),
    available_friday: attr('boolean'),
    available_monday: attr('boolean'),
    available_saturday: attr('boolean'),
    available_sunday: attr('boolean'),
    available_thursday: attr('boolean'),
    available_time_from: attr('string'),
    available_time_to: attr('string'),
    available_tuesday: attr('boolean'),
    available_wednesday: attr('boolean'),
    certifications: attr(),
    city: attr('string'),
    cold_avg_rate: attr('number'),
    created: attr('number'),
    created_by_user: attr('number'),
    description: attr('string'),
    distance: attr('number'),
    insurance: attr('number'),
    is_published: attr('boolean'),
    location: attr(),
    match: attr('number'),
    max_storage_height: attr(),
    max_storage_weight: attr(),
    min_order_quantity: attr('number'),
    min_order_quantity_in_feet: attr('number'),
    min_order_quantity_in_pallets: attr('number'),
    modified: attr('string'),
    nickname: attr('string'),
    payment_frequency: attr('string'),
    payment_terms: attr('string'),
    product_type_agriculture: attr('boolean'),
    product_type_apparel: attr('boolean'),
    product_type_automotive: attr('boolean'),
    product_type_base_metals: attr('boolean'),
    product_type_consumer_product: attr('boolean'),
    product_type_electronics: attr('boolean'),
    product_type_industrial_materials: attr('boolean'),
    product_type_machinery: attr('boolean'),
    product_type_petroleum: attr('boolean'),
    product_type_pharmaceuticals: attr('boolean'),
    rating: attr(),
    receiving_doors: attr(),
    security_gated_access: attr('boolean'),
    security_onsite_guards: attr('boolean'),
    security_security_system: attr('boolean'),
    security_video_surveillance: attr('boolean'),
    service_carton_pick: attr('boolean'),
    service_carton_pick_rate: attr('number'),
    service_carton_receiving: attr('boolean'),
    service_carton_receiving_rate: attr('number'),
    service_labor: attr('boolean'),
    service_labor_rate: attr(),
    service_pallet_pick: attr('boolean'),
    service_pallet_pick_rate: attr('number'),
    service_pallet_receiving: attr('boolean'),
    service_pallet_receiving_rate: attr('number'),
    service_unit_pack: attr('boolean'),
    service_unit_pack_rate: attr('number'),
    service_unit_pick: attr('boolean'),
    service_unit_pick_rate: attr('number'),
    shipping_doors: attr(),
    size: attr('number'),
    special_avg_rate: attr('number'),
    special_storage_aib_certified: attr('boolean'),
    special_storage_c_tpat: attr('boolean'),
    special_storage_cbp_bonded: attr('boolean'),
    special_storage_dry_ambient: attr('boolean'),
    special_storage_fda_registered: attr('boolean'),
    special_storage_food_grade: attr('boolean'),
    special_storage_foreign_trade_zone: attr('boolean'),
    special_storage_frozen_refrigerated: attr('boolean'),
    special_storage_hazmat_certified: attr('boolean'),
    special_storage_iso: attr('boolean'),
    special_storage_tapa: attr('boolean'),
    special_storage_temperature_controlled: attr('boolean'),
    state: attr('string'),
    storage_ambient_available: attr('number'),
    storage_ambient_available_in_feet: attr('number'),
    storage_ambient_available_in_pallets: attr('number'),
    storage_ambient_rate: attr('number'),
    storage_ambient_rate_per_foot: attr('number'),
    storage_ambient_rate_per_pallet: attr('number'),
    storage_ambient_uom: attr('string'),
    storage_available: attr('number'),
    storage_avg_rate: attr('number'),
    storage_cold_available: attr('number'),
    storage_cold_available_in_feet: attr('number'),
    storage_cold_available_in_pallets: attr('number'),
    storage_cold_rate: attr('number'),
    storage_cold_rate_per_foot: attr('number'),
    storage_cold_rate_per_pallet: attr('number'),
    storage_cold_uom: attr('string'),
    storage_special_available: attr(),
    storage_special_available_in_feet: attr(),
    storage_special_available_in_pallets: attr(),
    storage_special_rate: attr(),
    storage_special_rate_per_foot: attr(),
    storage_special_rate_per_pallet: attr(),
    storage_special_uom: attr('string'),
    storage_uom: attr('string'),
    wms_vendor: attr(),
    zip: attr('string'),

    company: belongsTo('company'),
    photo: belongsTo('photo'),

    storageAcceptsString: Ember.computed('service_pallet_pick', 'service_carton_pick', 'service_unit_pick', 'service_unit_pack', function () {
      var pallet = this.get('service_pallet_pick');
      var carton = this.get('service_carton_pick');
      var unit = this.get('service_unit_pick') || this.get('service_unit_pack');

      var values = [];

      if (pallet) {
        values.push('Pallet');
      }

      if (carton) {
        values.push('Carton');
      }

      if (unit) {
        values.push('Unit');
      }

      return values.length ? values.join(', ') : '';
    })
  });
});