define('ember-components/components/interactive/upload-agreement/component', ['exports', 'ember-components/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service,
      get = Ember.get;


  var ERROR_WRONG_FORMAT = 'Only PDF files';
  var FORMATS = ['pdf'];

  exports.default = Ember.Component.extend({
    notify: service(),
    store: service(),
    session: service(),

    fileIsAllowed: function fileIsAllowed(name, formats) {
      return formats.includes(name.split('.').slice(-1)[0].toLowerCase());
    },

    minDate: moment.utc(),

    actions: {
      load: function load() {
        var file = $('#saved_doc').html().length;
        if (file === 0) {
          $('#browse-file').show();
          $('#saved-file').hide();
        } else {
          $('#browse-file').hide();
          $('#saved-file').show();
        }
        $('#spinner').hide();
      },
      submit: function submit() {
        $('#spinner').show();
        var start = this.get('startDate');
        var end = this.get('endDate');
        var engagement = this.get('model');
        var engagement_rec = engagement.get('id');
        var API_PATH = '' + _environment.default.APP.API_HOST + _environment.default.APP.BASE_PATH;
        var file = $('#upload-files')[0].files.length;

        if (file === 0) {
          get(this, 'notify').warning('Please upload SOW document');
          return;
        }

        Ember.$.ajax({
          url: API_PATH + '/engagements/update/',
          type: 'POST',
          data: {
            engagementID: engagement_rec,
            startDate: start.format('YYYY-MM-DD'),
            endDate: end.format('YYYY-MM-DD')
          },
          headers: { Authorization: 'Token ' + this.get('session').session.content.authenticated.key }
        }).then(function (resp) {
          var returnedData = JSON.parse(resp);
          if (returnedData.status == 'success') {
            window.location.reload();
          }
        }).catch(function (error) {
          // handle errors here
        });
      },
      uploadFiles: function uploadFiles(event) {
        var files = Array.from(event.dataTransfer ? event.dataTransfer.files : event.target.files);
        var engagement = this.get('model');
        var engagement_rec = engagement.get('id');

        $('#spinner').show();

        // Validation before uploading
        var _iteratorNormalCompletion = true;
        var _didIteratorError = false;
        var _iteratorError = undefined;

        try {
          for (var _iterator = files[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
            var _file = _step.value;

            if (!this.fileIsAllowed(_file.name, FORMATS)) {
              get(this, 'notify').warning(ERROR_WRONG_FORMAT);
              return;
            }
          }
        } catch (err) {
          _didIteratorError = true;
          _iteratorError = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion && _iterator.return) {
              _iterator.return();
            }
          } finally {
            if (_didIteratorError) {
              throw _iteratorError;
            }
          }
        }

        var file = $('#upload-files')[0].files[0];
        var API_PATH = '' + _environment.default.APP.API_HOST + _environment.default.APP.BASE_PATH;
        var url_path = '' + _environment.default.APP.API_HOST;
        var client = new XMLHttpRequest();
        var formData = new FormData();
        formData.append('file', file);
        formData.append('engagement_id', engagement_rec);
        client.open('post', API_PATH + '/engagements/upload-agreement/', true);
        client.send(formData);

        client.onreadystatechange = function () {
          if (client.readyState === 4 && client.status === 200) {
            var _file2 = $.parseJSON(client.responseText).file;
            var file_name = _file2.split('/')[3];
            $('#spinner').hide();
            $('#saved_doc').html(file_name);
            $('#signed_doc').attr('href', _file2);
            $('#browse-file').hide();
            $('#saved-file').show();
          }
        };
      },
      cancel: function cancel() {
        var API_PATH = '' + _environment.default.APP.API_HOST + _environment.default.APP.BASE_PATH;
        var engagement = this.get('model');
        var engagement_rec = engagement.get('id');

        Ember.$.ajax({
          url: API_PATH + '/engagements/update/',
          type: 'POST',
          data: {
            engagementID: engagement_rec,
            status: 'cancel'
          },
          headers: { Authorization: 'Token ' + this.get('session').session.content.authenticated.key }
        }).then(function (resp) {
          var returnedData = JSON.parse(resp);
          if (returnedData.status === 'success') {
            window.location.reload();
          }
        }).catch(function (error) {
          // handle errors here
        });
      }
    }
  });
});