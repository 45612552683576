define('ember-components/dashboard-buyer/route', ['exports', 'ember-components/libs/base/route'], function (exports, _route) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  exports.default = _route.default.extend({
    templateName: 'dashboard-common/buyer',
    session: service('session'),
    currentUser: service(),
    titleToken: 'Buyer Dashboard',

    model: function model(params) {
      return Ember.RSVP.hash({
        cards: this.store.findAll('card').catch(function () {
          return [];
        }),
        bankAccounts: this.store.findAll('bank-account').catch(function () {
          return [];
        }),
        engagementStats: this.store.queryRecord('engagement-stat', {
          type: 'buyer'
        }),
        requestedEngagements: this.store.query('engagement', {
          status: 'requested',
          type: 'buyer'
        }),
        currentEngagements: this.store.query('engagement', {
          status: 'current',
          type: 'buyer'
        }),
        requestedAmendments: this.store.query('amendment', {
          type: 'buyer',
          status__in: 'pending,supplier_signed'
        }),
        requestedInvoices: this.store.query('invoice', {
          type: 'buyer',
          status__in: 'ready,pending,late,on_review,invalid'
        }),
        accountId: params.accountId,
        openVerified: params.openVerified
      });
    }
  });
});