define('ember-components/ask-form/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route,
      service = Ember.inject.service,
      get = Ember.get;
  exports.default = Route.extend({
    googleMaps: service(),

    renderTemplate: function renderTemplate() {
      var _this = this;

      // Delay before google maps load.
      get(this, 'googleMaps').loadGoogleMaps().done(function () {
        _this.render();
      });
    }
  });
});