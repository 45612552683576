define('ember-components/services/messages-hub', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  // function foo(model) {
  //   if (this.get('session').get('isAuthenticated')) {
  //     model.unreadCount()
  //       .then((result) => {
  //         const { count } = result;
  //         this.set('unreadCount', count);
  //       });
  //   }
  // }

  function setFirstConversation() {
    var _this = this;

    if (this.get('session').get('isAuthenticated')) {
      this.get('store').findAll('conversation').then(function (model) {
        if (model.get('length') > 0) {
          var first = model.get('firstObject');
          _this.set('firstConversation', first);
        }
      });
    }
  }

  exports.default = Ember.Service.extend({
    configProvider: Ember.inject.service(),
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    unreadCount: 0,

    init: function init() {
      // const timer = this.get('configProvider.messageTimer');
      var store = this.get('store');
      // eslint-disable-next-line no-unused-vars
      var model = store.createRecord('message');
      // TODO: Uncomment next lines when messages will be enabled.
      // setTimeout(foo.bind(this, model), 100);
      // setInterval(foo.bind(this, model), timer);
      setTimeout(setFirstConversation.bind(this), 100);
    }
  });
});