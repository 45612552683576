define('ember-components/overlay/service', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var pages = {
    search: {
      selector: '.overlay-for-dropdown'
    }
  };

  function getSelector(pageName) {
    if (pageName in pages) {
      var selector = pages[pageName].selector;

      return selector;
    }
  }

  function computedEntity(pageName) {
    return Ember.computed(function () {
      return {
        hide: this.hide.bind(this, pageName),
        show: this.show.bind(this, pageName),
        toggle: this.toggle.bind(this, pageName),
        instance: this
      };
    });
  }

  exports.default = Ember.Service.extend({
    search: computedEntity('search'),
    hide: function hide(pageName) {
      var selector = getSelector(pageName);
      $('.dropdown:has(.dropdown-toggle)').removeClass('open');
      $(selector).removeClass('show');
    },
    show: function show(pageName) {
      var selector = getSelector(pageName);
      $(selector).addClass('show');
    },
    toggle: function toggle(pageName) {
      var selector = getSelector(pageName);
      $(selector).toggleClass('show');
    }
  });
});