define('ember-components/models/service', ['exports', 'ember-data', 'ember-components/config/environment'], function (exports, _emberData, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;


  var API_PATH = '' + _environment.default.APP.API_HOST + _environment.default.APP.BASE_PATH;

  exports.default = Model.extend({
    description: attr('string'),
    rate: attr('number'),
    uom: attr('string', { defaultValue: 'Non provide' }),
    quantity: attr('number'),
    sub_total: attr('number'),
    invoice: belongsTo('invoice'),

    save: function save(data, serv_id, index, total_id) {
      if (serv_id != null) {
        Ember.$.ajax({
          url: API_PATH + '/invoices/service/',
          type: 'POST',
          data: { data: data, update_id: serv_id }
        }).then(function (resp) {
          var returnedData = JSON.parse(resp);
          return returnedData;
        });
      } else if (total_id === 'first') {
        Ember.$.ajax({
          url: API_PATH + '/invoices/service/',
          type: 'POST',
          data: { data: data, first: 'first' }
        }).then(function (resp) {
          var returnedData = JSON.parse(resp);
          return returnedData;
        });
      } else {
        Ember.$.ajax({
          url: API_PATH + '/invoices/service/',
          type: 'POST',
          data: data
        }).then(function (resp) {
          var returnedData = JSON.parse(resp);
          $('#' + total_id + index).attr('id', returnedData.id);
          return returnedData;
        });
      }
    }
  });
});