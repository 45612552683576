define('ember-components/components/dashboard-notifications-buyer/component', ['exports', 'ember-components/mixins/end-engagement-notifications'], function (exports, _endEngagementNotifications) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      computed = Ember.computed,
      service = Ember.inject.service;
  exports.default = Component.extend(_endEngagementNotifications.default, {
    store: service(),
    router: service(),
    notVerifiedAccounts: computed.filterBy('model.bankAccounts', 'verified', false),
    customEngagements: computed('model.requestedEngagements', function () {
      var requestedEngagements = this.get('model.requestedEngagements');
      return requestedEngagements.filterBy('is_custom', true).filterBy('buyer_sign_date', null).sortBy('id');
    }),
    engagementsAmendments: computed('model.requestedAmendments', function () {
      var requestedAmendments = this.get('model.requestedAmendments');
      return requestedAmendments.toArray();
    }),
    releasedInvoices: computed('model.requestedInvoices', function () {
      var requestedInvoices = this.get('model.requestedInvoices');
      return requestedInvoices.toArray().sort(function (a, b) {
        return Number(a.id) - Number(b.id);
      });
    }),
    isVerificationModalOpened: false,
    isConfirmationModalOpened: false,
    accountId: null,

    isNotificationsShown: computed('defaultCard', 'customEngagements', 'engagementsAmendments', 'releasedInvoices', 'notVerifiedAccounts', function () {
      return this.get('defaultCard') == null || this.get('customEngagements').length > 0 || this.get('engagementsAmendments').length > 0 || this.get('releasedInvoices').length > 0 || this.get('notVerifiedAccounts').length > 0 || this.get('ableToTerminateEngagements').length > 0 || this.get('inTerminationEngagements').length > 0 || this.get('afterTerminationEngagements').length > 0 || this.get('beforeEndingEngagements').length > 0;
    }),

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      var modelOpenVerified = this.get('model.openVerified');
      var modelAccountId = this.get('model.accountId');
      if (modelOpenVerified && modelAccountId) {
        this.set('isVerificationModalOpened', true);
        this.set('accountId', modelAccountId);
      }
    },


    actions: {
      openVerificationModal: function openVerificationModal(accountId) {
        this.set('accountId', accountId);
        if (this.get('accountId')) {
          this.set('isVerificationModalOpened', true);
        }
      },
      closeVerificationModal: function closeVerificationModal() {
        this.get('router').transitionTo({
          queryParams: { openVerified: undefined, accountId: undefined }
        });
        this.set('accountId', null);
        this.set('isVerificationModalOpened', false);
      },
      closeConfirmationModal: function closeConfirmationModal() {
        var _this = this;

        this.set('isConfirmationModalOpened', false);
        this.get('store').findAll('bank-account').then(function (response) {
          return _this.set('model.bankAccounts', response);
        });
      }
    }
  });
});