define('ember-components/components/presenter/engagement-dashboard/profile-info/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',

    type: '',

    engagement: Ember.computed.alias('model.engagement'),
    buyerRating: Ember.computed.alias('engagement.buyer_rating'),
    userProfile: Ember.computed('engagement.{user,facility.operator}', 'isSupplier', function () {
      var supplierProfile = this.get('engagement.user');
      var buyerProfile = this.get('engagement.facility.operator');

      return this.get('isSupplier') ? supplierProfile : buyerProfile;
    }),
    avatarUrl: Ember.computed(function () {
      return '/img/icons/user.svg';
    }),
    isSupplier: Ember.computed.equal('type', 'supplier'),
    isBuyer: Ember.computed.equal('type', 'buyer'),
    joinedDate: Ember.computed('userProfile.date_joined', function () {
      return moment(this.get('userProfile.date_joined')).format('MMMM YYYY');
    }),
    title: Ember.computed('type', function () {
      var type = this.get('type');

      switch (type) {
        case 'supplier':
          return 'Buyer Profile';

        case 'buyer':
          return 'Facility Operator Profile';

        default:
          console.error('You must set type!');
      }
    }),

    actions: {
      sendMessage: function sendMessage() {
        this.sendAction('sendMessage');
      }
    }
  });
});