define('ember-components/mixins/cp-validator', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    model: null,
    field: null,
    display: false,
    isTouched: Ember.computed('fieldValue', function () {
      var changedAttrs = this.get('model').changedAttributes();

      return Object.keys(changedAttrs).indexOf(this.get('field')) !== -1;
    }),
    showErrors: Ember.computed('isInvalid', 'isTouched', 'display', function () {
      return this.get('isInvalid') && (this.get('isTouched') || this.get('display'));
    }),

    init: function init() {
      this._super.apply(this, arguments);

      var field = this.get('field');

      Ember.defineProperty(this, 'fieldValue', Ember.computed.readOnly('model.' + field));
      Ember.defineProperty(this, 'isInvalid', Ember.computed.readOnly('model.validations.attrs.' + field + '.isInvalid'));
    }
  });
});