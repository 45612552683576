define('ember-components/components/cp-validator-input-wrap/component', ['exports', 'ember-components/mixins/cp-validator'], function (exports, _cpValidator) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_cpValidator.default, {
    tagName: '',
    hideMsg: false
  });
});