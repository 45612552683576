define('ember-components/libs/columns-definitions/invoices/service-fees/inbound-services', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = [{
    propertyName: 'service_pallet_receiving_volume',
    propertyName1: 'service_pallet_receiving_rate',
    ckeckProperty: 'service_pallet_receiving',
    title: 'Pallet Receiving',
    type: 'pallet'
  }, {
    propertyName: 'service_carton_receiving_volume',
    propertyName1: 'service_carton_receiving_rate',
    ckeckProperty: 'service_carton_receiving',
    title: 'Carton Receiving',
    type: 'carton'
  }];
});