define('ember-components/models/end-engagement', ['exports', 'ember-data', 'ember-cp-validations'], function (exports, _emberData, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _emberCpValidations.buildValidations)({
    terminationDate: (0, _emberCpValidations.validator)('presence', true),
    endReasonText: (0, _emberCpValidations.validator)('presence', true),
    endReasonQuestion: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('collection', true)]
  });

  exports.default = _emberData.default.Model.extend(Validations, {
    engagement: _emberData.default.attr('number'),
    terminationDate: _emberData.default.attr('string'),
    endReasonText: _emberData.default.attr('string'),
    endReasonQuestion: _emberData.default.hasMany('end-engagement-reason')
  });
});