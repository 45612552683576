define('ember-components/models/storage-type', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr;
  exports.default = Model.extend({
    dry_ambient: attr('boolean'),
    temperature_controlled: attr('boolean'),
    frozen_refrigerated: attr('boolean'),
    fda_registered: attr('boolean'),
    haz_mat_certified: attr('boolean'),
    aib_certified: attr('boolean'),
    cbp_bonded: attr('boolean'),
    foreign_trade_zone: attr('boolean'),
    food_grade: attr('boolean'),
    iso: attr('boolean'),
    ctpat: attr('boolean'),
    tapa: attr('boolean'),
    carton_labeling: attr('boolean'),
    kitting: attr('boolean'),
    cross_dock: attr('boolean'),
    price_ticker: attr('boolean'),
    repacking: attr('boolean'),
    ship_label: attr('boolean'),
    price_label: attr('boolean'),
    floor_space: attr('boolean'),
    racking: attr('boolean'),
    secured_room: attr('boolean'),
    pick_podule: attr('boolean'),
    dedicated_room: attr('boolean'),
    gated_access: attr('boolean'),
    site_guard: attr('boolean'),
    security_system: attr('boolean'),
    video_surveillance: attr('boolean')
  });
});