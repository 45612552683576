define('ember-components/mixins/end-engagement-notifications', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var DAYS_TO_SHOW_NOTIFICATION_AFTER_TERMINATION = 5;
  var DAYS_TO_SHOW_NOTIFICATION_BEFORE_END_ENGAGEMENT = 10;

  /** Dashboard end engagement messages mixin. */
  exports.default = Ember.Mixin.create({
    ableToTerminateEngagements: Ember.computed('model.currentEngagements', function () {
      var currentEngagements = this.get('model.currentEngagements');
      return currentEngagements.filterBy('isActiveStatusAbleToTerminate', true).sortBy('id');
    }),

    inTerminationEngagements: Ember.computed('model.currentEngagements', function () {
      var currentEngagements = this.get('model.currentEngagements');
      return currentEngagements.filterBy('termination_id').filter(function (engagement) {
        return moment().isBefore(engagement.get('termination_date'));
      }).sortBy('id').map(function (engagement) {
        var terminationEndDate = engagement.get('termination_date');
        engagement.set('termination_date', moment(terminationEndDate).format('MM/DD/YYYY'));
        return engagement;
      });
    }),

    /**
     * Examples: If engagement end date is: March 4th. Five days to show: March 4th to March 8th
     */
    afterTerminationEngagements: Ember.computed('model.currentEngagements', function () {
      var currentEngagements = this.get('model.currentEngagements');
      return currentEngagements.filter(function (engagement) {
        var terminationDate = engagement.get('end');
        var isSameOrAfterTerminationDate = moment().isSameOrAfter(terminationDate);
        var lastDayToShow = moment(terminationDate).add(DAYS_TO_SHOW_NOTIFICATION_AFTER_TERMINATION, 'days');
        var INCLUDE_FIRST_DAY_AND_EXCLUDE_LAST_DAY = '[)';
        var isAvailableToShow = moment().isBetween(terminationDate, lastDayToShow, 'days', INCLUDE_FIRST_DAY_AND_EXCLUDE_LAST_DAY);

        return isSameOrAfterTerminationDate && isAvailableToShow;
      }).sortBy('id');
    }),

    /** We show notice 10 days in advance. */
    beforeEndingEngagements: Ember.computed('model.currentEngagements', 'daysToEnd', function () {
      var currentEngagements = this.get('model.currentEngagements');
      return currentEngagements.filter(function (engagement) {
        var endDate = engagement.get('end');
        var firstDayToShow = moment(endDate).subtract(DAYS_TO_SHOW_NOTIFICATION_BEFORE_END_ENGAGEMENT, 'days');
        var INCLUDE_FIRST_DAY_AND_EXCLUDE_LAST_DAY = '[)';
        var isAvailableToShow = moment().isBetween(firstDayToShow, endDate, 'days', INCLUDE_FIRST_DAY_AND_EXCLUDE_LAST_DAY);
        return isAvailableToShow;
      }).sortBy('id');
    })
  });
});