define('ember-components/adapters/stats', ['exports', 'ember-components/adapters/application', 'ember-components/config/environment'], function (exports, _application, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    namespace: _environment.default.APP.API_NAMESPACE + '/engagements',

    urlForFindRecord: function urlForFindRecord(_id) {
      return _environment.default.APP.API_HOST + '/' + _environment.default.APP.API_NAMESPACE + '/engagements/stats/';
    }
  });
});