define('ember-components/components/interactive/panels/invoice/header-panel/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      service = Ember.inject.service;
  exports.default = Ember.Component.extend({
    store: service(),

    PAYMENT_TERMS: {
      default: 'Due upon Acceptance',
      net_7: 'Net 7',
      net_15: 'Net 15',
      net_30: 'Net 30',
      net_60: 'Net 60'
    },

    paymentTerms: computed('payment_terms', function () {
      return this.PAYMENT_TERMS[this.get('model.payment_terms')];
    }),

    startDate: computed('model.start_date', {
      get: function get() {
        return this.getDate('model.start_date');
      },
      set: function set(key, value) {
        this.set('model.start_date', value);
        return value;
      }
    }),

    endDate: computed('model.end_date', {
      get: function get() {
        return this.getDate('model.end_date');
      },
      set: function set(key, value) {
        this.set('model.end_date', value);
        return value;
      }
    }),

    invoiceDate: computed('model.invoice_date', {
      get: function get() {
        return this.getDate('model.invoice_date');
      },
      set: function set(key, value) {
        this.set('model.invoice_date', value);
        return value;
      }
    }),

    isLast: computed('model.getLast', function () {
      var id = this.get('model.id');
      var lastId = this.get('model.getLast.id');
      return id === lastId;
    }),

    notEditDate: computed('model.isReleased', 'model.is_first', function () {
      var is = this.get('model.isReleased');
      var isFirst = this.get('model.is_first');
      return is || !isFirst;
    }),

    getFormat: function getFormat() {
      var configProvider = this.get('configProvider');
      return configProvider.get('dateSlashesFormat');
    },
    getDate: function getDate(path) {
      var date = this.get(path);
      if (date) {
        var format = this.getFormat();
        return moment.utc(date).format(format);
      }
    },


    actions: {
      changeBillingPeriod: function changeBillingPeriod() {
        var eventEmitter = this.get('eventEmitter');
        var channelName = this.get('channelName');
        eventEmitter.trigger(channelName);
      }
    }
  });
});