define('ember-components/components/facility-form/facility-hours/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      set = Ember.set;
  exports.default = Component.extend({

    /**
     * Available everyday
     * 
     * @type {boolean}
     */
    availableEveryday: false,

    actions: {

      /**
       * When user checked "available 7 days a week" have to select all weeks
       *
       * @param {boolean} value
       * @return {void}
       */
      alwaysChanged: function alwaysChanged(value) {
        if (value) {
          set(this, 'facility.available_monday', true);
          set(this, 'facility.available_tuesday', true);
          set(this, 'facility.available_thursday', true);
          set(this, 'facility.available_wednesday', true);
          set(this, 'facility.available_friday', true);
          set(this, 'facility.available_saturday', true);
          set(this, 'facility.available_sunday', true);
        }
      },


      /**
       * Disable time range inputs if chosen ``everytime = true``
       *
       * @return void
       */
      everyTimeChanged: function everyTimeChanged() {
        set(this, 'facility.available_time_from', '12:00 AM');
        set(this, 'facility.available_time_to', '12:00 AM');
      }
    }
  });
});