define('ember-components/components/interactive/modals/info-modal/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    titleButton: 'Go it',

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.checkGotit();
    },
    checkGotit: function checkGotit() {
      if (this.get('noAvailable') == 'true') {
        this.set('nogotit', true);
      }
    },


    actions: {
      close: function close() {
        this.set('open', false);
        var handler = this.get('handler');
        if (handler) {
          handler();
        }
      }
    }
  });
});