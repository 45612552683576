define('ember-components/mixins/step', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Mixin = Ember.Mixin,
      set = Ember.set;
  exports.default = Mixin.create({

    /**
     * Set ``step`` property to model object and return it
     *
     * @method model
     * @return {object}
     * @public
     */
    model: function model() {
      var model = this._super.apply(this, arguments);
      set(model, 'step', this.get('step'));
      return model;
    }
  });
});