define('ember-components/components/presenter/engagement-dashboard/terms-of-agreement/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    isShowBasicLicense: false,
    isShowWarehouseLicense: false,
    licensePreviewOptions: null,
    engagementOptions: null,
    isSupplier: Ember.computed.equal('type', 'supplier'),
    isBuyer: Ember.computed.equal('type', 'buyer'),

    actions: {
      openBasicLicenseModal: function openBasicLicenseModal(isAmendment, id) {
        if (isAmendment) {
          this.set('licensePreviewOptions', {
            amendment: id,
            type: this.get('type')
          });
          this.set('engagementOptions', {
            engagement: this.get('model.engagement.id'),
            amendment: id
          });
        } else {
          this.set('licensePreviewOptions', { engagement: id });
          this.set('engagementOptions', {
            engagement: id
          });
        }
        this.set('isShowBasicLicense', true);
      },
      openWarehouseLicenseModal: function openWarehouseLicenseModal(isAmendment, id) {
        if (isAmendment) {
          this.set('licensePreviewOptions', {
            amendment: id,
            type: this.get('type')
          });
          this.set('engagementOptions', {
            engagement: this.get('model.engagement.id'),
            amendment: id
          });
        } else {
          this.set('licensePreviewOptions', { engagement: id });
          this.set('engagementOptions', {
            engagement: id
          });
        }
        this.set('isShowWarehouseLicense', true);
      }
    }
  });
});