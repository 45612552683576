define('ember-components/serializers/engagement', ['exports', 'ember-data', 'ember-components/serializers/drf'], function (exports, _emberData, _drf) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var EmbeddedRecordsMixin = _emberData.default.EmbeddedRecordsMixin;
  exports.default = _drf.default.extend(EmbeddedRecordsMixin, {
    attrs: {
      user: { embedded: 'always' },
      buyer_rating: { embedded: 'always' },
      persistent_services: { embedded: 'always' },
      amendments: { embedded: 'always' },
      acceptance_set: { embedded: 'always' }
    },

    serialize: function serialize() {
      var json = this._super.apply(this, arguments);

      // convert dates into string
      json.start = moment.utc(json.start).format('YYYY-MM-DD');
      json.end = moment.utc(json.end).format('YYYY-MM-DD');

      return json;
    }
  });
});