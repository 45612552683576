define('ember-components/components/interactive/modals/base-modal/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    actions: {
      submit: function submit() {
        var method = this.get('handleSubmit');
        method();
      },
      close: function close() {
        this.set('open', false);
        var method = this.get('handleClose');
        if (method) {
          method();
        }
        var model = this.get('model');
        if (Object.keys(model.changedAttributes()).length && model.get('isNew')) {
          var route = this.get('currentRoute').getRoute();
          route.refresh();
        }
      }
    }
  });
});