define('ember-components/components/presenter/engagement-dashboard/engagement-summary/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    // We use 'engagement' entity as engagement or as amendment according context.
    tagName: '',
    constants: Ember.inject.service(),
    engagementStatus: Ember.computed.alias('model.engagement.status'),
    amendmentStatus: Ember.computed.alias('model.amendment.status'),
    isBuyer: Ember.computed.equal('type', 'buyer'),
    isSupplier: Ember.computed.equal('type', 'supplier'),
    isBayerRequested: Ember.computed.equal('engagementStatus', 'buyer_requested'),
    persistentServicesFee: Ember.computed('engagement.persistent_services_fee', function () {
      return this.get('engagement.persistent_services_fee') ? this.get('engagement.persistent_services_fee') : 0;
    }),

    rentPerMonth: Ember.computed.alias('engagement.rentPerMonth'),

    monthlyServiceFee: Ember.computed('isSupplier', 'rentPerMonth', 'monthlyPersistentServicesFee', 'engagement.supplier_app_fee_percent', 'engagement.buyer_app_fee_percent', function () {
      var userSupplier = this.get('isSupplier');
      var fee = userSupplier ? this.get('engagement.supplier_app_fee_percent') : this.get('engagement.buyer_app_fee_percent');

      return (this.get('rentPerMonth') + this.get('monthlyPersistentServicesFee')) * (fee / 100);
    }),

    monthlyPersistentServicesFee: Ember.computed('engagement.monthly_persistent_services_fee', function () {
      return this.get('engagement.monthly_persistent_services_fee');
    }),

    totalMonthly: Ember.computed('isSupplier', 'engagement.monthly_fee', 'monthlyServiceFee', function () {
      var userSupplier = this.get('isSupplier');
      var monthlyFee = this.get('engagement.monthly_fee');
      return userSupplier ? monthlyFee - this.get('monthlyServiceFee') : monthlyFee + this.get('monthlyServiceFee');
    }),

    showCancelBtn: Ember.computed('model.amendment', 'engagementStatus', 'isBuyer', function () {
      if (this.get('model.amendment')) {
        return false;
      }

      var status = this.get('engagementStatus');
      return (status === 'supplier_signed' || status === 'buyer_requested') && this.get('isBuyer');
    }),

    totalRent: Ember.computed.alias('engagement.storage_fee'),

    totalServiceFee: Ember.computed('isSupplier', 'engagement.supplier_app_fee', 'engagement.buyer_app_fee', function () {
      var userSupplier = this.get('isSupplier');
      return userSupplier ? this.get('engagement.supplier_app_fee') : this.get('engagement.buyer_app_fee');
    }),

    totalContract: Ember.computed('isSupplier', 'engagement.supplier_total_fee', 'engagement.buyer_total_fee', function () {
      var userSupplier = this.get('isSupplier');
      return userSupplier ? this.get('engagement.supplier_total_fee') : this.get('engagement.buyer_total_fee');
    }),

    isShowInvoiceButton: Ember.computed('model.amendment', 'isSupplier', 'model.engagement.substatus', function () {
      if (this.get('model.amendment')) {
        return false;
      }

      var substatus = this.get('model.engagement.substatus');
      var allowSubstatus = substatus === 'expired' || substatus === 'current' || substatus === 'upcoming';
      return allowSubstatus && this.get('isSupplier');
    }),

    firstMonthServiceFee: Ember.computed('isSupplier', 'engagement.buyer_first_month_fee', 'engagement.supplier_first_month_fee', function () {
      var userSupplier = this.get('isSupplier');
      return userSupplier ? this.get('engagement.supplier_first_month_fee') : this.get('engagement.buyer_first_month_fee');
    }),

    firstMonthTotal: Ember.computed('isSupplier', 'engagement.buyer_due_today_total', 'engagement.supplier_due_today_total', function () {
      var userSupplier = this.get('isSupplier');
      return userSupplier ? this.get('engagement.supplier_due_today_total') : this.get('engagement.buyer_due_today_total');
    }),

    isEndEngagementShown: Ember.computed('termination_id', function () {
      return this.get('engagement.termination_id') == null;
    }),

    isEndEngagementModelOpen: false,

    actions: {
      cancel: function cancel() {
        this.sendAction('cancel');
      },
      onEndEngagementOpen: function onEndEngagementOpen() {
        this.set('isEndEngagementModelOpen', true);
      },
      onEndEngagementClose: function onEndEngagementClose() {
        this.set('isEndEngagementModelOpen', false);
      },
      onEndEngagementComplete: function onEndEngagementComplete() {
        this.sendAction('onEndEngagementComplete');
      }
    }
  });
});