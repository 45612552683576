define('ember-components/models/stats', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr;
  exports.default = Model.extend({
    supplier_signed: attr('number'),
    buyer_signed: attr('number'),
    buyer_requested: attr('number'),
    pending: attr('number'),
    rejected: attr('number'),
    canceled: attr('number'),
    upcoming: attr('number'),
    current: attr('number'),
    past: attr('number'),
    expired: attr('number')
  });
});