define('ember-components/models/state', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr;
  exports.default = Model.extend({

    /**
     * Short state name (Example: CA)
     *
     * @property short
     * @type {string}
     * @public
     */
    short: attr('string'),

    /**
     * Full state name (Example: California)
     *
     * @property full
     * @type {string}
     * @public
     */
    full: attr('string')
  });
});