define('ember-components/components/question-contact-info/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    title: 'Questions?',
    tagName: '',
    facility: null
  });
});