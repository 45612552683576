define('ember-components/components/engagement-request/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      computed = Ember.computed,
      service = Ember.inject.service;
  exports.default = Component.extend({
    router: service(),
    startDate: computed('model.engagement.start', function () {
      var start = this.get('model.engagement.start');
      return moment(start).format('MMM DD, YYYY');
    }),
    endDate: computed('model.engagement.end', function () {
      var end = this.get('model.engagement.end');
      return moment(end).format('MMM DD, YYYY');
    }),

    // TODO (WH-551): check if we use this, before we passed this variable to the params, but now we do not.
    servicesNeeded: computed('model.params.isNeedServices', function () {
      return this.get('model.params.isNeedServices') === 'true' ? 'Yes' : 'No';
    }),
    contractDuration: computed('model.engagement.start', 'model.engagement.end', function () {
      var endDate = this.get('model.engagement.end');
      var startDate = this.get('model.engagement.start');
      return moment(endDate).diff(startDate, 'month');
    }),
    isShowWarehouseLicense: false,
    isShowBasicLicense: false,
    isAgreeWarehouseLicense: false,
    isAgreeBasicLicense: false,
    isSubmitAvailable: computed('isAgreeWarehouseLicense', 'isAgreeBasicLicense', function () {
      return this.get('isAgreeWarehouseLicense') && this.get('isAgreeBasicLicense');
    }),

    actions: {
      agreeAndContinue: function agreeAndContinue() {
        this.get('router').transitionTo('engagements.payment-confirmation', this.get('model.facility.id'), {
          queryParams: {
            dateStart: this.get('model.params.dateStart'),
            dateEnd: this.get('model.params.dateEnd'),
            storage_units: this.get('model.params.storage_units'),
            storage_uom: this.get('model.params.storage_uom'),
            storageType: this.get('model.params.storageType'),
            usage: this.get('model.params.usage'),
            engagement_id: this.get('model.params.engagement_id'),
            amendment_id: this.get('model.params.amendment_id')
          }
        });
      }
    }
  });
});