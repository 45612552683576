define('ember-components/validators/ssn-last-4', ['exports', 'ember-cp-validations/validators/base'], function (exports, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var SsnLast4 = _base.default.extend({
    validate: function validate(value, _, model) {
      if (model.get('personal_id_number') && value === model.get('personal_id_number').slice(-4)) {
        return true;
      }
      return 'Should be the last 4 digits of Personal ID Number';
    }
  });

  exports.default = SsnLast4;
});