define('ember-components/components/new-listing-details/reservation-fixed-block/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',

    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      if (window) {
        window.removeEventListener('scroll', this.handlePageScroll);
      }
    },
    init: function init() {
      this._super.apply(this, arguments);
      if (window) {
        window.addEventListener('scroll', this.handlePageScroll.bind(this));
      }
    },
    handlePageScroll: function handlePageScroll() {
      var el = document.querySelector('.facility-reservation-fixed-box');
      if (!el) {
        return;
      }

      var scroll = window.scrollY;
      if (scroll >= 1000) {
        el.style.opacity = 1;
      } else {
        el.style.opacity = 0;
      }
    },


    actions: {
      scrollToTop: function scrollToTop() {
        if (window) {
          window.scrollTo({ top: 0, behavior: 'smooth' });
        }
      }
    }
  });
});