define('ember-components/models/buyer-rating', ['exports', 'ember-data', 'ember-cp-validations'], function (exports, _emberData, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var starValidations = [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('number', {
    allowString: true,
    integer: false,
    gt: 0
  })];

  var Validations = (0, _emberCpValidations.buildValidations)({
    overall: starValidations,
    responsiveness: starValidations,
    check_in_check_out: starValidations
  });

  exports.default = _emberData.default.Model.extend(Validations, {
    overall: _emberData.default.attr('number', { defaultValue: 0 }),
    overall_rating: _emberData.default.attr('number', { defaultValue: 0 }),
    responsiveness: _emberData.default.attr('number', { defaultValue: 0 }),
    check_in_check_out: _emberData.default.attr('number', { defaultValue: 0 }),
    buyer_review_count: _emberData.default.attr('number', { defaultValue: 0 }),

    engagement: _emberData.default.belongsTo('engagement')
  });
});