define('ember-components/helpers/if-path', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.ifPath = ifPath;
  function ifPath(params) {
    return window.location.href.search(params[0]) !== -1;
  }

  exports.default = Ember.Helper.helper(ifPath);
});