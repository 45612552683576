define('ember-components/adapters/card', ['exports', 'ember-components/adapters/application', 'ember-components/config/environment'], function (exports, _application, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    namespace: _environment.default.APP.API_NAMESPACE + '/payments/customer',
    shouldReloadAll: function shouldReloadAll() {
      return true;
    },
    shouldReloadRecord: function shouldReloadRecord() {
      return true;
    },
    isInvalid: function isInvalid(status) {
      return status === 400;
    },
    handleResponse: function handleResponse(status, headers, payload) {
      if (this.isSuccess(status, headers, payload)) {
        return payload;
      } else if (this.isInvalid(status)) {
        return payload;
      }
    }
  });
});