define('ember-components/validators/boolean', ['exports', 'ember-cp-validations/validators/base'], function (exports, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Boolean = _base.default.extend({
    validate: function validate(value, params) {
      if (params.presence) {
        if (!value) {
          return 'TOS have to be accepted.';
        }
        return value;
      }
      return true;
    }
  });

  exports.default = Boolean;
});