define('ember-components/services/constants', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    FACILITY_EDITOR_STEPS: {
      ONE: 1,
      TWO: 2,
      THREE: 3,
      FOUR: 4
    },

    MONTH_DAYS_QUANTITY: 30
  });
});