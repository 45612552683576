define('ember-components/components/pdf-preview/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    willDestroyElement: function willDestroyElement() {
      URL.revokeObjectURL(this.get('objectUrl'));
    },
    didInsertElement: function didInsertElement() {
      var _this = this;

      var url = this.get('url');
      fetch(url).then(function (res) {
        return res.blob();
      }).then(function (blob) {
        _this.set('objectUrl', window.URL.createObjectURL(blob));
      });
    }
  });
});