define('ember-components/components/control/input/invoice/storage-input/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      observer = Ember.observer,
      run = Ember.run;
  exports.default = Component.extend({
    storageChanged: observer('stor.rate', 'stor.quantity', 'stor.prorated_quantity', function () {
      run.debounce(this, 'loadCalcTotals', 500);
    }),

    loadCalcTotals: function loadCalcTotals() {
      var _this = this;

      var rate = this.get('stor.rate');
      var quantity = this.get('stor.quantity');
      var proratedQuantity = this.get('stor.prorated_quantity');
      var isExistingStorage = this.get('stor.id');

      if (rate && quantity && proratedQuantity !== '') {
        var requestParams = {
          rate: Number(rate),
          quantity: Number(quantity),
          prorated_quantity: Number(proratedQuantity),
          is_prorated: true
        };
        var stor = this.get('stor');

        var saveRequest = void 0;
        if (isExistingStorage) {
          saveRequest = stor.getInvoicePrecalculate(requestParams);
        } else {
          saveRequest = stor.getNewInvoicePrecalculate(requestParams);
        }

        saveRequest.then(function (response) {
          if (_this.isDestroyed) {
            return;
          }
          _this.set('stor.sub_total', response.sub_total);
          _this.set('stor.prorated_rate', response.prorated_rate);
          _this.set('stor.prorated_sub_total', response.prorated_sub_total);
        });
      }

      if (proratedQuantity === '') {
        this.set('stor.prorated_sub_total', 0);
      }
    },


    actions: {
      removeStorage: function removeStorage(stor) {
        stor.deleteRecord();
      },
      changeProrated: function changeProrated(stor) {
        var isProrated = stor.get('is_prorated');
        stor.set('is_prorated', !isProrated);
        stor.set('prorated_quantity', 0);
      }
    }
  });
});