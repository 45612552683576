define('ember-components/models/customer', ['exports', 'ember-data', 'ember-cp-validations'], function (exports, _emberData, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _emberCpValidations.buildValidations)({
    first_name: [(0, _emberCpValidations.validator)('presence', true)],
    last_name: [(0, _emberCpValidations.validator)('presence', true)],
    city: [(0, _emberCpValidations.validator)('presence', true)],
    zip: [(0, _emberCpValidations.validator)('presence', true)],
    company: [(0, _emberCpValidations.validator)('presence', true)],
    address_line1: [(0, _emberCpValidations.validator)('presence', true)],
    tos_accepted: [(0, _emberCpValidations.validator)('boolean', { presence: true })]
  });

  exports.default = _emberData.default.Model.extend(Validations, {

    isActive: Ember.computed('city', 'state', 'zip', 'address_line1', 'hasDirtyAttributes', function () {
      return this.get('city') && this.get('state') && this.get('zip') && this.get('address_line1') && !this.get('hasDirtyAttributes');
    }),

    fullAddress: Ember.computed('city', 'state', 'zip', 'address_line1', 'address_line2', function () {
      if (this.get('state') && this.get('city') && this.get('zip')) {
        return this.get('address_line1') + ' ' + this.get('address_line2') + ', ' + this.get('city') + ', ' + this.get('state') + ', ' + this.get('zip');
      }
      return '-';
    }),

    getCompanyName: Ember.computed('isActive', 'company', function () {
      if (this.get('isActive')) {
        return this.get('company');
      }
      return '-';
    }),

    // it indicates that this model was already filled
    // if address_line1 was saved successfully it means other
    // fields exists as well (they are all required in the same time)
    fullDataIsSaved: Ember.computed('address_line1', function () {
      var changedAttrs = this.changedAttributes();
      if (changedAttrs.address_line1) {
        return changedAttrs.address_line1[0];
      }
      return this.get('address_line1');
    }),

    first_name: _emberData.default.attr('string'),
    last_name: _emberData.default.attr('string'),
    city: _emberData.default.attr('string'),
    state: _emberData.default.attr('string'),
    zip: _emberData.default.attr('string'),
    company: _emberData.default.attr('string'),
    address_line1: _emberData.default.attr('string'),
    address_line2: _emberData.default.attr('string', { defaultValue: '' }),
    tos_accepted: _emberData.default.attr('boolean')
  });
});