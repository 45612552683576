define('ember-components/components/interactive/engagement-cancelling/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    actions: {
      submit: function submit(reason) {
        var _this = this;

        this.set('showReasonError', true);
        reason.validate().then(function (_ref) {
          var validations = _ref.validations;

          if (validations.get('isValid')) {
            _this.sendAction('submit', reason.get('reason'));
          }
        });
      },
      close: function close() {
        this.sendAction('close');
      }
    }
  });
});