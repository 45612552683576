define('ember-components/libs/columns-definitions/engagements/invoices', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = [{
    propertyName: 'id',
    title: 'Invoice Number',
    template: 'custom/billing-history/td/id',
    disableFiltering: true
  }, {
    propertyName: 'start_date',
    title: 'Billing Period',
    template: 'custom/billing-history/td/billing-period',
    disableFiltering: true
  }, {
    propertyName: 'formattedDueDate',
    title: 'Due Date',
    disableFiltering: true
  }, {
    propertyName: 'totalFee',
    title: 'Total',
    disableFiltering: true
  }, {
    propertyName: 'status',
    title: 'Status',
    disableFiltering: true
  }];
});