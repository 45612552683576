define('ember-components/helpers/storage-method-as-string', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.storageMethodAsString = storageMethodAsString;


  /**
   * Helper for displaying storage methods variable in human-readable way
   *
   * E.g. 'p' will be display as 'Pallets'
   *
   * @function hasError
   * @param {Array} params
   * @return {String} true if field in error list, false otherwise
   * @public
   */
  function storageMethodAsString(params, hash) {
    var storageMethodCode = params[0];
    var value = '';

    if (storageMethodCode === 'p') {
      value = 'Pallets';
    } else if (storageMethodCode === 'f') {
      value = 'Sq.Ft.';
    }

    return hash.lower ? value.toLowerCase() : value;
  }

  exports.default = Ember.Helper.helper(storageMethodAsString);
});