define('ember-components/components/interactive/modals/send-message-modal/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    actions: {
      submit: function submit() {
        var _this = this;

        this.sendAction('handlerSubmit', function () {
          _this.sendAction('close');
        });
      },
      close: function close() {
        this.set('value', '');
        this.set('error', '');
        this.sendAction('close');
      }
    }
  });
});