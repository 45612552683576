define('ember-components/components/interactive/modals/engagement-extend/component', ['exports', 'moment', 'ember-components/config/environment'], function (exports, _moment, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    isDateValid: Ember.computed('extendDate', function () {
      return _moment.default.utc(this.get('extendDate')) > this.get('minDate');
    }),

    minDate: Ember.computed(function () {
      return _moment.default.utc(this.get('model.end'));
    }),

    maxDate: Ember.computed(function () {
      return (0, _moment.default)().add(10, 'y');
    }),

    actions: {
      submit: function submit(date) {
        this.set('showDateError', true);
        this.sendAction('submitAction', _moment.default.utc(date).format(_environment.default.date.defaultFormat));
      }
    }
  });
});