define('ember-components/facility-listing-details/route', ['exports', 'ember-components/mixins/facility-publish-route', 'ember-components/mixins/storage-hub-route', 'ember-components/libs/base/route', 'ember-components/utils/clearCreateAccountStorage'], function (exports, _facilityPublishRoute, _storageHubRoute, _route, _clearCreateAccountStorage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _route.default.extend(_facilityPublishRoute.default, _storageHubRoute.default, {
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    headData: Ember.inject.service(),
    fastboot: Ember.inject.service(),
    queryParams: {
      volume: { refreshModel: true },
      startDate: { refreshModel: true },
      endDate: { refreshModel: true },
      storageType: { refreshModel: true },
      storageMethod: { refreshModel: true },
      // this parameter show us request were made from
      // this parameter is required for "go back" link generation
      // possible values:
      // - search: user come on this page from search page
      from: { refreshModel: true },
      mode: { refreshModel: true }
    },

    afterModel: function afterModel(model) {
      if (model.facility) {
        this.set('titleToken', model.facility.get('nickname'));
      }
      this.set('headData.title', 'Search Local Warehouses for Rent & Lease - Warehouse Exchange');
      this.set('headData.description', 'Search Local Warehouses for Rent on Warehouse Exchange, the warehouse marketplace where businesses search for warehouses for rent and warehouse owners list their warehouse space.');
      this.set('headData.keywords', 'Rent warehouse space, lease warehouse space, list warehouse list, warehouse marketing, list rental online, warehouse marketplace, find private renters, list my warehouse, flex warehouse space, on demand warehouse, short term warehouse, warehouse for rent near me, Search warehouses, find tenants, flex warehouse, 3pl warehouses, on demand warehouses');
    },
    model: function model(params) {
      var _this = this;

      var facility = this.store.findRecord('facility', params.facilityID);
      if (this.get('fastboot.isFastBoot')) {
        return Ember.RSVP.hash({
          facilityPrerender: facility
        });
      }

      var hash = {
        certifications: this.store.findAll('certification'),
        photos: this.store.query('photo', {
          facility: Number(params.facilityID)
        }),
        facility: facility,
        storageAvailability: {},
        states: this.store.findAll('state'),
        mode: params.mode
      };
      if (this.get('session.isAuthenticated')) {
        (0, _clearCreateAccountStorage.clearCreateAccountStorage)();
        this.store.queryRecord('customer', { me: true }).then(function (customer) {
          hash.customer = customer;
          hash.cards = _this.store.findAll('card').catch(function () {
            return [];
          });
          return _this.store.queryRecord('account', { me: true }).catch(function () {
            return _this.store.createRecord('account');
          });
        }).then(function () {
          hash.supplierCards = _this.store.findAll('supplier-card').catch(function () {
            return [];
          });
        }).catch(function () {
          return [];
        });
      }
      return Ember.RSVP.hash(hash);
    }
  });
});