define('ember-components/components/modal-notification/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      get = Ember.get;
  exports.default = Component.extend({
    /**
     * Show modal
     *
     * @method didInsertElement
     * @return {void}
     * @public
     */
    didInsertElement: function didInsertElement() {
      $('#notificationModal').modal({
        backdrop: 'static',
        keyboard: false
      });
    },


    actions: {
      /**
       * handles click on 'Got It' button on template
       */
      onGotItAction: function onGotItAction() {
        if (get(this, 'redirectTo')) {
          window.location.replace(get(this, 'redirectTo'));
        } else {
          // by default redirect to home page
          window.location.replace('/');
        }
      }
    }
  });
});