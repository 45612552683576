define('ember-components/libs/columns-definitions/invoices/service-fees/outbound-services', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = [{
    propertyName: 'service_carton_pick_volume',
    propertyName1: 'service_carton_pick_rate',
    ckeckProperty: 'service_carton_pick',
    title: 'Carton Pick',
    type: 'carton'
  }, {
    propertyName: 'service_pallet_pick_volume',
    propertyName1: 'service_pallet_pick_rate',
    ckeckProperty: 'service_pallet_pick',
    title: 'Pallet Pick',
    type: 'pallet'
  }, {
    propertyName: 'service_unit_pick_volume',
    propertyName1: 'service_unit_pick_rate',
    ckeckProperty: 'service_unit_pick',
    title: 'Unit Pick',
    type: 'unit'
  }, {
    propertyName: 'service_unit_pack_volume',
    propertyName1: 'service_unit_pack_rate',
    ckeckProperty: 'service_unit_pack',
    title: 'Unit Pack',
    type: 'box'
  }];
});