define('ember-components/adapters/state', ['exports', 'ember-components/adapters/application', 'ember-components/config/environment'], function (exports, _application, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({

    /**
     * Namespace
     *
     * @property namespace
     * @type {string}
     * @default 'api/v1/facilities'
     * @public
     */
    namespace: _environment.default.APP.API_NAMESPACE + '/facilities',

    findRecord: function findRecord(store, type, id, snapshot) {
      var _this = this;

      var baseUrl = this.buildURL();
      var data = this.serialize(snapshot, { includeId: true });
      var url = baseUrl + 'states/';

      if (data.full) {
        return data;
      }

      return new Ember.RSVP.Promise(function (resolve, reject) {
        $.ajax({
          type: 'GET',
          url: url,
          headers: _this.get('headers')
        }).then(function (list) {
          resolve(list.find(function (state) {
            return state.short === data.id || state.short === data.short;
          }));
        }, function (error) {
          reject(error);
        });
      });
    }
  });
});