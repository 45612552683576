define('ember-components/components/interactive/panels/invoice/additional-service-fees/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    additionalServices: computed.reads('model.invoices_additional_services'),
    channelInvoiceWasEdited: computed.reads('channelName'),
    filteredNew: computed.filterBy('additionalServices', 'isNew', true),

    didInsertElement: function didInsertElement() {
      if (!this.get('model.isReleased')) {
        this.createDummyService();
      }
    },
    createDummyService: function createDummyService() {
      var services = this.get('additionalServices');

      if (this.get('filteredNew').length < 1) {
        var newItem = this.get('store').createRecord('invoices-additional-service', {
          invoice: this.get('model'),
          title: '',
          fee: 0
        });
        services.pushObject(newItem);
      }
    },


    actions: {
      createDummyService: function createDummyService() {
        this.createDummyService();
      }
    }

  });
});