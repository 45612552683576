define('ember-components/helpers/hyphenate', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.hyphenate = hyphenate;
  function hyphenate(string) {
    return string[0].replace(/\s+/g, '-');
  }

  exports.default = Ember.Helper.helper(hyphenate);
});