define('ember-components/models/user', ['exports', 'ember-cp-validations', 'ember-api-actions', 'ember-data', 'ember-components/constants'], function (exports, _emberCpValidations, _emberApiActions, _emberData, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _emberCpValidations.buildValidations)({
    email: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: 'Required'
    }), (0, _emberCpValidations.validator)('remoteEmail', {
      message: 'Email already registered'
    }), (0, _emberCpValidations.validator)('email')],
    password: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: 'Required'
    }), (0, _emberCpValidations.validator)('length', {
      min: 8
    }), (0, _emberCpValidations.validator)('format', {
      regex: /^(?=.*\d).*$/,
      message: 'Password must include at least one digit'
    }), (0, _emberCpValidations.validator)('format', {
      regex: /(?=.*[a-z]).*/,
      message: 'Password must include at least one lower-case character'
    }), (0, _emberCpValidations.validator)('format', {
      regex: /(?=.*[!@#$%^&*]).*/,
      message: 'Password must include at least one special character (!, @, #, $, %, ^, &, *)'
    }), (0, _emberCpValidations.validator)('format', {
      regex: /(?=.*[A-Z]).*/,
      message: 'Password must contain at least one upper case character.'
    })],
    password2: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: 'Required'
    }), (0, _emberCpValidations.validator)('length', {
      min: 8
    }), (0, _emberCpValidations.validator)('format', {
      regex: /^(?=.*\d).*$/,
      message: 'Password must include at least one digit'
    }), (0, _emberCpValidations.validator)('format', {
      regex: /(?=.*[a-z]).*/,
      message: 'Password must include at least one lower-case character'
    }), (0, _emberCpValidations.validator)('format', {
      regex: /(?=.*[!@#$%^&*]).*/,
      message: 'Password must include at least one special character (!, @, #, $, %, ^, &, *)'
    }), (0, _emberCpValidations.validator)('format', {
      regex: /(?=.*[A-Z]).*/,
      message: 'Password must contain at least one upper case character.'
    }), (0, _emberCpValidations.validator)('password-comparison', { password: Ember.computed.alias('model.password') })]
  });

  exports.default = _emberData.default.Model.extend(Validations, {
    email: _emberData.default.attr('string'),
    password: _emberData.default.attr('string'),
    latitude: _emberData.default.attr('number'),
    longitude: _emberData.default.attr('number'),
    first_name: _emberData.default.attr('string'),
    last_name: _emberData.default.attr('string'),
    is_confirmed: _emberData.default.attr('boolean'),
    is_company_confirmed: _emberData.default.attr('boolean'),
    buyer_rating: _emberData.default.attr('number', { defaultValue: 0 }),
    buyer_review_count: _emberData.default.attr('number', { defaultValue: 0 }),
    supplier_rating: _emberData.default.attr('number'),
    is_supplier_guide_seen: _emberData.default.attr('boolean'),
    company: _emberData.default.belongsTo('company'),
    customer: _emberData.default.belongsTo('customer'),
    date_joined: _emberData.default.attr('string'),
    usage: _emberData.default.attr('string', { defaultValue: _constants.default.USAGE_TYPE.BUYER }),

    buyer_company: _emberData.default.attr(),

    password1: _emberData.default.attr('string'),
    password2: _emberData.default.attr('string'),

    emailChanged: Ember.computed('email', function () {
      return Ember.isPresent(this.changedAttributes().email);
    }),
    fullName: Ember.computed('first_name', 'last_name', function () {
      var first_name = this.get('first_name');
      var last_name = this.get('last_name');
      var fullName = (first_name + ' ' + last_name).trim();
      return fullName || this.get('email');
    }),

    isValidPassword: Ember.computed('latitude', 'longitude', function () {
      return this.get('password1') === this.get('password2') ? 'valid' : null;
    }),

    hasSupplierCompany: Ember.computed('company', function () {
      return this.get('company.id') !== undefined;
    }),

    hasBuyerCompany: Ember.computed('buyer_company', function () {
      return this.get('buyer_company.id') !== undefined;
    }),

    isBuyer: Ember.computed('usage', function () {
      return this.get('usage') === _constants.default.USAGE_TYPE.BUYER;
    }),

    isSupplier: Ember.computed.not('isBuyer'),

    profileIsFull: Ember.computed('hasBuyerCompany', 'first_name', 'last_name', function () {
      return this.get('hasBuyerCompany') && this.get('first_name') && this.get('last_name');
    }),

    alerts: _emberData.default.hasMany('alert'),

    markGuideSeen: (0, _emberApiActions.memberAction)({ path: 'mark-supplier-guide-seen/', type: 'put' })
  });
});