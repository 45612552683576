define('ember-components/components/interactive/modals/confirm-modal/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = Ember.Component.extend({
    isSubmit: false,
    checked: false,
    showAlert: computed('checked', 'isSubmit', function () {
      var checked = this.get('checked');
      var isSubmit = this.get('isSubmit');
      return !checked && isSubmit;
    }),

    actions: {
      submit: function submit() {
        this.set('isSubmit', true);
        var checked = this.get('checked');
        var termsOfServices = this.get('termsOfServices');

        if (!termsOfServices || termsOfServices && checked) {
          this.handleSubmit();
        }
      },
      close: function close() {
        this.set('open', false);
        this.set('isSubmit', false);
      }
    }
  });
});