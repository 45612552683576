define('ember-components/components/facility-form/facility-rules/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    actions: {
      /** Set 'custom' value for facility rule_type. */
      onSetCustomType: function onSetCustomType() {
        this.set('model.facility.rule_type', 'custom');
      }
    }
  });
});