define('ember-components/serializers/account', ['exports', 'moment', 'ember-data', 'ember-components/serializers/drf', 'ember-components/config/environment'], function (exports, _moment, _emberData, _drf, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _drf.default.extend(_emberData.default.EmbeddedRecordsMixin, {
    attrs: {
      fields_needed: { embedded: 'always' }
    },

    serialize: function serialize() {
      var json = this._super.apply(this, arguments);
      json.day_of_birth = _moment.default.utc(json.day_of_birth).format(_environment.default.date.defaultFormat);
      return json;
    },
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload) {
      /* eslint-disable no-param-reassign */
      payload.business_tax_id_provided = payload.legal_entity.business_tax_id_provided || Boolean(payload.business_tax_id);
      payload.day_of_birth = payload.day_of_birth ? _moment.default.utc(payload.day_of_birth).format(_environment.default.date.slashesFormat) : _moment.default.utc().format(_environment.default.date.slashesFormat);
      return this._super.apply(this, arguments);
    }
  });
});