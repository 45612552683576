define('ember-components/components/facility-form/service-pricing/main-service/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['md-sm-12', 'col-xs-12', 'col-lg-6', 'col-md-6'],

    resetRateValue: Ember.computed('customDisable', function () {
      if (!this.get('customDisable')) {
        this.set('enable', false);
        this.notifyPropertyChange('enable');
        this.set('rate', 0);
      }
    })
  });
});