define('ember-components/components/listing-details/photo-slider/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['page-slick-slider'],

    didInsertElement: function didInsertElement() {
      // initialize slider
      $('.page-slick-slider').slick({
        dots: true,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        arrows: true
      });
    }
  });
});