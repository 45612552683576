define('ember-components/components/control/input/invoice/storage-inputs/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      service = Ember.inject.service;
  exports.default = Ember.Component.extend({
    store: service(),
    classNames: ['row'],

    storages: computed.alias('invoice.storages')
  });
});