define('ember-components/components/listing-details/photo-gallery/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    previewMax: 2,
    allImages: [],
    selectedImage: null,
    currentImageIndex: Ember.computed('selectedImage', function () {
      var selectedImage = this.get('selectedImage');

      return selectedImage.id;
    }),
    mainImage: Ember.computed('allImages.[]', function () {
      return this.get('allImages').get('firstObject');
    }),
    imagePreviews: Ember.computed('allImages.[]', function () {
      var _this = this;

      var images = this.get('allImages');

      return images.filter(function (item, index) {
        return index > 0 && index < _this.previewMax + 1;
      });
    }),
    hiddenPhotosCount: Ember.computed('allImages.[]', function () {
      var count = this.get('allImages').length - this.previewMax - 1;

      return count > 0 ? count : 0;
    }),
    hasPreviews: Ember.computed('imagePreviews.[]', function () {
      return !!this.get('imagePreviews.length');
    }),

    didInsertElement: function didInsertElement() {
      var images = this.get('photos');
      var mapImages = images.map(function (image, index) {
        return {
          file: image.get('file'),
          id: index
        };
      });

      this.set('allImages', mapImages);
    },
    setSelectedImage: function setSelectedImage(index) {
      var image = this.get('allImages.' + index);

      this.set('selectedImage', image);
    },


    actions: {
      openImagePopup: function openImagePopup(index) {
        this.setSelectedImage(index);
      },
      prevImage: function prevImage() {
        this.setSelectedImage(this.get('currentImageIndex') - 1);

        return false;
      },
      nextImage: function nextImage() {
        this.setSelectedImage(this.get('currentImageIndex') + 1);

        return false;
      },
      closeImagePopup: function closeImagePopup() {
        this.set('selectedImage', null);

        return false;
      },
      popupClick: function popupClick() {
        return false;
      }
    }
  });
});