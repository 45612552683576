define('ember-components/engagements/revise-rates/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model(params) {
      var _this = this;

      return this.store.findRecord('engagement', params.engagement_id).then(function (engagement) {
        return Ember.RSVP.hash({
          engagement: engagement,
          revision: _this.store.createRecord('engagement-revision', {
            engagement: engagement,
            new_storage_original_rate: engagement.get('storage_original_rate'),
            new_service_pallet_receiving_rate: engagement.get('service_pallet_receiving_rate'),
            new_service_carton_receiving_rate: engagement.get('service_carton_receiving_rate'),
            new_service_carton_pick_rate: engagement.get('service_carton_pick_rate'),
            new_service_pallet_pick_rate: engagement.get('service_pallet_pick_rate'),
            new_service_unit_pack_rate: engagement.get('service_unit_pack_rate'),
            new_service_unit_pick_rate: engagement.get('service_unit_pick_rate')
          })
        });
      });
    },


    actions: {
      submit: function submit() {
        var _this2 = this;

        this.controller.get('model.revision').validate().then(function (_ref) {
          var validations = _ref.validations;

          if (validations.get('isValid')) {
            _this2.controller.get('model.revision').save().then(function (data) {
              _this2.controller.set('revisedSubmittedModalOpen', true);
              _this2.controller.set('model.engagement.current_revision', data);
              _this2.controller.get('model.invoices').reload();
            }).catch(function (e) {
              Ember.Logger.error(e);
            });
          }
        });
      },
      close: function close() {
        this.transitionTo('engagements.supplier', this.controller.get('model.engagement.id'));
      }
    }
  });
});