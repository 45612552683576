define('ember-components/models/engagement', ['exports', 'ember-data', 'moment', 'ember-api-actions', 'ember-components/mixins/model-validator', 'ember-components/config/environment', 'ember-components/mixins/validators/engagement-model'], function (exports, _emberData, _moment, _emberApiActions, _modelValidator, _environment, _engagementModel) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var AT_END_ACTION_NUMBER_VALUES = {
    mtm_one_month: 30,
    mtm_two_months: 60
  };

  var NUMBER_OF_DAYS_TO_SUBMIT_END_ENGAGEMENT = 10;

  var attr = _emberData.default.attr,
      hasMany = _emberData.default.hasMany,
      belongsTo = _emberData.default.belongsTo;
  var computed = Ember.computed;
  exports.default = _emberData.default.Model.extend(_modelValidator.default, _engagementModel.default, {

    STORAGE_TYPES: {
      a: 'Ambient',
      c: 'Cold',
      s: 'Special'
    },

    PAYMENT_TERMS: {
      default: 'Due upon Acceptance',
      net_7: 'Net 7',
      net_15: 'Net 15',
      net_30: 'Net 30',
      net_60: 'Net 60'
    },

    STATUSES: {
      buyer_requested: 'Requested',
      buyer_signed: 'Buyer Signed',
      supplier_signed: 'Supplier Signed',
      active: 'Active',
      rejected: 'Rejected',
      canceled: 'Canceled'
    },

    PAYMENT_FREQUENCY: {
      monthly: 'Monthly',
      weekly: 'Weekly',
      bi_weekly: 'Bi-Weekly'
    },

    currentUser: Ember.inject.service(),

    check_in: attr('date'),
    check_out: attr('date'),
    substatus: attr('string'),
    status: attr('string'),
    created: attr('date'),
    payment_terms: attr('string'),
    payment_frequency: attr('string'),

    storage_uom: attr('string'),
    storage_units: attr('number', { defaultValue: 0 }),
    storage_type: attr('string'),
    storage_original_rate: attr('number'),
    storage_original_rate_daily: attr('number', { defaultValue: 0.0 }),

    storage_fee: attr('number'),
    service_inbound_fee: attr('number'),
    service_outbound_fee: attr('number'),
    tax_fee: attr('number'),
    subtotal_fee: attr('number'),
    monthly_fee: attr('number'),

    buyer_app_fee: attr('number'),
    buyer_app_fee_percent: attr('number'),
    buyer_total_fee: attr('number'),
    supplier_app_fee: attr('number'),
    supplier_app_fee_percent: attr('number'),
    supplier_total_fee: attr('number'),

    start: attr('string'),
    end: attr('string'),
    reject_date: attr('date'),

    sku_count: attr('number'),
    carton_cube_min_volume: attr('number'),
    carton_cube_max_volume: attr('number'),
    carton_weight_min_volume: attr('number'),
    carton_weight_max_volume: attr('number'),
    facility_services_enabled: attr('boolean', { defaultValue: false }),
    facility_services_rate: attr('number'),
    usage: attr('string'),

    first_month_invoice: attr('number'),
    buyer_first_month_fee: attr('number'),
    buyer_due_today_total: attr('number'),
    supplier_first_month_fee: attr('number'),
    supplier_due_today_total: attr('number'),

    service_pallet_receiving: attr('boolean', { defaultValue: false }),
    service_pallet_receiving_volume: attr('number', { defaultValue: 0.0 }),
    service_pallet_receiving_rate: attr('number', { defaultValue: 0.0 }),
    service_pallet_receiving_uom: attr('string', { defaultValue: 'p' }),

    service_carton_receiving: attr('boolean', { defaultValue: false }),
    service_carton_receiving_volume: attr('number', { defaultValue: 0.0 }),
    service_carton_receiving_rate: attr('number', { defaultValue: 0 }),
    service_carton_receiving_uom: attr('string', { defaultValue: 'p' }),

    service_carton_pick: attr('boolean', { defaultValue: false }),
    service_carton_pick_volume: attr('number', { defaultValue: 0.0 }),
    service_carton_pick_rate: attr('number', { defaultValue: 0.0 }),
    service_carton_pick_uom: attr('string', { defaultValue: 'p' }),

    service_pallet_pick: attr('boolean', { defaultValue: false }),
    service_pallet_pick_volume: attr('number', { defaultValue: 0.0 }),
    service_pallet_pick_rate: attr('number', { defaultValue: 0.0 }),
    service_pallet_pick_uom: attr('string', { defaultValue: 'p' }),

    service_unit_pack: attr('boolean', { defaultValue: false }),
    service_unit_pack_volume: attr('number', { defaultValue: 0.0 }),
    service_unit_pack_rate: attr('number', { defaultValue: 0.0 }),
    service_unit_pack_uom: attr('string', { defaultValue: 'p' }),

    service_unit_pick: attr('boolean', { defaultValue: false }),
    service_unit_pick_volume: attr('number', { defaultValue: 0.0 }),
    service_unit_pick_rate: attr('number', { defaultValue: 0.0 }),
    service_unit_pick_uom: attr('string', { defaultValue: 'p' }),

    stripe_cc_commission: attr('number', { defaultValue: 0 }),
    stripe_cc_commission_amount: attr('number', { defaultValue: 0 }),

    buyer_sign_date: attr('date'),
    supplier_sign_date: attr('date'),

    signed_document: attr('string'),
    checkout_completed: attr('string'),

    days_count: attr('number'),
    deposit: attr('number'),
    final_invoice_date: attr('date'),

    is_agreed: attr('boolean', { defaultValue: false }),
    is_custom: attr('boolean', { defaultValue: false }),

    buyer_rating: belongsTo('buyer-rating'),
    user: belongsTo('user'),
    facility: belongsTo('facility'),
    buyer: belongsTo('user'),
    engagement: belongsTo('engagement'),
    current_revision: belongsTo('engagement-revision'),
    alerts: hasMany('alert'),
    invoices: hasMany('invoices'),
    getFacilityNickname: computed.alias('facility.nickname'),

    persistent_services: hasMany('persistent_services'),
    persistent_services_fee: attr('number'),
    monthly_persistent_services_fee: attr('number', { defaultValue: 0 }),

    termination_date: attr('string'),
    termination_start_date: attr('string'),
    termination_end_date: attr('string'),
    termination_id: attr('number'),
    at_end_action: attr('string', { defaultValue: 'mtm_two_months' }),

    amendments: hasMany('engagement-amendment'),

    acceptance_set: hasMany('acceptance'),

    rentPerMonth: computed(function () {
      return Number((this.get('storage_original_rate') * this.get('storage_units')).toFixed(2));
    }),

    securityDeposit: computed('deposit', 'rentPerMonth', function () {
      var deposit = this.get('deposit');
      if (deposit !== undefined) {
        return deposit;
      }
      return this.get('rentPerMonth');
    }),

    getIdAsNumber: computed('id', function () {
      return parseInt(this.get('id'), 10);
    }),

    getSupplierAlerts: computed.filterBy('alerts', 'visible_for', 'supplier'),

    getBuyerAlerts: computed.filterBy('alerts', 'visible_for', 'buyer'),

    _formatDate: function _formatDate(field) {
      return _moment.default.utc(this.get(field)).format(_environment.default.date.engagementFormat);
    },
    _formatDateSigned: function _formatDateSigned(field) {
      return _moment.default.utc(this.get(field)).format(_environment.default.date.dateSigned);
    },


    supplierSignDate: computed('supplier_sign_date', function () {
      return this._formatDate('supplier_sign_date');
    }),

    buyerSignDate: computed('buyer_sign_date', function () {
      return this._formatDate('buyer_sign_date');
    }),

    formattedCreated: computed('created', function () {
      return this._formatDate('created');
    }),

    startFormatted: computed('start', function () {
      return this._formatDate('start');
    }),

    endFormatted: computed('end', function () {
      return this._formatDate('end');
    }),

    checkInCheckOutDays: computed('start', 'end', function () {
      return _moment.default.utc(this.get('end')).diff(_moment.default.utc(this.get('start')), 'days') + 1;
    }),

    formattedStorageType: computed('storage_type', function () {
      return this.STORAGE_TYPES[this.get('storage_type')];
    }),

    titledStatus: computed('status', function () {
      return this.STATUSES[this.get('status')];
    }),

    titledSubstatus: computed('substatus', function () {
      return this.get('substatus').capitalize();
    }),

    isOnReview: computed('current_revision', 'current_revision.accepted_date', 'current_revision.declined_date', function () {
      return this.get('current_revision.id') && !this.get('current_revision.accepted_date') && !this.get('current_revision.declined_date');
    }),

    paymentTerms: computed('payment_terms', function () {
      return this.PAYMENT_TERMS[this.get('payment_terms')];
    }),

    paymentFrequency: computed('payment_frequency', function () {
      return this.PAYMENT_FREQUENCY[this.get('payment_frequency')];
    }),

    getFacilityCityShort: computed('facility.city', function () {
      var city = this.get('facility.city');
      if (city) {
        return city.substr(0, 3);
      }
      return city;
    }),

    calculate: (0, _emberApiActions.memberAction)({
      path: 'calculate/?type=buyer',
      type: 'post'
    }),

    changeStatus: function changeStatus(status) {
      var reason = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
      var type = arguments[2];

      var action = (0, _emberApiActions.memberAction)({
        path: 'status/?type=' + type,
        type: 'post'
      }).bind(this);

      return action({ status: status, reason: reason, type: type, is_agreed: this.get('is_agreed') });
    },
    signBySupplier: function signBySupplier() {
      return this.changeStatus('supplier_signed', null, 'supplier');
    },
    signByBuyer: function signByBuyer() {
      if (this.get('is_custom')) {
        return this.changeStatus('buyer_signed', null, 'buyer');
      }
      return this.changeStatus('active', null, 'buyer');
    },
    rejectEngagement: function rejectEngagement(reason) {
      return this.changeStatus('rejected', reason, 'supplier');
    },
    cancelEngagement: function cancelEngagement(reason) {
      return this.changeStatus('canceled', reason, 'buyer');
    },


    extend: (0, _emberApiActions.memberAction)({
      path: 'extend/?type=supplier',
      type: 'put'
    }),

    getTotalData: (0, _emberApiActions.memberAction)({
      path: 'calculate/?type=buyer',
      type: 'POST'
    }),

    completeCheckout: (0, _emberApiActions.memberAction)({
      path: 'check-out/?type=supplier',
      type: 'post'
    }),

    trackerInfo: (0, _emberApiActions.memberAction)({ path: 'tracking-info/', type: 'get' }),

    isNeedToShowSendMessageButton: Ember.computed('substatus', 'invoices.@each.status', function () {
      if (this.get('substatus') === 'past') {
        var invoices = this.get('invoices');
        return !(invoices.get('length') > 0 && invoices.every(function (item) {
          return item.get('status') === 'paid';
        }));
      }

      return true;
    }),

    getLastDayToSubmitEndEngagement: function getLastDayToSubmitEndEngagement() {
      var engagementEndDate = this.get('end');
      var atEndAction = this.get('at_end_action');
      var daysToSubtract = AT_END_ACTION_NUMBER_VALUES[atEndAction];

      return (0, _moment.default)(engagementEndDate).subtract(daysToSubtract, 'days');
    },


    isActiveEngagement: computed('status', function () {
      var status = this.get('status');
      var activeStatuses = ['active', 'month_to_month'];
      return activeStatuses.includes(status);
    }),

    isActiveStatusAbleToTerminate: computed('termination_id', 'substatus', 'status', 'at_end_action', function () {
      var isEndMtm = this.get('at_end_action') === 'mtm_end';
      if (isEndMtm) {
        return false;
      }
      var isCurrentSubStatus = this.get('substatus') === 'current';
      var isCurrentActiveStatus = this.get('status') === 'active' && isCurrentSubStatus;

      var lastDayToSubmitEndEngagement = this.getLastDayToSubmitEndEngagement();
      var firstDayToSubmitEndEngagement = lastDayToSubmitEndEngagement.clone().subtract(NUMBER_OF_DAYS_TO_SUBMIT_END_ENGAGEMENT, 'days');

      // Based on BE validations
      var INCLUDE_FIRST_DAY_AND_EXCLUDE_LAST_DAY = '[)';
      var isWithinEndEngagementDeadline = (0, _moment.default)().isBetween(firstDayToSubmitEndEngagement, lastDayToSubmitEndEngagement, 'days', INCLUDE_FIRST_DAY_AND_EXCLUDE_LAST_DAY);
      var isSubmitted = Boolean(this.get('termination_id'));

      var isActiveStatusAbleToTerminate = isCurrentActiveStatus && isWithinEndEngagementDeadline && isSubmitted === false;

      return isActiveStatusAbleToTerminate;
    }),

    availableDaysToTerminate: computed('termination_id', 'substatus', function () {
      return Math.ceil(this.getLastDayToSubmitEndEngagement().diff((0, _moment.default)(), 'days', true));
    }),

    daysToEnd: computed('end', function () {
      var endDate = this.get('end');
      return (0, _moment.default)(endDate).endOf('day').diff((0, _moment.default)(), 'days');
    })
  });
});