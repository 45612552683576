define('ember-components/components/with-errors/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      get = Ember.get,
      set = Ember.set;
  exports.default = Component.extend({

    /**
     * Seems like there is a bug in Ember.js. Helper doesn't fire when object which is argument of
     * helper changed. So boolean property in context set every update
     *
     * @method didUpdate
     * @return {void}
     * @public
     */
    didUpdate: function didUpdate() {
      var flag = false;
      if (get(this, 'errors')) {
        if (get(this, 'errors.validation_errors')) {
          var _iteratorNormalCompletion = true;
          var _didIteratorError = false;
          var _iteratorError = undefined;

          try {
            for (var _iterator = get(this, 'errors.validation_errors')[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
              var error = _step.value;

              if (error.field === get(this, 'field')) {
                set(this, 'hasError', true);
                flag = !flag;
              }
            }
          } catch (err) {
            _didIteratorError = true;
            _iteratorError = err;
          } finally {
            try {
              if (!_iteratorNormalCompletion && _iterator.return) {
                _iterator.return();
              }
            } finally {
              if (_didIteratorError) {
                throw _iteratorError;
              }
            }
          }
        }
      }
      if (!flag) {
        set(this, 'hasError', false);
      }
    }
  });
});