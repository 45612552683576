define('ember-components/home/email-confirm/route', ['exports', 'fetch', 'ember-components/config/environment'], function (exports, _fetch, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  exports.default = Route.extend({
    session: Ember.inject.service(),

    activate: function activate() {
      this.get('session').invalidate();

      return true;
    },
    model: function model(params) {
      return (0, _fetch.default)('' + _environment.default.APP.API_HOST + _environment.default.APP.BASE_PATH + '/auth/email-confirm/' + params.key + '/', { method: 'post' }).then(function (data) {
        if (data.status === 404) {
          return 'error';
        } else if (data.status === 204) {
          return 'already_verified';
        }
        return 'ok';
      });
    },


    actions: {
      redirectToHome: function redirectToHome() {
        this.transitionTo('application');
      }
    }
  });
});