define('ember-components/models/account', ['exports', 'ember-data', 'ember-cp-validations'], function (exports, _emberData, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr;


  var Validations = (0, _emberCpValidations.buildValidations)({
    tos_accepted: [(0, _emberCpValidations.validator)('boolean', { presence: true })],
    first_name: [(0, _emberCpValidations.validator)('presence', {
      presence: Ember.computed('model.first_name', 'model.fields_needed.first_name', function () {
        if (this.get('model.fields_needed.first_name') || !this.get('model.first_name').toString().isEmpty()) {
          return true;
        }
      })
    })],
    last_name: [(0, _emberCpValidations.validator)('presence', {
      presence: Ember.computed('model.last_name', 'model.fields_needed.last_name', function () {
        if (this.get('model.fields_needed.last_name') || !this.get('model.last_name').toString().isEmpty()) {
          return true;
        }
      })
    })],
    ssn_last_4: [(0, _emberCpValidations.validator)('presence', {
      presence: Ember.computed('model.ssn_last_4', 'model.fields_needed.ssn_last_4', function () {
        if (this.get('model.fields_needed.ssn_last_4' || !this.get('model.ssn_last_4').toString().isEmpty())) {
          return true;
        }
      })
    }), (0, _emberCpValidations.validator)('length', {
      min: 4,
      max: 4
    })],
    business_tax_id: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: 'Required'
    }), (0, _emberCpValidations.validator)('length', {
      min: 9,
      max: 9
    })],
    city: [(0, _emberCpValidations.validator)('presence', {
      presence: Ember.computed('model.city', 'model.fields_needed.city', function () {
        if (this.get('model.fields_needed.city') || !this.get('model.city').toString().isEmpty()) {
          return true;
        }
      })
    })],
    zip: [(0, _emberCpValidations.validator)('presence', {
      presence: Ember.computed('model.zip', 'model.fields_needed.zip', function () {
        if (this.get('model.fields_needed.zip') || !this.get('model.zip').toString().isEmpty()) {
          return true;
        }
      })
    }), (0, _emberCpValidations.validator)('zip')],
    address_line1: [(0, _emberCpValidations.validator)('presence', {
      presence: Ember.computed('model.address_line1', 'model.fields_needed.address_line1', function () {
        if (this.get('model.fields_needed.address_line1') || !this.get('model.address_line1').toString().isEmpty()) {
          return true;
        }
      })
    })],
    day_of_birth: [(0, _emberCpValidations.validator)('presence', {
      presence: Ember.computed('model.day_of_birth', 'model.fields_needed.day_of_birth', function () {
        if (this.get('model.fields_needed.day_of_birth') || !this.get('model.day_of_birth').toString().isEmpty()) {
          return true;
        }
      })
    }), (0, _emberCpValidations.validator)('date', true)]
  });

  exports.default = Model.extend(Validations, {
    payment_frequency: attr('string'),
    payment_terms: attr('string'),
    tos_accepted: attr('boolean'),
    verification_status: attr('string'),
    first_name: attr('string'),
    last_name: attr('string'),
    ssn_last_4: attr('string'),
    business_tax_id: attr('string'),
    city: attr('string'),
    state: attr('string'),
    zip: attr('string'),
    address_line1: attr('string'),
    address_line2: attr('string', { defaultValue: '' }),
    account_type: attr('string'),
    day_of_birth: attr('string'),
    business_tax_id_provided: attr('boolean'),

    fields_needed: _emberData.default.belongsTo('fields-needed'),

    fullAddress: Ember.computed('city', 'state', 'zip', 'address_line1', 'address_line2', 'verification_status', function () {
      if (this.get('verification_status') !== 'unverified') {
        return this.get('address_line1') + ' ' + this.get('address_line2') + ' ' + this.get('city') + ' ' + this.get('state') + ' ' + this.get('zip');
      }
      return '-';
    }),

    getFullAddress: Ember.computed('city', 'state', 'zip', 'address_line1', 'address_line2', 'verification_status', function () {
      if (this.get('address_line1') && this.get('city') && this.get('zip')) {
        return this.get('address_line1') + ' ' + this.get('address_line2') + ', ' + this.get('city') + ', ' + this.get('state') + ', ' + this.get('zip');
      } else if (this.get('address_line1') && this.get('zip') && this.get('state')) {
        return this.get('address_line1') + ', ' + this.get('state') + ', ' + this.get('zip');
      }
      return '-';
    }),

    getCompanyName: Ember.computed('isActive', 'company', function () {
      if (this.get('isActive')) {
        return this.get('company');
      }
      return '-';
    })
  });


  // eslint-disable-next-line no-extend-native
  String.prototype.isEmpty = function () {
    return this.length === 0 || !this.trim();
  };
});