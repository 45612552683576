define('ember-components/components/interactive/modals/end-engagement-modal/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    errorService: Ember.inject.service(),

    isLoading: false,
    step: 1,
    error: null,
    model: null,
    reasons: [],

    isStepOne: Ember.computed('step', function () {
      return this.step === 1;
    }),

    isStepTwo: Ember.computed('step', function () {
      return this.step === 2;
    }),

    isStepThree: Ember.computed('step', function () {
      return this.step === 3;
    }),

    isNextButtonShown: Ember.computed('step', function () {
      return this.get('isStepOne') || this.get('isStepTwo');
    }),

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.model = this.get('store').createRecord('end-engagement', {
        engagement: this.get('engagement').id
      });

      this.get('store').query('end-engagement-reason', {
        // Type values are defined at engagement-dashboard
        type: this.type
      }).then(function (reasons) {
        _this.set('reasons', reasons);
      });
    },


    actions: {
      onNext: function onNext() {
        if (this.get('model.validations.isValid')) {
          this.set('step', 2);
        } else {
          this.set('error', {
            endReasonQuestion: this.get('model.validations.attrs.endReasonQuestion.isInvalid'),
            terminationDate: this.get('model.validations.attrs.terminationDate.isInvalid'),
            endReasonText: this.get('model.validations.attrs.endReasonText.isInvalid')
          });
        }
      },
      onConfirm: function onConfirm() {
        var _this2 = this;

        this.set('isLoading', true);
        this.get('model').save().then(function () {
          _this2.sendAction('onComplete');
          _this2.set('step', 3);
        }).catch(function (error) {
          if (error && error.responseJSON != null) {
            _this2.get('errorService').showBackendValidationErrors(error.responseJSON);
          }
        }).finally(function () {
          _this2.set('isLoading', false);
        });
      },
      onCloseDialog: function onCloseDialog() {
        this.sendAction('close');
        this.set('error', null);
        this.set('model', {
          terminationDate: null,
          endReasonText: null,
          endReasonQuestion: null
        });
        this.set('step', 1);
      }
    }
  });
});