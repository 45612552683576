define('ember-components/models/conversation', ['exports', 'ember-data', 'ember-api-actions', 'ember-components/libs/computed/format-date', 'ember-components/libs/types-shims', 'ember-components/mixins/model-validator'], function (exports, _emberData, _emberApiActions, _formatDate, _typesShims, _modelValidator) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend(_modelValidator.default, {
    subject: _typesShims.string,
    companion: _typesShims.string,
    new_messages_count: _typesShims.number,
    messaging_enabled: _typesShims.boolean,
    last_message_date: _typesShims.string,
    normolizedLastMsgDate: (0, _formatDate.default)('last_message_date', 'MMM DD'),
    read: (0, _emberApiActions.memberAction)({ path: 'read/', type: 'put' })
  });
});