define('ember-components/adapters/facility', ['exports', 'ember-components/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    pathForType: function pathForType() {
      return 'facilities';
    }
  });
});