define('ember-components/components/interactive/modals/end-engagement-modal/second-step/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    model: null,
    isLoading: false,

    actions: {
      onConfirm: function onConfirm() {
        this.sendAction('onConfirm');
      }
    }
  });
});