define('ember-components/models/attachment', ['exports', 'ember-data', 'ember-components/libs/computed/format-date'], function (exports, _emberData, _formatDate) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;
  var computed = Ember.computed;
  exports.default = Model.extend({
    content_type: attr('string'),
    file: attr('file'),
    created_date: attr('date'),

    invoice: belongsTo('invoice'),
    created_by_user: belongsTo('user'),

    getName: computed('file', function () {
      var file = this.get('file');
      if (typeof file === 'string') {
        return file.split('/').slice(-1);
      } else if (file) {
        return file.name;
      }
    }),

    createdDate: (0, _formatDate.default)('created_date')
  });
});