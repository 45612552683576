define('ember-components/serializers/invoice-storage', ['exports', 'ember-components/serializers/drf'], function (exports, _drf) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _drf.default.extend({
    configProvider: Ember.inject.service(),

    serialize: function serialize() {
      var json = this._super.apply(this, arguments);
      json.uom = 'Non provide';

      return json;
    }
  });
});