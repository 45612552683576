define('ember-components/helpers/defined', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.defined = defined;
  function defined(params) {
    return params[0] !== undefined;
  }

  exports.default = Ember.Helper.helper(defined);
});