define('ember-components/app', ['exports', 'ember-load-initializers', 'ember-components/resolver', 'ember-components/config/environment'], function (exports, _emberLoadInitializers, _resolver, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  Ember.MODEL_FACTORY_INJECTIONS = true;

  Ember.TextField.reopen({
    attributeBindings: ['data-role'],
    classNameBindings: ['isInvalid:invalid']
  });

  Ember.TextArea.reopen({
    attributeBindings: ['data-role'],
    classNameBindings: ['isInvalid:invalid']
  });

  var App = Ember.Application.extend({
    customEvents: {
      touchStart: 'click'
    },
    modulePrefix: _environment.default.modulePrefix,
    podModulePrefix: _environment.default.podModulePrefix,
    Resolver: _resolver.default,

    intl: Ember.inject.service(),
    beforeModel: function beforeModel() {
      return this.get('intl').setLocale('en-us');
    }
  });

  (0, _emberLoadInitializers.default)(App, _environment.default.modulePrefix);

  exports.default = App;


  // eslint-disable-next-line no-console
  console.warn('Ember Deprecation warnings are silenced. Change config in app.js to enable it.');
  // Comment line below to enable Ember Deprecation warnings.
  Ember.deprecate = function () {};
});