define('ember-components/engagements-table/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller,
      computed = Ember.computed,
      _get = Ember.get,
      _set = Ember.set;
  exports.default = Controller.extend({
    queryParams: ['tab'],
    tab: null,

    openedTab: computed('tab', {
      get: function get() {
        return _get(this, 'tab');
      },
      set: function set(key, value) {
        _set(this, 'tab', value);
        return value;
      }
    }),
    getUserType: computed('userType', function () {
      return _get(this, 'userType');
    })
  });
});