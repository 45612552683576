define('ember-components/components/presenter/tables/rows/engagement-arrow/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'tr',
    arrowVector: 'right'

  });
});