define('ember-components/preferences-supplier/route', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'ember-components/constants', 'ember-components/libs/base/route'], function (exports, _authenticatedRouteMixin, _constants, _route) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var hash = Ember.RSVP.hash;
  exports.default = _route.default.extend(_authenticatedRouteMixin.default, {
    titleToken: 'Supplier Billing Preferences',

    queryParams: {
      from: { refreshModel: true }
    },
    currentUser: Ember.inject.service(),

    model: function model() {
      var _this = this;

      var companyId = this.get('currentUser.user.company.id');

      return hash({
        account: this.store.queryRecord('account', { me: true }).catch(function () {
          return _this.store.createRecord('account');
        }),
        cards: this.store.findAll('supplier-card').catch(function () {
          return [];
        }),
        bankAccounts: this.store.findAll('supplier-bank-account').catch(function () {
          return [];
        }),
        card: this.store.createRecord('supplier-card'),
        bankAccount: this.store.createRecord('supplier-bank-account', {
          default: false
        }),
        accountFile: this.store.createRecord('account-file'),
        supplierCompanyModel: companyId ? this.store.findRecord('company', companyId) : this.store.createRecord('company', { type: _constants.default.COMPANY_TYPE.SUPPLIER }),
        states: this.store.findAll('state')
      });
    },
    afterModel: function afterModel(model) {
      model.supplierCompanyModel.set('validate_payment', true);

      if (!model.supplierCompanyModel.get('business_tax_id_provided') && model.account.get('business_tax_id_provided')) {
        model.supplierCompanyModel.set('business_tax_id_provided', true);
      }
    }
  });
});