define('ember-components/adapters/user', ['exports', 'ember-components/adapters/application', 'ember-components/config/environment'], function (exports, _application, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    namespace: _environment.default.APP.API_NAMESPACE + '/auth',

    pathForType: function pathForType() {
      return 'user';
    },
    urlForCreateRecord: function urlForCreateRecord() {
      return _environment.default.APP.API_HOST + '/' + this.namespace + '/register/';
    },
    urlForUpdateRecord: function urlForUpdateRecord() {
      return _environment.default.APP.API_HOST + '/' + this.namespace + '/user/';
    },
    buildURL: function buildURL(modelName, instance, snapshot, urlType) {
      var url = this._super(modelName, instance, snapshot, urlType);
      return url.replace(/\/\d+\//, '');
    }
  });
});