define('ember-components/engagements/invoice-details/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller,
      computed = Ember.computed,
      service = Ember.inject.service;
  exports.default = Controller.extend({
    notify: service(),
    totalFee: computed('model.invoice.totalSum', 'model.invoice.commission', 'model.invoice.stripe_cc_commission_amount', function () {
      return this.get('model.invoice.totalSum') + this.get('model.invoice.commission') + this.get('model.invoice.stripe_cc_commission_amount');
    })
  });
});