define('ember-components/engagements/route', ['exports', 'ember-components/libs/base/route'], function (exports, _route) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _route.default.extend({
    titleToken: 'Engagement Details',

    model: function model() {
      return Ember.RSVP.hash({
        reason: this.store.createRecord('reason'),
        states: this.store.findAll('state')
      });
    }
  });
});