define('ember-components/facility-create/step2/route', ['exports', 'ember-components/mixins/step'], function (exports, _step) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route,
      service = Ember.inject.service,
      computed = Ember.computed;
  exports.default = Route.extend(_step.default, {
    constants: service(),

    /**
     * Step number
     *
     * @property step
     * @default 2
     * @type number
     * @public
     */
    step: computed.readOnly('constants.FACILITY_EDITOR_STEPS.TWO'),

    /**
     * Notification service
     *
     * @property notify
     * @type {Object}
     * @public
     */
    notify: service()
  });
});