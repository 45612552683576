define('ember-components/engagements/invoice-edit/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      get = Ember.get;


  function computedPanelViewModel(path, title) {
    return computed('invoice', function () {
      return {
        type: 'interactive',
        name: 'sa-panel',
        params: {
          customClass: 'panel panel-dark-blue panel-invoice mb30',
          header: { title: title }
        }
      };
    });
  }

  exports.default = Ember.Controller.extend({
    notify: Ember.inject.service(),
    invoice: computed.alias('model.invoice'),
    currentUser: Ember.inject.service(),
    channelInvoiceWasEdited: 'incoice-was-edited',
    additionalServicesLength: computed.reads('model.invoice.invoices_additional_services.length'),
    sortedAttachmentsLength: computed.reads('model.invoice.attachments.length'),

    additionalServiceFeesViewModel: computedPanelViewModel('invoice', 'Additional Service Fees'),
    storageUtilizeViewModel: computedPanelViewModel('invoice', 'Fees'),
    serviceViewModel: computedPanelViewModel('invoice', 'Services'),
    serviceFeesViewModel: computedPanelViewModel('invoice', 'Storage'),
    invoiceSummaryViewModel: computedPanelViewModel('invoice', 'Invoice Summary'),

    hasErrors: computed.or('invoice.hasErrors'),

    _exit: function _exit() {
      var engagement = this.get('model.engagement');
      this.transitionToRoute('engagements.supplier', engagement.get('id'));
    },


    /**
     * Save invoice storages.
     * @param {number} invoiceId
     * @return {Promise<any>}
     */
    saveStorages: function saveStorages(invoiceId) {
      var _this = this;

      var storages = this.get('invoice.storages');
      var promises = [];

      storages.forEach(function (record) {
        record.set('invoice', invoiceId);
        promises.push(record.save());
      });

      return Ember.RSVP.all(promises).catch(function (error) {
        get(_this, 'notify').error('An error occurred while saving the storages. Please try again later.');
        console.error(error);

        return error;
      });
    },


    /**
     * Save invoice services.
     * @param {number} invoiceId
     * @return {Promise<any>}
     */
    saveServices: function saveServices(invoiceId) {
      var _this2 = this;

      var services = this.get('invoice.services');
      var promises = [];

      services.forEach(function (record) {
        record.set('invoice', invoiceId);
        promises.push(record.save());
      });

      return Ember.RSVP.all(promises).catch(function (error) {
        get(_this2, 'notify').error('An error occurred while saving the services. Please try again later.');
        console.error(error);

        return error;
      });
    },


    /**
     * Save invoice attachments.
     * @return {Promise<any>}
     */
    saveAttachments: function saveAttachments() {
      var _this3 = this;

      var attachments = this.get('invoice.attachments');
      var promises = [];

      attachments.forEach(function (record) {
        promises.push(record.save());
      });

      return Ember.RSVP.all(promises).catch(function (error) {
        get(_this3, 'notify').error('An error occurred while saving the attachments. Please try again later.');
        console.error(error);

        return error;
      });
    },
    sendData: function sendData() {
      var _this4 = this;

      var invoice = this.get('invoice');

      invoice.save().then(function (data) {
        var invoiceId = Number(data.get('id'));

        Ember.RSVP.all([_this4.saveServices(invoiceId), _this4.saveStorages(invoiceId), _this4.saveAttachments()]).then(function () {
          _this4._exit();
        });
      }).catch(function (e) {
        get(_this4, 'notify').error('An error occurred while saving the invoice. Please try again later.');
        console.error(e);
      });
    },


    actions: {
      saveAndExit: function saveAndExit() {
        var _this5 = this;

        var model = this.get('invoice');

        model.validate().then(function (_ref) {
          var validations = _ref.validations;

          var isValid = validations.get('isValid');

          if (!isValid) {
            return;
          }

          _this5.sendData();
        });
      },
      exit: function exit() {
        this._exit();
      },
      markSeen: function markSeen() {
        var _this6 = this;

        this.get('currentUser.user').content.markGuideSeen().then(function () {
          _this6.set('currentUser.user.is_supplier_guide_seen', true);
        });
      }
    }
  });
});