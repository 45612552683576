define('ember-components/mixins/error-pusher', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Mixin = Ember.Mixin,
      get = Ember.get,
      set = Ember.set;
  exports.default = Mixin.create({

    /**
     * Push error to ``errors`` context property
     *
     * @method pushError
     * @param {string} field
     * @param {Array<string>} errorList
     * @return {void}
     * @public
     */
    pushError: function pushError(field, errorList) {
      var _this = this;

      var setFlag = false;
      if (!get(this, 'errors')) {
        if (errorList.length) {
          set(this, 'errors', {
            code: 'client_validation_error',
            detail: 'Unfortunately, there are some problems with the data you committed',
            validation_errors: [{ field: field, errors: errorList }]
          });
        }
      } else {
        // eslint-disable-next-line array-callback-return
        get(this, 'errors.validation_errors').map(function (element, index) {
          if (element.field === field) {
            if (errorList.length) {
              set(_this, 'errors.validation_errors.' + index + '.errors', errorList);
            } else {
              get(_this, 'errors.validation_errors').removeAt(index);
            }
            setFlag = true;
          }
        });
        if (!setFlag) {
          if (errorList.length) {
            get(this, 'errors.validation_errors').pushObject({ field: field, errors: errorList });
          }
        }

        if (!get(this, 'errors.validation_errors').length) {
          set(this, 'errors', null);
        }
      }
    }
  });
});