define('ember-components/home/password-reset-confirm/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  exports.default = Route.extend({
    model: function model(params) {
      return this.store.createRecord('password-reset-confirm', { uid: params.uid, key: params.key });
    }
  });
});