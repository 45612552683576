define('ember-components/components/control/input/invoice/storage-input-wrap/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      service = Ember.inject.service;
  exports.default = Ember.Component.extend({
    store: service(),
    classNames: ['row'],
    storageList: computed.alias('invoice.storages'),
    hasErrors: computed.alias('invoice.storagesHasErrors'),

    actions: {
      addStorage: function addStorage() {
        var storageList = this.get('storageList');
        var newStorage = this.get('store').createRecord('invoice-storage', {
          invoice: this.get('invoice.id'),
          is_prorated: false,
          prorated_quantity: 0
        });

        storageList.pushObject(newStorage);
      }
    }
  });
});