define('ember-components/facility-create/step4/route', ['exports', 'ember-components/mixins/step'], function (exports, _step) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      service = Ember.inject.service;
  exports.default = Ember.Route.extend(_step.default, {
    constants: service(),

    step: computed.readOnly('constants.FACILITY_EDITOR_STEPS.FOUR'),
    userIsAdmin: false,

    model: function model() {
      var model = this._super.apply(this, arguments);
      // We only need request if we do not have facility_listings yet.
      if (!model.facility_listings) {
        model.facility_listings = this.store.query('facility-listing', {
          facility: String(model.facility.id)
        });
      }

      return Ember.RSVP.hash(model);
    }
  });
});