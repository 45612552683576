define('ember-components/components/interactive/modals/rates-review/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    actions: {
      close: function close() {
        this.sendAction('closeAction');
      },
      submit: function submit() {
        this.sendAction('submitAction');
      }
    }
  });
});