define('ember-components/components/password-reset-form/component', ['exports', 'fetch', 'ember-components/config/environment'], function (exports, _fetch, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      service = Ember.inject.service,
      get = Ember.get,
      set = Ember.set;
  exports.default = Component.extend({

    store: service(),
    flashMessages: service(),

    didInsertElement: function didInsertElement() {
      var _this = this;

      $('#passwordResetModal').on('hide.bs.modal', function () {
        set(_this, 'model', get(_this, 'store').createRecord('email-request'));
        set(_this, 'showEmailError', false);
      });
    },


    actions: {
      resetPassword: function resetPassword(model) {
        var _this2 = this;

        model.validate().then(function (_ref) {
          var validations = _ref.validations;

          if (validations.get('isValid')) {
            var emailData = new FormData();
            emailData.append('email', model.get('email'));
            (0, _fetch.default)('' + _environment.default.APP.API_HOST + _environment.default.APP.BASE_PATH + '/auth/password/reset/', {
              method: 'post',
              body: emailData
            }).then(function (data) {
              if (data.status === 200) {
                get(_this2, 'flashMessages').success('Password reset e-mail has been sent');
              } else {
                get(_this2, 'flashMessages').danger('The e-mail address is not assigned to any user account');
              }
            });
          }
        }).catch(function (e) {
          get(_this2, 'flashMessages').danger(e.message);
        });
      }
    }
  });
});