define('ember-components/components/new-listing-details/facility-info-tabs/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i];

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var computed = Ember.computed;


  var FLAG_LABEL_MAP = {
    days: {
      available_sunday: 'Sunday',
      available_monday: 'Monday',
      available_tuesday: 'Tuesday',
      available_wednesday: 'Wednesday',
      available_thursday: 'Thursday',
      available_friday: 'Friday',
      available_saturday: 'Saturday'
    },

    product_types: {
      product_type_agriculture: 'Agriculture',
      product_type_apparel: 'Apparel',
      product_type_automotive: 'Automotive',
      product_type_base_metals: 'Base Metals',
      product_type_consumer_product: 'Consumer Products',
      product_type_electronics: 'Electronics',
      product_type_industrial_materials: 'Industrial Materials',
      product_type_machinery: 'Machinery',
      product_type_petroleum: 'Petroleum',
      product_type_pharmaceuticals: 'Pharmaceuticals'
    },

    storage_layout: {
      storage_layout_floor_space: 'Floor',
      storage_layout_racking: 'Racking',
      storage_layout_security_rooms: 'Security Room(s)',
      storage_layout_pick_modules: 'Pick Module(s)',
      storage_layout_dedicated_rooms: 'Dedicated Room(s)'
    },

    security: {
      security_gated_access: 'Gated Access',
      security_onsite_guards: 'Onsite Guards',
      security_security_system: 'Security System',
      security_video_surveillance: 'Video Surveillance'
    },

    special: {
      // special_storage_hazmat: 'HazMat',
      // special_storage_aerosol: 'Aerosol',
      // special_storage_cold: 'Cold',
      // special_storage_frozen: 'Frozen',
      // special_storage_ormd: 'ORMD',
      // special_storage_flammable: 'Flammable',

      special_storage_dry_ambient: 'Dry - Ambient',
      special_storage_temperature_controlled: 'Temperature Controlled',
      special_storage_frozen_refrigerated: 'Frozen - Refrigerated',
      special_storage_fda_registered: 'FDA Registered',
      special_storage_hazmat_certified: 'HazMat Certified',
      special_storage_aib_certified: 'AIB Certified',
      special_storage_cbp_bonded: 'CBP Bonded',
      special_storage_foreign_trade_zone: 'Foreign Trade Zone (FTZ)',
      special_storage_food_grade: 'Food Grade',
      special_storage_iso: 'ISO 9001',
      special_storage_c_tpat: 'C-TPAT',
      special_storage_tapa: 'TAPA'

    },

    inbound_services: {
      service_pallet_receiving: 'Pallet Receiving',
      service_carton_receiving: 'Carton Receiving'
    },

    outbound_services: {
      service_unit_pick: 'Unit Pick and Pack',
      service_unit_pack: 'eCommerce Fulfillment',
      service_carton_pick: 'Carton Pick',
      service_pallet_pick: 'Pallet Pick'
    },

    value_add_services: {
      additional_price_labeling: 'Price Labeling',
      additional_carton_kitting: 'Kitting',
      additional_repacking: 'Repacking',
      additional_carton_labeling: 'Master Carton Labeling',
      additional_cross_dock: 'Cross Dock',
      additional_ship_labeling: 'Ship Labeling',
      additional_price_ticketing: 'Ticketing'
    }
  };

  function getMethodParams(flag) {
    var group = FLAG_LABEL_MAP[flag];
    return Object.keys(group).map(function (item) {
      return 'facility.' + item;
    });
  }

  function flagsToString(facility, flag_group) {
    var group = FLAG_LABEL_MAP[flag_group];
    return Object.keys(group).filter(function (item) {
      return facility.get(item);
    }).map(function (item) {
      return group[item];
    }).join(', ');
  }

  function computedFlag(flagName) {
    var obj = getMethodParams(flagName);
    return computed.apply(undefined, _toConsumableArray(obj).concat([function () {
      return flagsToString(this.get('facility'), flagName);
    }]));
  }

  function computedParam(paramPath, metric) {
    return computed(paramPath, function () {
      var param = this.get(paramPath);
      if (param) {
        return param + ' ' + metric;
      }
    });
  }

  exports.default = Ember.Component.extend({
    availableDays: computedFlag('days'),
    inboundServices: computedFlag('inbound_services'),
    availableSpecial: computedFlag('special'),
    outboundServices: computedFlag('outbound_services'),
    valueAddServices: computedFlag('value_add_services'),
    availableSecurity: computedFlag('security'),
    availableProductTypes: computedFlag('product_types'),
    availableStorageLayout: computedFlag('storage_layout'),

    facilityMaxStorageHeight: computedParam('facility.max_storage_height', '′'),
    facilityMaxStorageWeight: computedParam('facility.max_storage_weight', 'lbs'),

    facilitySize: computed('facility.size', function () {
      var size = this.get('facility.size');
      return numeral(size).format() + ' sq. feet';
    }),

    facilityTime: computed('facility.available_24h', 'facility.available_time_from', 'facility.available_time_to', function () {
      var available_24h = this.get('facility.available_24h');
      if (available_24h) {
        return '24 hours';
      }
      var from = this.get('facility.available_time_from');
      var to = this.get('facility.available_time_to');
      return from + ' - ' + to;
    })
  });
});