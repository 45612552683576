define("ember-components/services/stripe-mock", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var lambda = function lambda() {};
  var promiseTokenLambda = function promiseTokenLambda() {
    return Ember.RSVP.Promise.resolve({ id: true });
  };

  exports.default = {
    setPublishableKey: lambda,
    card: {
      cardType: lambda,
      validateCardNumber: lambda,
      validateCVC: lambda,
      validateExpiry: lambda,
      createToken: promiseTokenLambda
    },
    bankAccount: {
      createToken: promiseTokenLambda
    },
    piiData: {
      createToken: promiseTokenLambda
    }
  };
});