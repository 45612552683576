define('ember-components/components/new-listing-details/component', ['exports', 'ember-components/config/environment', 'ember-components/constants'], function (exports, _environment, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      service = Ember.inject.service;


  var API_PATH = '' + _environment.default.APP.API_HOST + _environment.default.APP.BASE_PATH;

  function computedMode(mode) {
    return computed('mode', function () {
      return this.get('mode') === mode;
    });
  }

  exports.default = Ember.Component.extend({
    session: service(),
    store: service(),
    notify: service(),
    currentUser: service(),
    api: service(),
    router: service(),
    googleTagManager: service(),

    classNames: ['wrap-container'],

    isPreview: computedMode('preview'),
    isProfile: computedMode('profile'),

    embedVideos: Ember.computed.filter('facility.videos.@each.url', function (video) {
      var url = video.get('url');

      if (!url) {
        return false;
      }

      return ['matterport.com/show/', 'vimeo.com/video/', 'youtube.com/embed/'].some(function (p) {
        return url.indexOf(p) !== -1;
      });
    }),
    embedVideo: Ember.computed('embedVideos', function () {
      return this.get('embedVideos.firstObject');
    }),

    isShowButtonPublish: computed('mode', 'from', function () {
      return this.get('session').get('isAuthenticated') && this.get('isPreview') === true;
    }),

    isEmptyFacilityListings: Ember.computed('facilityListings.length', function () {
      var listingsLength = this.get('facilityListings.length');
      return listingsLength === 0;
    }),

    startAvailableDate: Ember.computed('availableDates', function () {
      var dates = this.get('availableDates');
      if (dates) {
        var startDate = dates[0];
        return startDate;
      }
      return null;
    }),

    endAvailableDate: Ember.computed('availableDates', function () {
      var dates = this.get('availableDates');
      if (dates) {
        var endDate = dates[dates.length - 1];
        return endDate;
      }
      return null;
    }),

    storageSpace: computed('store.storageAvailability', {
      get: function get() {
        return this.get('store.storageAvailability.ambient_feet_amount') || null;
      }
    }),

    isAvailable: computed('startAvailableDate', 'endAvailableDate', function () {
      return this.get('startAvailableDate') && this.get('endAvailableDate');
    }),

    dateChanged: Ember.observer('startDate', 'endDate', function () {
      Ember.run.once(this, 'processDateChange');
    }),

    volumeChanged: Ember.observer('volume', function () {
      Ember.run.debounce(this, 'processVolumeChange', 1000);
    }),

    didInsertElement: function didInsertElement() {
      window.localStorage.removeItem(_constants.default.IS_CREATE_ACCOUNT);
      Ember.$.ajax({
        url: API_PATH + '/auth/users/update-search/',
        type: 'POST',
        data: {
          sessionid: localStorage.getItem('session_id'),
          facility: this.get('facility').id
        }
      });
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
    },
    init: function init() {
      this._super.apply(this, arguments);
      this.addObserver('startDate', this, 'reloadStorageAvailability');
      this.addObserver('endDate', this, 'reloadStorageAvailability');
      this.addObserver('storageMethod', this, 'reloadStorageAvailability');
      this.addObserver('facility', this, 'reloadStorageAvailability');
      this.loadStorageAvailability(this);

      this.processDateChange();
      this.processVolumeChange();
    },
    loadStorageAvailability: function loadStorageAvailability(_this) {
      var _this2 = this;

      var facility = _this.get('facility');

      if (!_this.get('volume')) {
        return;
      }

      var requestParams = {
        storage_method: 'f',
        storage_type: _this.get('storageType'),
        volume: _this.get('facility.min_order_quantity')
      };

      if (_this.get('startDate') && _this.get('endDate')) {
        requestParams.date_from = _this.get('startDate');
        requestParams.date_to = _this.get('endDate');
      }

      facility.getStorageAvailability(requestParams).then(function (response) {
        _this2.set('store.storageAvailability', response);
        var storageType = _this2.get('storageType');
        var defaultType = _this2.get('STORAGE_TYPE__AMBIENT');

        if (!_this2.get('isAvailableCold') && storageType === _this2.get('STORAGE_TYPE__COLD')) {
          _this2.set('storageType', defaultType);
        } else if (!_this2.get('isAvailableSpecial') && storageType === _this2.get('STORAGE_TYPE__SPECIAL')) {
          _this2.set('storageType', defaultType);
        }
      });

      facility.getAvailableDates(requestParams).then(function (response) {
        var availableDates = response.available_dates || [];
        var dates = [];

        availableDates.forEach(function (item) {
          var date = moment.utc(item);

          if (date.isSameOrAfter(moment.utc(), 'day')) {
            dates.push(date);
          }
        });

        _this2.set('availableDates', dates);
      });

      this.get('store').query('facility-listing', {
        facility: String(facility.id)
      }).then(function (listings) {
        _this2.set('facilityListings', listings);
      });
    },
    reloadStorageAvailability: function reloadStorageAvailability(sender) {
      sender.loadStorageAvailability(sender);
    },
    processDateChange: function processDateChange() {
      var startDate = this.get('startDate');
      var endDate = this.get('endDate');
      if (startDate && endDate) {
        this.get('googleTagManager').sendEvent('facility-reservation__date-change', {
          start_date: startDate,
          end_date: endDate,
          facility_id: this.get('facility.id')
        });
      }
    },
    processVolumeChange: function processVolumeChange() {
      var volume = this.get('volume');
      if (volume) {
        this.get('googleTagManager').sendEvent('facility-reservation__volume-change', {
          volume: volume,
          facility_id: this.get('facility.id')
        });
      }
    },


    actions: {
      checkAvailability: function checkAvailability() {
        this.loadStorageAvailability(this);
      },
      checkBuyerPayment: function checkBuyerPayment() {
        var routeName = Ember.getOwner(this).lookup('controller:application').currentPath;
        var route = Ember.getOwner(this).lookup('route:' + routeName);
        route.send('checkBuyerPayment');
      },
      checkSupplierPayment: function checkSupplierPayment() {
        var _this3 = this;

        var facility = this.get('facility');

        if (facility.get('validations.isValid')) {
          facility.set('is_published', true);
          facility.save().then(function () {
            Ember.get(_this3, 'notify').success('Save completed');
            _this3.get('router').transitionTo('dashboard-supplier');
          }).catch(function (error) {
            Ember.get(_this3, 'notify').error(error.message);

            return error;
          });
        }
      },
      showModalSendMessage: function showModalSendMessage() {
        if (this.get('session').get('isAuthenticated')) {
          this.set('openModalSendMessage', true);
        } else {
          $('#loginModal').modal();
        }
      },
      sendMessage: function sendMessage(facility, callback) {
        var _this = this;
        var message = this.get('message');

        message = message.trim();
        if (!message) {
          this.set('error', 'Message cannot be empty');
          return;
        }

        var msg = {
          text: message,
          facility: facility
        };

        this.get('api').messageAdmin(msg).then(callback).catch(function () {
          _this.set('error', 'You cannot post new messages in this conversation');
        });
      }
    }
  });
});