define('ember-components/adapters/invoices-additional-services', ['exports', 'ember-components/adapters/application', 'ember-components/config/environment'], function (exports, _application, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    namespace: '' + _environment.default.APP.API_NAMESPACE

    // buildURL(...argumetns) {
    //   argumetns[0] = 'invoices-additional-services';
    //   return this._super(...argumetns);
    // },

  });
});