define('ember-components/resources/about-us/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  exports.default = Controller.extend({

    dataServices: [{
      disc: 'Storage Fees',
      volume: '500 pallets',
      cost: '$8.45',
      subtotal: '$2,502.00'
    }, {
      disc: 'Carton Receiving',
      volume: '50 pallets',
      cost: '$8.45',
      subtotal: '$1,493.12'
    }, {
      disc: 'Unit Pick',
      volume: '50 units',
      cost: '$8.45',
      subtotal: '$583.10'
    }, {
      disc: 'Pallet Pick',
      volume: '50 pallets',
      cost: '$8.45',
      subtotal: '$3,850.00'
    }, {
      disc: 'Master Carton Labeling',
      volume: '50 pallets',
      cost: '$x.xx',
      subtotal: '$192.00'
    }, {
      disc: 'Kitting',
      volume: '50 units',
      cost: '$x.xx',
      subtotal: '$50.00'
    }, {
      disc: 'Additional Services #1',
      volume: 'n/a',
      cost: '$x.xx',
      subtotal: '$57.00'
    }, {
      disc: 'Overcharge from previous invoice',
      volume: 'n/a',
      cost: '$x.xx',
      subtotal: '$25.00'
    }],
    dataDocuments: [{
      name: 'Filename.jpg',
      type: 'Image / jpg',
      date: '04/21/17'
    }, {
      name: 'Filename.pdf',
      type: 'Document / pdf',
      date: '04/21/17'
    }]
  });
});