define('ember-components/validators/decimal-places', ['exports', 'ember-cp-validations/validators/base'], function (exports, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var DecimalPlaces = _base.default.extend({
    validate: function validate(value) {
      if (typeof value === 'string') {
        if (value.split('.')[1]) {
          if (value.split('.')[1].length > 2) {
            return 'Ensure that there are no more than 2 decimal places';
          }
        }
      }
      return true;
    }
  });

  exports.default = DecimalPlaces;
});