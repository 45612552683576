define('ember-components/serializers/facility', ['exports', 'ember-data', 'ember-components/serializers/drf'], function (exports, _emberData, _drf) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  /* global moment */
  function pushPhoto(store, item) {
    store.push({
      data: {
        id: item.photo.id,
        type: 'photo',
        attributes: item.photo,
        relationships: {
          facility: {
            data: {
              id: item.id,
              type: 'facility'
            }
          }
        }
      }
    });
  }

  /**
   * Facility serializer
   *
   * @class FacilitySerializer
   * @module serializers/facility
   * @extends DRFSerializer
   * @public
   */
  exports.default = _drf.default.extend(_emberData.default.EmbeddedRecordsMixin, {

    attrs: {
      company: { embedded: 'always' },
      operator: { embedded: 'always' }
    },

    serialize: function serialize(snapshot) {
      var json = this._super.apply(this, arguments);
      var model = snapshot.record;

      if (!json.description) {
        json.description = '';
      }
      json.state = snapshot.record.get('state.short');

      json.videos = model.get('videos').filterBy('id').map(function (item) {
        return item.id;
      });
      json.photos = model.get('photos').filterBy('id').map(function (item) {
        return item.id;
      });

      return json;
    },
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload) {
      var states = store.peekAll('state');
      var state = states.find(function (value) {
        return value.data.short === payload.state;
      });

      /* eslint-disable no-param-reassign */
      if (state) {
        payload.state = state.id;
      }

      if (Ember.isArray(payload)) {
        payload = payload.map(function (item) {
          if (item.photo.file) {
            pushPhoto(store, item);
          }
          item.photo = [item.photo];
          return item;
        });
      }

      if (payload.available_time_to && payload.available_time_from) {
        payload.available_time_to = moment.utc(payload.available_time_to, 'HH:mm:ss').format('h:mm A');
        payload.available_time_from = moment.utc(payload.available_time_from, 'HH:mm:ss').format('h:mm A');
      }

      return this._super.apply(this, arguments);
    }
  });
});