define('ember-components/models/certification', ['exports', 'ember-data', 'ember-components/mixins/model-validator'], function (exports, _emberData, _modelValidator) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr;
  exports.default = Model.extend(_modelValidator.default, {
    /**
     * Certification name
     *
     * @property name
     * @type {string}
     * @public
     */
    name: attr('string'),

    validations: {
      name: {
        presence: true
      }
    }
  });
});