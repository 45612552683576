define('ember-components/components/facility-form/additional-information/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      service = Ember.inject.service;
  exports.default = Component.extend({

    /**
     * Store
     *
     * @property store
     * @type {object}
     * @public
     */
    store: service(),

    /**
     * Preferred payment terms
     *
     * @property allPaymentTerms
     * @type {object}
     * @public
     */
    allPaymentTerms: [{ label: 'Due Upon Acceptance', value: 'default' }, { label: 'Net 7', value: 'net_7' }, { label: 'Net 15', value: 'net_15' }, { label: 'Net 30', value: 'net_30' }, { label: 'Net 60', value: 'net_60' }],

    /**
     * Options for payment frequency
     *
     * @property optionsPaymentFrequency
     * @type {object}
     * @public
     */
    optionsPaymentFrequency: [{ label: 'Monthly', value: 'monthly' }, { label: 'Weekly', value: 'weekly' }, { label: 'Bi-Weekly', value: 'bi_weekly' }]
  });
});