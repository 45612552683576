define('ember-components/adapters/certification', ['exports', 'ember-components/adapters/application', 'ember-components/config/environment'], function (exports, _application, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({

    /**
     * Namespace
     *
     * @property namespace
     * @type {string}
     * @default 'api/v1/facilities'
     * @public
     */
    namespace: _environment.default.APP.API_NAMESPACE + '/facilities'
  });
});