define('ember-components/components/search-filtering/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    overlay: Ember.inject.service(),

    didInsertElement: function didInsertElement() {
      var overlaySearch = this.get('overlay.search');
      var isMobile = this.get('isMobile.any');

      $('.dropdown-menu div').on('click', function (event) {
        if (event.target.classList.value !== 'next available' && event.target.classList.value !== 'fa fa-chevron-right glyphicon glyphicon-chevron-right' && event.target.classList.value !== 'prev available' && event.target.classList.value !== 'fa fa-chevron-left glyphicon glyphicon-chevron-left') {
          event.stopPropagation();
        }
      });
      if (!isMobile) {
        $('.overlay-for-dropdown').on('click', function () {
          overlaySearch.hide();
        });
      }
    }
  });
});