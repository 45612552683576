define('ember-components/components/presenter/engagement-dashboard/notes/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    notify: Ember.inject.service(),

    engagement: Ember.computed.alias('model.engagement'),
    note: Ember.computed.alias('model.note'),
    noteText: Ember.computed('note.content', {
      get: function get() {
        return this.get('note.content');
      },
      set: function set(key, value) {
        this.set('note.content', value);
        Ember.run.debounce(this, this._save, 600);

        return value;
      }
    }),

    _save: function _save() {
      var _this = this;

      this.get('note').save().then(function () {
        _this.get('notify').success('Note text updated');
      }).catch(function (e) {
        _this.get('notify').error('Note text not saved');

        return e;
      });
    }
  });
});