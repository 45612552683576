define('ember-components/supplier/company-verify/route', ['exports', 'fetch', 'ember-components/config/environment'], function (exports, _fetch, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route,
      get = Ember.get,
      service = Ember.inject.service,
      run = Ember.run;
  exports.default = Route.extend({
    session: service(),

    model: function model(params) {
      var response = {};

      return (0, _fetch.default)('' + _environment.default.APP.API_HOST + _environment.default.APP.BASE_PATH + '/auth/users/' + params.user_id + '/company/' + params.company_id + '/' + params.action_type, {
        method: 'get',
        headers: { Authorization: 'token ' + get(this, 'session').session.content.authenticated.key }
      }).then(function (data) {
        if (data.status === 403) {
          response.title = 'Action Forbidden';
          response.message = data.detail;
        } else if (data.status === 404) {
          response.title = 'Not Found';
          response.message = data.detail;
        } else if (data.status === 200) {
          response.title = params.action_type === 'approve' ? 'User approved' : 'User declined';
          response.message = data.user;
        }
        run.later(function () {
          window.location.replace('/');
        }, 7000);

        return response;
      });
    }
  });
});