define('ember-components/components/interactive/sliders/sa-slider/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',

    modelCountChanged: Ember.observer('model.@each', function () {
      Ember.run.scheduleOnce('afterRender', this, function () {
        $('#dashboard-slider').slick('unslick');
        this._applySlick();
      });
    }),

    _applySlick: function _applySlick() {
      Ember.run.scheduleOnce('afterRender', this, function () {
        $('#dashboard-slider').slick({
          variableWidth: true,
          arrow: false,
          infinite: false,
          slidesToScroll: 1,
          prevArrow: $('.arrow-left'),
          nextArrow: $('.arrow-right')
        });
      });
    },
    didInsertElement: function didInsertElement() {
      this._applySlick();
    }
  });
});