define('ember-components/models/revision-request', ['exports', 'ember-data', 'ember-cp-validations'], function (exports, _emberData, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _emberCpValidations.buildValidations)({
    reason: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: 'Required'
    })]
  });

  exports.default = _emberData.default.Model.extend(Validations, {
    reason: _emberData.default.attr('string'),
    invoice: _emberData.default.belongsTo('invoice')
  });
});