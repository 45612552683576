define('ember-components/components/search-page/no-results/component', ['exports', 'ember-components/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    formIsSubmitted: false,

    didInsertElement: function didInsertElement() {
      var _this = this;

      window.hbspt.forms.create({
        region: _environment.default.HUBSPOT_FORMS.region,
        portalId: _environment.default.HUBSPOT_FORMS.portalId,
        formId: _environment.default.HUBSPOT_FORMS.formId,
        target: '#hubspot-form-container',
        onFormSubmitted: function onFormSubmitted() {
          _this.set('formIsSubmitted', true);
        }
      });
    }
  });
});