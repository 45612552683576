define('ember-components/components/search-page/custom-g-map-marker/component', ['exports', 'ember-components/components/g-map-marker'], function (exports, _gMapMarker) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  exports.default = _gMapMarker.default.extend({
    circleRadius: 2414.02,
    map: Ember.computed.alias('mapContext.map'),
    markersCluster: Ember.computed.alias('mapContext.markersCluster'),
    infoWindowContent: null,
    overlay: null,
    htmlMarker: false,
    markerEl: null,

    /**
     * Google maps service.
     *
     * @property googleMaps
     * @type {object}
     * @public
     */
    googleMaps: service(),

    customOverlayMarker: function customOverlayMarker() {
      this._super.apply(this, arguments);

      var marker = this.get('marker');

      var customMarkerHtml = this.$(this.htmlMarker);
      this.set('markerEl', customMarkerHtml);

      function CustomMarker(map) {
        this.lat = marker.position.lat();
        this.lng = marker.position.lng();
        this.map_ = map;
        this.div_ = null;
        this.setMap(this.map_);
        this.pos = new google.maps.LatLng(marker.position.lat(), marker.position.lng());
      }

      CustomMarker.prototype = new google.maps.OverlayView();

      CustomMarker.prototype.onAdd = function () {
        var div = customMarkerHtml;

        div.css({
          border: 'none',
          borderWidth: '0px',
          position: 'absolute'
        });

        this.div_ = div;

        var panes = this.getPanes();

        $(panes.overlayMouseTarget).append(this.div_);
      };

      CustomMarker.prototype.draw = function () {
        var cords = new google.maps.LatLngBounds(new google.maps.LatLng(this.lat, this.lng));

        var overlayProjection = this.getProjection();

        var southWest = overlayProjection.fromLatLngToDivPixel(cords.getSouthWest());
        var northEast = overlayProjection.fromLatLngToDivPixel(cords.getNorthEast());

        var div = this.div_;

        div.css({
          left: southWest.x + 'px',
          top: northEast.y + 'px',
          transform: 'translateX(-50%) translateY(-50%)'
        });
      };

      CustomMarker.prototype.onRemove = function () {
        if (this.div_) {
          this.div_.detach();
        }
      };

      CustomMarker.prototype.getDraggable = function () {};
      CustomMarker.prototype.getPosition = function () {
        return this.pos;
      };

      this.set('overlay', new CustomMarker(this.mapContext.map));
      marker.setVisible(false);
    },


    /**
     * Display circules on map set.
     *
     * @return {void}
     * @method setMap
     * @protected
     */
    setMap: function setMap() {
      this._super.apply(this, arguments);
      var cluster = this.get('markersCluster');
      var marker = this.get('marker');

      if (this.htmlMarker) {
        this.customOverlayMarker();
      }

      if (this.infoWindowContent) {
        var infoWindow = new google.maps.InfoWindow({
          content: this.infoWindowContent
        });

        try {
          infoWindow.open(this.map, marker);
        } catch (err) {
          // eslint-disable-next-line no-console
          console.error(err);
        }
      }

      if (cluster) {
        this._changePoistionForCoincidentMarkers(marker);
      }

      if (cluster && !this.htmlMarker) {
        cluster.addMarker(marker);
      } else if (cluster && this.htmlMarker) {
        cluster.removeMarker(marker);
        cluster.addMarker(this.overlay);
      }
    },


    /**
     * On marker positon change we have to check coincident markers
     * again.
     */
    latChanged: Ember.observer('lat', function () {
      var marker = this.get('marker');
      this._changePoistionForCoincidentMarkers(marker);
      this.set('lat', marker.getPosition().lat());
      this.set('lng', marker.getPosition().lng());
    }),

    /**
     * Also removes circle on marker destroy.
     *
     * @return {void}
     * @method willDestroyElement
     * @protected
     */
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      if (this.get('markersCluster')) {
        this.get('markersCluster').removeMarker(this.get('marker'));
        this.get('markersCluster').removeMarker(this.overlay);
      }

      if (this.overlay) {
        this.overlay.onRemove();
      }
    },
    _changePoistionForCoincidentMarkers: function _changePoistionForCoincidentMarkers(newMarker) {
      var googleMapsService = this.get('googleMaps');
      var markers = this.get('markersCluster').getMarkers();
      for (var i = 0; i < markers.length; i += 1) {
        googleMapsService.changeCoincidentMarkersPositions(newMarker, markers[i]);
      }
    }
  });
});