define('ember-components/adapters/end-engagement-reason', ['exports', 'ember-components/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    query: function query(store, type, _query) {
      var _this = this;

      var baseUrl = this.buildURL();
      var url = baseUrl + 'engagements/reasons/?type=' + _query.type;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        $.ajax({
          type: 'GET',
          url: url,
          headers: _this.get('headers')
        }).then(function (responseData) {
          resolve(responseData);
        }, function (error) {
          reject(error);
        });
      });
    }
  });
});