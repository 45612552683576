define('ember-components/components/interactive/modals/request-revision/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    actions: {
      requestRevision: function requestRevision(value) {
        this.set('error', true);
        this.sendAction('requestRevisionAction', value);
      }
    }
  });
});