define('ember-components/adapters/email-request', ['exports', 'ember-components/adapters/application', 'ember-components/config/environment'], function (exports, _application, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    namespace: _environment.default.APP.API_NAMESPACE + '/auth',

    urlForCreateRecord: function urlForCreateRecord() {
      return _environment.default.APP.API_HOST + '/' + this.namespace + '/password/reset/';
    }
  });
});