define('ember-components/components/search-filtering/side-bar/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    overlay: Ember.inject.service(),
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    api: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    userAgentCheck: Ember.inject.service(),
    facilities: Ember.computed.alias('model.facilities'),
    queryString: Ember.computed.alias('model.queryString'),
    queryParams: Ember.computed.alias('model.queryParams'),
    isCrawler: Ember.computed.alias('userAgentCheck.isCrawler'),
    isNoResults: Ember.computed('facilities.length', function () {
      return !this.get('facilities.length');
    }),
    actions: {
      showModalSendMessage: function showModalSendMessage(facility) {
        this.set('facility', facility);
        if (this.get('session').get('isAuthenticated')) {
          this.set('openModalSendMessage', true);
        } else {
          $('#loginModal').modal();
        }
      },
      sendMessage: function sendMessage(facility, callback) {
        var _this = this;
        var message = this.get('message');

        message = message.trim();
        if (!message) {
          this.set('error', 'Message cannot be empty');
          return;
        }

        var msg = {
          text: message,
          facility: facility
        };

        this.get('api').messageAdmin(msg).then(callback).catch(function () {
          _this.set('error', 'You cannot post new messages in this conversation');
        });
      },
      searchAgain: function searchAgain() {
        this.sendAction('searchAgain');
      }
    }
  });
});