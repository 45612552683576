define('ember-components/components/control/input/invoice/service-input/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      observer = Ember.observer,
      run = Ember.run;
  exports.default = Component.extend({
    serviceChanged: observer('service.rate', 'service.quantity', 'service.prorated_quantity', function () {
      run.debounce(this, 'loadCalcTotals', 500);
    }),

    loadCalcTotals: function loadCalcTotals() {
      var _this = this;

      var rate = this.get('service.rate');
      var quantity = this.get('service.quantity');
      var proratedQuantity = this.get('service.prorated_quantity');
      var isExistingService = this.get('service.id');

      if (rate && quantity && proratedQuantity !== '') {
        var requestParams = {
          rate: Number(rate),
          quantity: Number(quantity),
          prorated_quantity: Number(proratedQuantity),
          is_prorated: true
        };
        var service = this.get('service');

        var saveRequest = void 0;
        if (isExistingService) {
          saveRequest = service.getInvoicePrecalculate(requestParams);
        } else {
          saveRequest = service.getNewInvoicePrecalculate(requestParams);
        }

        saveRequest.then(function (response) {
          if (_this.isDestroyed) {
            return;
          }
          _this.set('service.sub_total', response.sub_total);
          _this.set('service.prorated_rate', response.prorated_rate);
          _this.set('service.prorated_sub_total', response.prorated_sub_total);
        });
      }

      if (proratedQuantity === '') {
        this.set('service.prorated_sub_total', 0);
      }
    },


    actions: {
      removeService: function removeService(service) {
        service.deleteRecord();
      },
      changeProrated: function changeProrated(service) {
        var isProrated = service.get('is_prorated');
        service.set('is_prorated', !isProrated);
        service.set('prorated_quantity', 0);
      }
    }
  });
});