define('ember-components/adapters/account', ['exports', 'ember-components/adapters/application', 'ember-components/config/environment'], function (exports, _application, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    namespace: _environment.default.APP.API_NAMESPACE + '/payments/account',

    buildURL: function buildURL(modelName, instance, snapshot, urlType) {
      var url = this._super(modelName, instance, snapshot, urlType);
      var index = url.indexOf('accounts');
      return url.replace(/\/\d+\//, '').substr(0, index);
    }
  });
});