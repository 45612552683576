define('ember-components/components/new-listing-details/order-form/component', ['exports', 'ember-components/constants'], function (exports, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      service = Ember.inject.service;
  exports.default = Ember.Component.extend({
    UOM__PALLET: 'p',
    UOM__CUFT: 'f',

    store: service(),
    currentUser: service(),
    session: service(),
    notify: service(),
    router: service(),
    googleTagManager: service(),

    showDateRangeError: false,
    showVolumeError: false,
    isBottomCollapsed: false,
    isReservationFacilityModalOpen: false,

    startDateMoment: Ember.computed('startDate', {
      get: function get() {
        return this.get('startDate') ? moment(this.get('startDate')) : null;
      },
      set: function set(key, value) {
        this.set('startDate', value ? value.format('YYYY-MM-DD') : null);
        return value;
      }
    }),

    endDateMoment: Ember.computed('endDate', {
      get: function get() {
        return this.get('endDate') ? moment(this.get('endDate')) : null;
      },
      set: function set(key, value) {
        this.set('endDate', value ? value.format('YYYY-MM-DD') : null);
        return value;
      }
    }),

    calculatedPrice: computed('storageRate', 'volume', function () {
      var price = this.get('storageRate') * this.get('volume');
      return Number(price.toFixed(2));
    }),

    getStorageType: computed(function () {
      if (window.location && window.location.href.slice(window.location.href.indexOf('st_type'))) {
        return window.location.href.slice(window.location.href.indexOf('st_type')).split('=')[1];
      }
      return 'f';
    }),

    storageRate: computed('store.storageAvailability', 'storageType', 'getStorageType', {
      get: function get() {
        var storageType = this.get('storageType');
        var storageMethod = this.get('getStorageType') || 'f';
        if (storageMethod === 'p') {
          return this.get('store.storageAvailability.' + storageType + '_pallets_rate');
        } else if (storageMethod === 'f') {
          return this.get('store.storageAvailability.' + storageType + '_feet_rate');
        }
        return null;
      }
    }),

    daysCount: computed('store.storageAvailability', {
      get: function get() {
        var storageType = this.get('storageType');
        return this.get('store.storageAvailability.' + storageType + '_days_count');
      }
    }),

    minOrderQuantity: computed('facility.min_order_quantity_in_feet', 'storageMethod', {
      get: function get() {
        var storageMethod = this.get('storageMethod');

        if (storageMethod === this.get('UOM__PALLET')) {
          return this.get('facility.min_order_quantity');
        } else if (storageMethod === this.get('UOM__CUFT')) {
          return this.get('facility.min_order_quantity');
        }
        return null;
      }
    }),

    volumeIsInvalid: computed('volume', 'storageSpace', 'disabledForm', 'minOrderQuantity', {
      get: function get() {
        var disabledForm = this.get('disabledForm');
        var volume = Number(this.get('volume'));

        var isValid = disabledForm || volume >= Number(this.get('minOrderQuantity')) && volume <= Number(this.get('storageSpace'));
        return !isValid;
      }
    }),

    dateRangeIsInvalid: computed('startDateMoment', 'endDateMoment', 'availableDates', function () {
      var availableDates = this.get('availableDates');
      var startDate = this.get('startDateMoment');
      var endDate = this.get('endDateMoment');
      if (!startDate || !endDate || !availableDates) {
        return true;
      }

      return this.checkRangeHasUnavailableDates(startDate, endDate, availableDates.map(function (date) {
        return date.format('YYYY-MM-DD');
      }));
    }),

    prepareEngagementModel: function prepareEngagementModel(engagement) {
      engagement.set('start', this.get('startDate'));
      engagement.set('end', this.get('endDate'));
      engagement.set('facility', this.get('facility'));
      engagement.set('storage_type', this.get('storageType')[0]);
      engagement.set('storage_uom', 'f');
      engagement.set('storage_units', this.get('volume'));
      engagement.set('usage', this.get('usage'));
    },
    showError: function showError(error) {
      this.get('notify').error(error);
    },


    /**
     * Check when some date from the range is not in some array.
     * @param startDate Range start at 'YYYY-MM-DD' format.
     * @param endDate Range end at 'YYYY-MM-DD' format.
     * @param availableArray Dates array at 'YYYY-MM-DD' format to find.
     */
    checkRangeHasUnavailableDates: function checkRangeHasUnavailableDates(startDate, endDate, availableArray) {
      var datesArray = [];

      if (startDate && endDate) {
        var startPeriod = moment(startDate);
        while (startPeriod <= endDate) {
          datesArray.push(startPeriod.format('YYYY-MM-DD'));
          startPeriod = startPeriod.add(1, 'days');
        }
      }
      return datesArray.some(function (date) {
        return !availableArray.includes(date);
      });
    },


    actions: {
      checkAvailability: function checkAvailability() {
        this.checkAvailability();
        if (!this.get('isReservationFacilityModalOpen')) {
          this.set('showVolumeError', true);
        }
      },
      hideSpaceInputError: function hideSpaceInputError() {
        this.set('showVolumeError', false);
      },
      hideSpaceDateError: function hideSpaceDateError() {
        this.set('showDateRangeError', false);
      },
      toggleBottomSectionDisplay: function toggleBottomSectionDisplay() {
        var isCollapsed = this.get('isBottomCollapsed');
        this.set('isBottomCollapsed', !isCollapsed);
      },
      setFacilityUrl: function setFacilityUrl() {
        window.localStorage.setItem(_constants.default.STORAGE_FACILITY_LINK, window.location.href);
        window.localStorage.setItem(_constants.default.STORAGE_FACILITY_DETAILS, JSON.stringify({
          facilityName: this.get('facility.nickname'),
          facilityCity: this.get('facility.city') + ', ' + this.get('facility.state.short')
        }));
      },
      submitModalForm: function submitModalForm() {
        this.get('googleTagManager').sendEvent('facility-reservation-modal__submit', {
          start_date: this.get('startDate'),
          end_date: this.get('endDate'),
          volume: this.get('volume'),
          facility_id: this.get('facility.id'),
          usage: this.get('usage')
        });

        this.get('router').transitionTo('engagements.request', this.get('facility.id'), {
          queryParams: {
            dateStart: this.get('startDate'),
            dateEnd: this.get('endDate'),
            storage_units: this.get('volume'),
            storage_uom: this.get('UOM__CUFT'),
            storageType: this.get('storageType')[0],
            usage: this.get('usage')
          }
        });
      },
      submitForm: function submitForm() {
        if (!this.get('session').session.content.authenticated.key) {
          $('#loginModalFacility').modal('show');
          return;
        }

        if (this.get('dateRangeIsInvalid')) {
          this.set('showDateRangeError', true);
          return;
        }

        if (this.get('volumeIsInvalid')) {
          this.set('showVolumeError', true);
          return;
        }

        this.get('googleTagManager').sendEvent('facility-reservation__submit', {
          start_date: this.get('startDate'),
          end_date: this.get('endDate'),
          volume: this.get('volume'),
          facility_id: this.get('facility.id')
        });

        this.set('showDateRangeError', false);
        this.set('showVolumeError', false);
        this.set('isReservationFacilityModalOpen', true);
      }
    }
  });
});