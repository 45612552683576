define('ember-components/engagements/supplier/route', ['exports', 'ember-components/libs/base/route'], function (exports, _route) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _route.default.extend({
    model: function model(params) {
      var _this = this;

      var model = this.modelFor('engagements');
      model.engagement = this.store.findRecord('engagement', params.engagement_id, {
        adapterOptions: { type: 'supplier' }
      });
      model.invoices = this.store.query('invoice', {
        engagement: model.engagement.get('id'),
        type: 'supplier'
      });
      model.reason = this.store.createRecord('reason');

      model.note = this.store.query('engagement-note', { engagement: params.engagement_id }).then(function (notes) {
        if (notes.get('length')) {
          return notes.filterBy('engagement', Number(params.engagement_id)).get('firstObject');
        }

        return _this.store.createRecord('engagement-note', { engagement: params.engagement_id });
      });

      if (params.amendment_id) {
        model.amendment = this.store.findRecord('amendment', params.amendment_id, {
          adapterOptions: { type: 'supplier' }
        });
      }

      return Ember.RSVP.hash(model);
    },
    afterModel: function afterModel(model) {
      var engagement = model.engagement;
      var buyerRating = engagement.get('buyer_rating');

      if (!buyerRating.content) {
        engagement.set('buyer_rating', this.store.createRecord('buyer-rating'));
      }

      engagement.get('invoices').forEach(function (invoice) {
        return invoice.loadInvoicePdfUrl({ type: 'supplier' });
      });
    }
  });
});