define('ember-components/components/interactive/modals/-published/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    actions: {
      close: function close() {
        this.set('open', false);
        if (this.get('closeAction')) {
          this.sendAction('closeAction');
        }
      },
      editFacility: function editFacility() {
        this.set('open', false);
        if (this.get('editFacilityAction')) {
          this.sendAction('editFacilityAction');
        }
      }
    }
  });
});