define('ember-components/components/control/input/invoice/service-toggle-input/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['row'],
    notify: Ember.inject.service(),
    channelInvoiceWasEdited: Ember.computed.reads('channelName'),

    editVector: false,
    isEdit: Ember.computed('model.isNew', 'editVector', 'model.invoice.isReleased', {
      get: function get() {
        return this.get('model.isNew') || this.get('editVector');
      },
      set: function set(key, value) {
        this.set('editVector', value);
      }
    }),

    triggerChannel: function triggerChannel() {
      var channelName = this.get('channelInvoiceWasEdited');
      var eventEmitter = this.get('eventEmitter');
      eventEmitter.trigger(channelName);
    },
    validate: function validate() {
      var _this = this;

      var model = this.get('model');
      model.get('errors').forEach(function (item) {
        var attribute = item.attribute;

        item.message.forEach(function (error) {
          _this.get('notify').alert(attribute + ': ' + error);
        });
      });
    },


    actions: {
      edit: function edit() {
        this.set('editVector', true);
      },
      remove: function remove() {
        var _this2 = this;

        this.get('model').destroyRecord().then(function () {
          _this2.triggerChannel();
        });
      },
      save: function save() {
        var _this3 = this;

        var model = this.get('model');
        if (model.validate()) {
          var isNew = model.get('isNew');
          model.save().then(function () {
            _this3.triggerChannel();
            if (isNew) {
              _this3.sendAction('handlerCreateDammuService');
            }
            _this3.set('editVector', false);
          });
        } else {
          this.validate();
        }
      },
      cancel: function cancel() {
        var model = this.get('model');
        if (model.get('isNew')) {
          this.set('model.title', '');
          this.set('model.fee', 0);
        } else {
          model.rollbackAttributes();
        }
        this.set('editVector', false);
      }
    }
  });
});