define('ember-components/models/password-reset-confirm', ['exports', 'ember-data', 'ember-cp-validations'], function (exports, _emberData, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr;


  var Validations = (0, _emberCpValidations.buildValidations)({
    password1: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: 'Required'
    }), (0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
      min: 8
    }), (0, _emberCpValidations.validator)('format', {
      regex: /^(?=.*\d).*$/,
      message: 'Password must include at least one digit'
    }), (0, _emberCpValidations.validator)('format', {
      regex: /(?=.*[a-z]).*/,
      message: 'Password must include at least one lower-case character'
    }), (0, _emberCpValidations.validator)('format', {
      regex: /(?=.*[!@#$%^&*]).*/,
      message: 'Password must include at least one special character (!, @, #, $, %, ^, &, *)'
    }), (0, _emberCpValidations.validator)('format', {
      regex: /(?=.*[A-Z]).*/,
      message: 'Password must contain at least one upper case character.'
    })],
    password2: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: 'Required'
    }), (0, _emberCpValidations.validator)('confirmation', {
      on: 'password1',
      message: "Doesn't match Password"
    })]
  });

  exports.default = Model.extend(Validations, {
    password1: attr('string'),
    password2: attr('string'),
    uid: attr('string'),
    key: attr('string')
  });
});