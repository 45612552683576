define('ember-components/models/ach-verification', ['exports', 'ember-data', 'ember-cp-validations'], function (exports, _emberData, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr;


  var TWO_DECIMAL_PATTERN = /^(?=.*\d)\d*(?:\.\d{0,2})?$/;

  var Validations = (0, _emberCpValidations.buildValidations)({
    amountOne: [(0, _emberCpValidations.validator)('number', {
      allowString: true,
      gt: 0,
      lt: 100
    }), (0, _emberCpValidations.validator)('format', {
      regex: TWO_DECIMAL_PATTERN,
      message: 'Please enter the value in "0.00" format.'
    })],
    amountTwo: [(0, _emberCpValidations.validator)('number', {
      allowString: true,
      gt: 0,
      lt: 100
    }), (0, _emberCpValidations.validator)('format', {
      regex: TWO_DECIMAL_PATTERN,
      message: 'Please enter the value in "0.00" format.'
    })]
  });

  exports.default = Model.extend(Validations, {
    amountOne: attr('number'),
    amountTwo: attr('number')
  });
});