define('ember-components/seo-search/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller,
      service = Ember.inject.service;
  exports.default = Controller.extend({
    session: service(),
    fastboot: service(),

    storageMethod: 'p',

    volume: 0,

    storageType: 'ambient',

    ordering: 'distanceAsc',

    queryParams: ['startDate', 'endDate', 'storageMethod', 'volume', 'storageType', 'ordering', 'zoom'],
    isFastBoot: Ember.computed(function () {
      return this.get('fastboot.isFastBoot');
    }),
    seoBlockStyles: Ember.computed('isFastBoot', function () {
      var display = this.get('isFastBoot') ? 'none' : 'block';
      return Ember.String.htmlSafe('display: ' + display);
    }),

    actions: {
      setLatitude: function setLatitude(lat, lng) {
        this.set('latitude', lat);
        this.set('longitude', lng);
      }
    }
  });
});