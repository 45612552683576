define('ember-components/validators/image-size', ['exports', 'ember-cp-validations/validators/base'], function (exports, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var ImageSize = _base.default.extend({
    validate: function validate(value) {
      if (value) {
        if (value.size <= 8388608) {
          return true;
        }
        return 'Size of file should be less than 8 MB';
      }
      return true;
    }
  });

  exports.default = ImageSize;
});