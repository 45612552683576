define('ember-components/components/interactive/modals/end-engagement-modal/first-step/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service(),

    // Props
    model: null,
    engagement: null,
    error: null,
    type: null,

    reasons: [],

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.get('store').query('end-engagement-reason', {
        // Type values are defined at engagement-dashboard
        type: this.type
      }).then(function (reasons) {
        _this.set('reasons', reasons);
      });
    }
  });
});