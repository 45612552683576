define('ember-components/components/interactive/home-own-warehouse/component', ['exports', 'ember-components/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      service = Ember.inject.service,
      computed = Ember.computed,
      get = Ember.get;
  exports.default = Ember.Component.extend({
    notify: service(),
    store: service(),
    session: service(),

    actions: {}
  });
});