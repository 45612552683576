define('ember-components/adapters/amendment', ['exports', 'ember-components/adapters/application', 'ember-components/config/environment'], function (exports, _application, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    namespace: _environment.default.APP.API_NAMESPACE + '/engagements',

    urlForFindRecord: function urlForFindRecord(id, modelName, snapshot) {
      this.set('addTrailingSlashes', false);
      var url = this._super(id, modelName, snapshot);
      var type = snapshot.adapterOptions.type;
      return url + '/?type=' + type;
    }
  });
});