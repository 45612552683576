define('ember-components/components/feedback-form/component', ['exports', 'ember-components/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      service = Ember.inject.service;
  exports.default = Component.extend({
    googleTagManager: service(),

    didInsertElement: function didInsertElement() {
      var _this = this;

      window.hbspt.forms.create({
        region: _environment.default.HUBSPOT_FORMS.region,
        portalId: _environment.default.HUBSPOT_FORMS.portalId,
        formId: _environment.default.HUBSPOT_FORMS.formId,
        target: '#hubspot-form-container',
        onFormSubmitted: function onFormSubmitted() {
          _this.set('modalOpen', true);
          _this.get('googleTagManager').sendEvent('pageview', {
            url: window.location.href
          });
        }
      });
    }
  });
});