define('ember-components/services/billing-history', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({

    store: Ember.inject.service(),

    getBillingHistory: function getBillingHistory(engagement) {
      return this.get('store').query('invoice', { engagement: engagement });
    }
  });
});