define('ember-components/initializers/inject', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize(application) {
    ['controller', 'component', 'route'].forEach(function (injectionTarget) {
      application.inject(injectionTarget, 'configProvider', 'service:configProvider');
      application.inject(injectionTarget, 'storageHub', 'service:storageHub');
      application.inject(injectionTarget, 'eventEmitter', 'service:eventEmitter');
      application.inject(injectionTarget, 'currentRoute', 'service:currentRoute');
      // FastBoot is defined globally only during build step.
      // We use it to disable messagesHub on server because it's incompatible with FastBoot.
      if (typeof FastBoot === 'undefined') {
        application.inject(injectionTarget, 'messagesHub', 'service:messagesHub');
      }
    });
  }

  exports.default = {
    name: 'inject-config-provider',
    initialize: initialize
  };
});