define('ember-components/adapters/buyer-rating', ['exports', 'ember-components/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    pathForType: function pathForType() {
      return '';
    },
    buildURL: function buildURL(modelName, id, snapshot, requestType) {
      var engagementId = snapshot.record.get('engagement.id');

      if (requestType === 'createRecord') {
        return this._super.apply(this, arguments) + 'engagements/' + engagementId + '/rating/?type=supplier';
      }

      return this._super.apply(this, arguments);
    }
  });
});