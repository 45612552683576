define('ember-components/components/interactive/panels/invoice/invoice-summary/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;

  var STATUS_PENDING = 'pending';
  var STATUS_ON_REVIEW = 'on_review';

  exports.default = Ember.Component.extend({
    notify: Ember.inject.service(),
    store: Ember.inject.service(),
    invoice: Ember.computed.alias('model'),
    channelName: 'invoice-calculate-run',
    openModalGoIT: false,
    hasErrors: computed.or('invoice.hasErrors'),
    exchangeFee: computed('invoice.totalSum', 'model.engagement.supplier_app_fee_percent', 'isReleased', function () {
      var isReleased = this.get('isReleased');
      if (isReleased) {
        return this.get('invoice.commission');
      }
      var invoiceTotal = this.get('invoice.totalSum');
      var commission = this.get('model.engagement.supplier_app_fee_percent') / 100;
      return invoiceTotal * commission;
    }),
    supplierReceives: computed('invoice.totalSum', 'exchangeFee', function () {
      return this.get('invoice.totalSum') - this.get('exchangeFee');
    }),

    dueDateFormatted: computed('invoice.due_date', function () {
      var date = this.get('invoice.due_date');

      return date ? moment.utc(date).format('ll') : '';
    }),

    willDestroy: function willDestroy() {
      var eventEmitter = this.get('eventEmitter');
      var channelName = this.get('channelName');
      eventEmitter.off(channelName);
    },
    _exit: function _exit() {
      var engagement = this.get('model.engagement');
      this.transitionToRoute('engagements.supplier', engagement.get('id'));
    },


    /**
     * Save invoice storages.
     * @param {number} invoiceId
     * @return {Promise<any>}
     */
    saveStorages: function saveStorages(invoiceId) {
      var _this = this;

      var storages = this.get('invoice.storages');
      var promises = [];

      storages.forEach(function (record) {
        record.set('invoice', invoiceId);
        promises.push(record.save());
      });

      return Ember.RSVP.all(promises).catch(function (error) {
        _this.get('notify').error('An error occurred while saving the storages. Please try again later.');
        console.error(error);

        return error;
      });
    },


    /**
     * Save invoice services.
     * @param {number} invoiceId
     * @return {Promise<any>}
     */
    saveServices: function saveServices(invoiceId) {
      var _this2 = this;

      var services = this.get('invoice.services');
      var promises = [];

      services.forEach(function (record) {
        record.set('invoice', invoiceId);
        promises.push(record.save());
      });

      return Ember.RSVP.all(promises).catch(function (error) {
        _this2.get('notify').error('An error occurred while saving the services. Please try again later.');
        console.error(error);

        return error;
      });
    },


    /**
     * Save invoice attachments.
     * @return {Promise<any>}
     */
    saveAttachments: function saveAttachments() {
      var _this3 = this;

      var attachments = this.get('invoice.attachments');
      var promises = [];

      attachments.forEach(function (record) {
        promises.push(record.save());
      });

      return Ember.RSVP.all(promises).catch(function (error) {
        _this3.get('notify').error('An error occurred while saving the attachments. Please try again later.');
        console.error(error);

        return error;
      });
    },
    sendData: function sendData() {
      var _this4 = this;

      var model = this.get('model');

      return model.save().then(function (data) {
        var invoiceId = Number(data.get('id'));

        return Ember.RSVP.all([_this4.saveServices(invoiceId), _this4.saveStorages(invoiceId), _this4.saveAttachments()]);
      }).catch(function (e) {
        _this4.get('notify').error('An error occurred while saving the invoice. Please try again later.');
        console.error(e);
      });
    },


    actions: {
      actionHandler: function actionHandler() {
        var engagement = this.get('model.engagement');
        var route = this.get('currentRoute').getRoute();
        route.transitionTo('engagements.supplier', engagement.get('id'));
      },
      invoiceRelease: function invoiceRelease() {
        var _this5 = this;

        var model = this.get('model');

        if (!model.validate()) {
          var controller = this.get('currentRoute').getController();
          controller.validate();
          return null;
        }

        var postFix = void 0;
        if (model.get('status') === STATUS_ON_REVIEW) {
          postFix = 'accept-review';
        } else {
          postFix = 'release';
        }

        this.sendData().then(function () {

          model.save({
            adapterOptions: {
              postFix: postFix,
              type: 'supplier'
            }
          }).then(function () {
            model.set('status', STATUS_PENDING);
            _this5.set('openModalGoIT', true);
            model.save();
            model.set('attachments', model.get('savedAttachments'));
          }).catch(function (e) {
            e.errors.forEach(function (error) {
              _this5.get('notify').alert(error.detail);
            });
          });
        });
      }
    }
  });
});