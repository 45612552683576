define('ember-components/models/company', ['exports', 'ember-data', 'ember-cp-validations', 'ember-components/constants'], function (exports, _emberData, _emberCpValidations, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var ONLY_DIGITS_PATTERN = /^\d+$/;

  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;


  var requiredValidator = (0, _emberCpValidations.validator)('presence', {
    presence: true,
    ignoreBlank: true,
    message: 'Required'
  });

  var buyerDisabled = Ember.computed('model.type', function () {
    return this.get('model.type') === _constants.default.COMPANY_TYPE.BUYER;
  });

  var shouldValidatePayment = function shouldValidatePayment(model) {
    return model.get('type') !== _constants.default.COMPANY_TYPE.BUYER && model.get('validate_payment');
  };

  var paymentValidationDisabled = Ember.computed('model.business_tax_id', 'model.validate_payment', 'model.business_tax_id_provided', function () {
    return !shouldValidatePayment(this.get('model')) && !this.get('model.business_tax_id') || this.get('model.business_tax_id_provided');
  });

  var urlDisabled = Ember.computed('model.description', 'model.validate_payment', function () {
    return !shouldValidatePayment(this.get('model')) || Boolean(this.get('model.description'));
  });

  var descriptionDisabled = Ember.computed('model.url', 'model.validate_payment', function () {
    return !shouldValidatePayment(this.get('model')) || Boolean(this.get('model.url'));
  });

  var supplierRequiredValidator = (0, _emberCpValidations.validator)('presence', {
    presence: true,
    ignoreBlank: true,
    message: 'Required',
    disabled: buyerDisabled
  });

  var Validations = (0, _emberCpValidations.buildValidations)({
    name: [supplierRequiredValidator],
    address: [requiredValidator],
    city: [supplierRequiredValidator],
    state: [requiredValidator],
    zip: [requiredValidator],
    phone: [requiredValidator],
    url: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true,
      message: 'Either URL or Description is required',
      disabled: urlDisabled
    }), (0, _emberCpValidations.validator)('format', {
      type: 'url',
      ignoreBlank: true,
      disabled: urlDisabled
    })],
    description: [(0, _emberCpValidations.validator)('length', {
      max: 288
    }), (0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true,
      message: 'Either URL or Description is required',
      disabled: descriptionDisabled
    })],
    business_tax_id: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true,
      message: 'Required',
      disabled: paymentValidationDisabled
    }), (0, _emberCpValidations.validator)('length', {
      is: 9,
      message: 'Must contain 9 characters',
      disabled: paymentValidationDisabled
    }), (0, _emberCpValidations.validator)('format', {
      regex: ONLY_DIGITS_PATTERN,
      message: 'Must contain digits only',
      disabled: paymentValidationDisabled
    })]
  });

  exports.default = Model.extend(Validations, {
    name: attr('string', { defaultValue: '' }),
    is_confirmed: attr('boolean'),
    type: attr('string'),
    admin_user: attr('number'),
    account: belongsTo('account'),
    city: attr('string'),
    address: attr('string'),
    state: attr('string'),
    zip: attr('string'),
    phone: attr('string'),
    url: attr('string', { defaultValue: '' }),
    description: attr('string', { defaultValue: '' }),
    business_tax_id: attr('string'),
    validate_payment: false,
    business_tax_id_provided: attr('boolean')
  });
});