define('ember-components/models/card', ['exports', 'ember-data', 'ember-cp-validations', 'ember-api-actions'], function (exports, _emberData, _emberCpValidations, _emberApiActions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  var Model = _emberData.default.Model,
      attr = _emberData.default.attr;
  var computed = Ember.computed,
      get = Ember.get;


  var Validations = (0, _emberCpValidations.buildValidations)({
    number: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('card-number')],
    cvc: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('cvc')],
    expirity: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('expirity')]
  });

  exports.default = Model.extend(Validations, {
    number: attr('number'),
    cvc: attr('number'),
    exp_month: attr('number'),
    exp_year: attr('number'),
    expirity: attr(),
    source: attr('string'),
    brand: attr('string'),
    default: attr('boolean'),
    last4: attr('number'),

    expMonth: computed('expirity', function () {
      var _get$split$map = get(this, 'expirity').split('/').map(function (val) {
        return val.trim();
      }),
          _get$split$map2 = _slicedToArray(_get$split$map, 1),
          expMonth = _get$split$map2[0];

      return expMonth;
    }),

    expYear: computed('expirity', function () {
      var _get$split$map3 = get(this, 'expirity').split('/').map(function (val) {
        return val.trim();
      }),
          _get$split$map4 = _slicedToArray(_get$split$map3, 2),
          expYear = _get$split$map4[1];

      return expYear;
    }),

    logoPath: computed('brand', function () {
      var brand = get(this, 'brand');
      switch (brand) {
        case 'Visa':
          return '/img/general/cards/visa.png';
        case 'MasterCard':
          return '/img/general/cards/mc.png';
        case 'American Express':
          return '/img/general/cards/ae.png';
        default:
          return '/img/general/cards/default.png';
      }
    }),

    setDefault: (0, _emberApiActions.memberAction)({ path: 'default/', type: 'POST' })
  });
});