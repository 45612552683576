define('ember-components/components/tooltip-abs/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'span',
    absTextComponent: '',
    hideTimeoutId: null,
    renderIn: $(document.documentElement),
    debounce: 600,

    actions: {
      show: function show() {
        var tooltip = this.$('.tooltip-abs__icon');
        var tooltipText = this.$('.tooltip-abs__text').clone(true);
        var tooltipOffset = tooltip.offset();
        var timeout = this.get('hideTimeoutId');

        this.send('removeAbsTextComponent');
        this.set('absTextComponent', tooltipText);

        clearTimeout(timeout);

        tooltipText.css({
          position: 'absolute',
          top: tooltipOffset.top + tooltip.outerHeight(),
          left: tooltipOffset.left,
          transform: 'translateX(-50%)'
        });

        this.get('renderIn').append(tooltipText);

        tooltipText.show();
      },
      hide: function hide() {
        var _this = this;

        var timeout = setTimeout(function () {
          _this.send('removeAbsTextComponent');
        }, this.get('debounce'));

        this.set('hideTimeoutId', timeout);
      },
      removeAbsTextComponent: function removeAbsTextComponent() {
        if (this.get('absTextComponent')) {
          this.get('absTextComponent').remove();
          this.set('absTextComponent', null);
        }
      }
    }
  });
});