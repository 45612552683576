define('ember-components/components/facility-form/facility-amenities/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      set = Ember.set;
  exports.default = Component.extend({

    actions: {

      /**
       * Disable forklift capacity inputs if chosen 'none'.
       * 
       * @return void
       */
      forkliftNoneChanged: function forkliftNoneChanged() {
        set(this, 'facility.max_storage_weight', null);
      }
    }
  });
});