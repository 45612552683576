define('ember-components/components/interactive/modals/ach-verification/component', ['exports', 'ember-components/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      service = Ember.inject.service;

  var API_PATH = '' + _environment.default.APP.API_HOST + _environment.default.APP.BASE_PATH;
  var FLASH_MESSAGES_TIMEOUT = 5000;

  /** ACH payment verification modal. */
  exports.default = Component.extend({
    store: service(),
    session: service(),
    flashMessages: service(),
    amountOneFieldName: 'first_amount',
    amountTwoFieldName: 'second_amount',

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.set('model', this.get('store').createRecord('ach-verification'));
    },

    isShowAmountOneError: false,
    isShowAmountTwoError: false,
    actions: {
      submit: function submit() {
        var _this = this;

        this.get('model.errors').clear();
        var amountOne = this.get('model.amountOne');
        var amountTwo = this.get('model.amountTwo');
        var accountId = this.get('accountId');
        Ember.$.ajax({
          url: API_PATH + '/payments/customer/banks/' + accountId + '/verify/',
          type: 'POST',
          data: { first_amount: amountOne, second_amount: amountTwo },
          headers: { Authorization: 'Token ' + this.get('session').session.content.authenticated.key }
        }).then(function () {
          _this.sendAction('close');
          _this.set('isConfirmationModalOpened', true);
        }).catch(function (e) {
          var error = e.responseJSON;
          var validationErrors = error.validation_errors;
          var message = '';
          if (validationErrors && validationErrors.length > 0) {
            if (validationErrors[0].field === 'detail') {
              message = validationErrors[0].errors;
            } else {
              validationErrors.forEach(function (validateError) {
                _this.get('model.errors').add(validateError.field, validateError.errors);
              });
            }
          } else {
            message = error.detail;
          }
          if (message) {
            _this.get('flashMessages').danger(message, {
              timeout: FLASH_MESSAGES_TIMEOUT
            });
          }
        });
      },
      close: function close() {
        this.set('model.amountOne', '');
        this.set('model.amountTwo', '');
        this.set('isShowAmountOneError', false);
        this.set('isShowAmountTwoError', false);
        this.sendAction('close');
      }
    }
  });
});