define('ember-components/serializers/facility-search', ['exports', 'ember-data', 'ember-components/serializers/drf'], function (exports, _emberData, _drf) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _drf.default.extend(_emberData.default.EmbeddedRecordsMixin, {
    attrs: {
      company: { embedded: 'always' },
      photo: { embedded: 'always' }
    },

    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload) {
      payload.forEach(function (item) {
        if (!item.photo.id) {
          // eslint-disable-next-line no-param-reassign
          delete item.photo;
        }
      });

      return this._super.apply(this, arguments);
    }
  });
});