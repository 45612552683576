define('ember-components/mixins/facility-publish-route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    session: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    storageHub: Ember.inject.service(),

    model: function model() {
      var model = this._super.apply(this, arguments);
      var facilityId = model.facility.id;

      model.facility_listings = this.store.query('facility-listing', {
        facility: String(facilityId)
      });

      if (this.get('session.isAuthenticated')) {
        model.supplierCards = this.store.findAll('supplier-card').catch(function () {
          return [];
        });
        model.supplierBankAccounts = this.store.findAll('supplier-bank-account').catch(function () {
          return [];
        });
      }

      return Ember.RSVP.hash(model);
    },


    actions: {
      checkBuyerPayment: function checkBuyerPayment() {
        // [IK]: As I see the route is not used, see payment/check-buyer-payment.
      },
      checkSupplierPayment: function checkSupplierPayment() {
        var _this = this;

        // const controller = this.get('controller');
        // const supplierCards = controller.get('supplierCards');
        // const supplierBankAccounts = controller.get('supplierBankAccounts');
        // if (supplierCards.get('length') < 1 && supplierBankAccounts.get('length') < 1) {
        //   $('#checkSupplierPaymentModal').modal();
        // } else {
        var facilityModel = this.get('currentModel.facility');
        facilityModel.set('is_published', true);
        facilityModel.save().then(function (_data) {
          // this.transitionTo('home');
          _this.controller.set('publishedModal', true);
          // if (!data.get('is_published')) {
          //   if (this.get('currentUser.user.is_company_confirmed')) {
          //     this.controller.set('companyConfirmedModal', true);
          //   } else {
          //     this.controller.set('approvedModal', true);
          //   }
          // } else {
          //   this.controller.set('publishedModal', true);
          // }
        });
        // }
      },
      close: function close() {
        this.transitionTo('dashboard-supplier');
      },
      viewFacility: function viewFacility() {
        this.transitionTo('new-facility-listing-details', this.controller.get('model.facility.id'));
      }
    }
  });
});