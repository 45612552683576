define('ember-components/components/sign-up-form/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      get = Ember.get,
      set = Ember.set,
      service = Ember.inject.service;
  exports.default = Component.extend({
    notify: service(),
    store: service(),
    flashMessages: service(),

    selectLocationField: false,

    didInsertElement: function didInsertElement() {
      var _this = this;

      var urlParams = new URLSearchParams(window.location.search);
      if (urlParams.has("registration")) {
        $('#signUpModal').modal();
      }

      $('#signUpModal').on('hide.bs.modal', function () {
        set(_this, 'model', get(_this, 'store').createRecord('user'));
        set(_this, 'showEmailError', false);
        set(_this, 'showPasswordError', false);
        set(_this, 'showPasswordError2', false);
        set(_this, 'showLocationError', false);
        set(_this, 'value', null);
        $('.only-for-input').removeAttr('style');
      });
    },


    actions: {
      signUp: function signUp(model) {
        var _this2 = this;

        if (!get(this, 'tos')) {
          get(this, 'flashMessages').danger('You have to accept Terms of Services');
          return;
        }

        model.validate().then(function (_ref) {
          var validations = _ref.validations;

          if (validations.get('isValid')) {
            // TODO: remove after change API
            var new_model = model;
            new_model.password1 = model.get('password');
            new_model.password2 = model.get('password2');
            new_model.location = {
              latitude: model.get('latitude'),
              longitude: model.get('longitude')
            };

            new_model.save().then(function () {
              $('#signUpModal').modal('hide');
              $('#welcomeModal').modal();
            }).catch(function (e) {
              get(_this2, 'flashMessages').danger(e.message, { timeout: 5000 });
            });
          }
        });
      },
      password: function password() {
        var type = $('#sign-up-confirm-input-password');
        var button = $('#sign-up-confirm-button-password');

        if (type.attr('type') === 'password') {
          type.prop('type', 'text');
          button.addClass('ic__eye_off');
        } else {
          type.prop('type', 'password');
          button.removeClass('ic__eye_off');
        }
      },


      // urgent fix
      password2: function password2() {
        var type = $('#sign-up-confirm-input-password2');
        var button = $('#sign-up-confirm-button-password2');

        if (type.attr('type') === 'password') {
          type.prop('type', 'text');
          button.addClass('ic__eye_off');
        } else {
          type.prop('type', 'password');
          button.removeClass('ic__eye_off');
        }
      }
    }
  });
});