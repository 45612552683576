define('ember-components/models/engagement-amendment', ['exports', 'ember-data', 'ember-components/config/environment'], function (exports, _emberData, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr,
      hasMany = _emberData.default.hasMany;
  var computed = Ember.computed;
  exports.default = _emberData.default.Model.extend({
    status: attr('string'),
    agreement_date: attr('date'),
    acceptances: hasMany('acceptance'),

    agreementData: computed('agreement_date', function () {
      var agreementDate = this.get('agreement_date');
      if (agreementDate === null) {
        return '(is being signed)';
      }
      return moment.utc(this.get('agreement_date')).format(_environment.default.date.dateSigned);
    })
  });
});