define('ember-components/models/amendment-engagement-data', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr;
  exports.default = _emberData.default.Model.extend({
    payment_terms: attr('string'),
    facility: attr('number')
  });
});