define('ember-components/components/presenter/engagement-dashboard/bayer-rating/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    notify: Ember.inject.service(),
    rating: null,
    isInvalid: Ember.computed.equal('rating.validations.isValid', false),

    actions: {
      updateRating: function updateRating(key, val) {
        this.get('rating').set(key, val);
      },
      submit: function submit() {
        var _this = this;

        this.get('rating.content').save().then(function () {
          _this.get('rating.engagement.content').reload();
          _this.get('notify').success('Rating successfully sent');
        }).catch(function (error) {
          console.error(error);
          _this.get('notify').error(error.message);
        });
      }
    }
  });
});