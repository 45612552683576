define('ember-components/components/list-your-space-button/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      computed = Ember.computed,
      service = Ember.inject.service;
  exports.default = Component.extend({
    tagName: '',
    session: service(),
    currentUser: service(),

    isAuthenticated: computed('session.isAuthenticated', function () {
      return this.get('session').get('isAuthenticated');
    }),
    isSupplier: computed('currentUser.user.is_company_confirmed', function () {
      return this.get('currentUser.user.is_company_confirmed');
    })
  });
});