define('ember-components/dashboard-supplier/controller', ['exports', 'ember-components/mixins/default-payment-property'], function (exports, _defaultPaymentProperty) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      service = Ember.inject.service;
  exports.default = Ember.Controller.extend(_defaultPaymentProperty.default, {
    session: service('session'),
    currentUser: service(),
    list: [1, 2, 3, 4],
    cards: computed.reads('model.cards'),
    bankAccounts: computed.reads('model.bankAccounts'),

    panelMyNetworkViewModel: computed('realModel.length', function () {
      var model = this.get('realModel');
      return {
        type: 'interactive',
        name: 'sa-panel',
        params: {
          customClass: 'panel panel-light-blue panel-dashboard',
          header: {
            title: 'My Network (' + model.get('length') + ')',
            child: [{
              type: 'control',
              name: 'slider/slider-arrows'
            }]
          }
        }
      };
    }),
    isEmptyList: computed.empty('realModel'),
    withoutNewRecordsModel: computed.filterBy('model.facility', 'isNew', false),
    realModel: computed.filterBy('withoutNewRecordsModel', 'isMemberCompany', true)

  });
});