define('ember-components/models/bank-account', ['exports', 'ember-data', 'ember-cp-validations', 'ember-api-actions'], function (exports, _emberData, _emberCpValidations, _emberApiActions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _emberCpValidations.buildValidations)({
    routing_number: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('number', {
      allowString: true,
      integer: true
    })],
    account_number: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('number', {
      allowString: true,
      integer: true
    })],
    account_holder_type: [(0, _emberCpValidations.validator)('presence', true)]
  });

  exports.default = _emberData.default.Model.extend(Validations, {
    routing_number: _emberData.default.attr('string'),
    account_number: _emberData.default.attr('string'),
    account_holder_name: _emberData.default.attr('string'),
    account_holder_type: _emberData.default.attr('string', { defaultValue: 'company' }),
    source: _emberData.default.attr('string'),
    token: _emberData.default.attr('string'),
    bank_name: _emberData.default.attr('string'),
    last4: _emberData.default.attr('string'),
    verified: _emberData.default.attr('boolean'),
    default: _emberData.default.attr('boolean', { defaultValue: false }),

    setDefault: (0, _emberApiActions.memberAction)({ path: 'default/', type: 'POST' }),

    logoPath: Ember.computed('brand', function () {
      return '/img/general/cards/default.png';
    })
  });
});