define('ember-components/components/interactive/modals/delete-confirm/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    actions: {
      deleteAction: function deleteAction(deleteObject) {
        this.sendAction('deleteAction', deleteObject);
        this.set('open', false);
        return false;
      }
    }
  });
});