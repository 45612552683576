define('ember-components/components/presenter/tables/double-cell/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'tr',
    blank: '-',

    selfValue: Ember.computed('value', function () {
      var value = this.get('value');
      var blank = this.get('blank');
      return value ? value : blank;
    })

  });
});