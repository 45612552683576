define('ember-components/adapters/invoice-pdf-url', ['exports', 'ember-components/adapters/application', 'ember-components/config/environment'], function (exports, _application, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    urlForFindRecord: function urlForFindRecord(id, modelName, snapshot) {
      this.set('addTrailingSlashes', false);
      var type = snapshot.adapterOptions.type;
      return _environment.default.APP.API_HOST + '/' + _environment.default.APP.API_NAMESPACE + '/invoices/' + id + '/download-pdf/?type=' + type;
    }
  });
});