define('ember-components/components/listing-details/order-form/component', ['exports', 'ember-components/constants'], function (exports, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      service = Ember.inject.service;
  exports.default = Ember.Component.extend({
    UOM__PALLET: 'p',
    UOM__CUFT: 'f',

    STORAGE_TYPE__AMBIENT: 'ambient',
    STORAGE_TYPE__COLD: 'cold',
    STORAGE_TYPE__SPECIAL: 'special',

    store: service(),
    currentUser: service(),
    session: service(),
    notify: service(),
    router: service(),

    showUsageIsValidError: false,
    cartonCubeMin: null,
    cartonCubeMax: null,
    cartonWeightMin: null,
    cartonWeightMax: null,
    showVolumeError: false,

    attrDisabledButtonSubmit: computed('disabledForm', function () {
      var disabledForm = this.get('disabledForm');
      return disabledForm ? 'disabled=""' : '';
    }),

    startDateMoment: Ember.computed('startDate', {
      get: function get() {
        return this.get('startDate') ? moment(this.get('startDate')) : null;
      },
      set: function set(key, value) {
        this.set('startDate', value ? value.format('YYYY-MM-DD') : null);
      }
    }),

    endDateMoment: Ember.computed('endDate', {
      get: function get() {
        return this.get('endDate') ? moment(this.get('endDate')) : null;
      },
      set: function set(key, value) {
        this.set('endDate', value ? value.format('YYYY-MM-DD') : null);
      }
    }),

    isAvailable: computed('store.storageAvailability', 'storageType', 'storageMethod', {
      get: function get() {
        var storageType = this.get('storageType');
        if (storageType === this.get('STORAGE_TYPE__AMBIENT')) {
          return this.get('isAvailableAmbient');
        } else if (storageType === this.get('STORAGE_TYPE__COLD')) {
          return this.get('isAvailableCold');
        } else if (storageType === this.get('STORAGE_TYPE__SPECIAL')) {
          return this.get('isAvailableSpecial');
        }
        return null;
      }
    }),

    isAvailableAmbient: computed('store.storageAvailability', {
      get: function get() {
        return this.get('store.storageAvailability.is_available_ambient');
      }
    }),

    isAvailableCold: computed('store.storageAvailability', {
      get: function get() {
        return this.get('store.storageAvailability.is_available_cold');
      }
    }),

    isAvailableSpecial: computed('store.storageAvailability', {
      get: function get() {
        return this.get('store.storageAvailability.is_available_special');
      }
    }),

    storageMethod: computed('store.storageAvailability', {
      get: function get() {
        return this.get('store.storageAvailability.storage_method');
      }
    }),

    storageRate: computed('store.storageAvailability', 'storageType', 'storageMethod', {
      get: function get() {
        var storageType = this.get('storageType');
        var storageMethod = this.get('storageMethod');

        if (storageMethod === this.get('UOM__PALLET')) {
          return this.get('store.storageAvailability.' + storageType + '_pallets_rate');
        } else if (storageMethod === this.get('UOM__CUFT')) {
          return this.get('store.storageAvailability.' + storageType + '_feet_rate');
        }
        return null;
      }
    }),

    storageDailyRate: computed('storageRate', {
      get: function get() {
        var monthlyRate = this.get('storageRate');
        var dailyRate = monthlyRate / 30;
        // this allows to round to 2 digits after point
        // 100 = 10 ** 2
        return Math.ceil(dailyRate * 100) / 100;
      }
    }),

    storageSpace: computed('store.storageAvailability', {
      get: function get() {
        return this.get('store.storageAvailability.ambient_feet_amount') || null;
      }
    }),

    daysCount: computed('store.storageAvailability', {
      get: function get() {
        var storageType = this.get('storageType');
        return this.get('store.storageAvailability.' + storageType + '_days_count');
      }
    }),

    minOrderQuantity: computed('facility.min_order_quantity_in_feet', 'storageMethod', {
      get: function get() {
        var storageMethod = this.get('storageMethod');

        if (storageMethod === this.get('UOM__PALLET')) {
          return this.get('facility.min_order_quantity');
        } else if (storageMethod === this.get('UOM__CUFT')) {
          return this.get('facility.min_order_quantity');
        }
        return null;
      }
    }),

    volumeIsInvalid: computed('volume', 'storageSpace', 'disabledForm', 'storageType', 'minOrderQuantity', {
      get: function get() {
        var disabledForm = this.get('disabledForm');
        var volume = Number(this.get('volume'));

        // @TODO is temp solution - we will need extract controls and render
        // errors to special components with view-models
        var isValid = disabledForm || volume >= Number(this.get('minOrderQuantity')) && volume <= Number(this.get('storageSpace'));
        return !isValid;
      }
    }),

    cubeIsValid: computed('cartonCubeMin', 'cartonCubeMax', function () {
      if (!this.get('cartonCubeMin') && !this.get('cartonCubeMax')) {
        return true;
      }

      return this.additionalInfoValidate('cartonCubeMin', 'cartonCubeMax');
    }),

    weightIsValid: computed('cartonWeightMin', 'cartonWeightMax', function () {
      if (!this.get('cartonWeightMin') && !this.get('cartonWeightMax')) {
        return true;
      }

      return this.additionalInfoValidate('cartonWeightMin', 'cartonWeightMax');
    }),

    additionalInfoValidate: function additionalInfoValidate(fieldMin, fieldMax) {
      var min = this.get(fieldMin);
      var max = this.get(fieldMax);
      var isEmptyField = min === null && max === null;
      var isCompletedFields = min !== null && max !== null;

      return isEmptyField || isCompletedFields && Number(max) > Number(min);
    },


    usageIsValid: computed('usage', 'showUsageIsValidError', function () {
      var fieldIsValid = this.get('usage').length > 0;
      if (fieldIsValid) {
        this.set('showUsageIsValidError', false);
      }

      return !this.get('showUsageIsValidError');
    }),

    /**
     * Calculates fee
     */
    storageFee: computed('storageRate', 'daysCount', {
      get: function get() {
        var dailyRate = this.get('storageDailyRate');
        var daysCount = this.get('daysCount');
        var volume = this.get('volume');

        return dailyRate * volume * daysCount;
      }
    }),

    serviceFeeMin: computed('store.storageAvailability', {
      get: function get() {
        return 0;
      }
    }),

    serviceFeeMax: computed('store.storageAvailability', {
      get: function get() {
        return 500;
      }
    }),

    projectedCost: computed('storageFee', {
      get: function get() {
        return this.get('storageFee');
      }
    }),

    prepareEngagementModel: function prepareEngagementModel(engagement) {
      engagement.set('start', this.get('startDate'));
      engagement.set('end', this.get('endDate'));
      engagement.set('facility', this.get('facility'));
      engagement.set('storage_type', this.get('storageType')[0]);
      engagement.set('storage_uom', 'f');
      engagement.set('storage_units', this.get('volume'));
      engagement.set('usage', this.get('usage'));
    },
    showError: function showError(error) {
      this.get('notify').error(error);
    },


    actions: {
      checkAvailability: function checkAvailability() {
        this.checkAvailability();
        this.set('showVolumeError', true);
      },
      setFacilityUrl: function setFacilityUrl() {
        window.localStorage.setItem(_constants.default.STORAGE_FACILITY_LINK, window.location.href);
        window.localStorage.setItem(_constants.default.STORAGE_FACILITY_DETAILS, JSON.stringify({
          facilityName: this.get('facility.nickname'),
          facilityCity: this.get('facility.city') + ', ' + this.get('facility.state.short')
        }));
      },
      submitForm: function submitForm() {
        if (!this.get('isAvailable') || !this.get('startDate') || !this.get('endDate')) {
          $('#date-validation-modal').modal('show');
          return;
        }

        if (this.get('volumeIsInvalid')) {
          $('#volume-validation-modal').modal('show');
          return;
        }

        if (this.get('usage').length === 0) {
          this.set('showUsageIsValidError', true);
          $('#usage-validation-modal').modal('show');
          return;
        }

        if (!this.get('session').session.content.authenticated.key) {
          $('#loginModalFacility').modal('show');
          return;
        }

        if (!this.get('cubeIsValid') || !this.get('weightIsValid')) {
          $('#additional-info-validation-modal').modal('show');
          return;
        }

        this.get('router').transitionTo('engagements.request', this.get('facility').id, {
          queryParams: {
            dateStart: this.get('startDate'),
            dateEnd: this.get('endDate'),
            storage_units: this.get('volume'),
            isNeedServices: this.get('feedback'),
            storage_uom: 'f',
            storageType: this.get('storageType')[0],
            cc_max: this.get('cartonCubeMax'),
            cc_min: this.get('cartonCubeMin'),
            cw_max: this.get('cartonWeightMax'),
            cw_min: this.get('cartonWeightMin'),
            skus: this.get('skus'),
            usage: this.get('usage')
          }
        });
      },
      toggleAdditionalOptions: function toggleAdditionalOptions() {
        this.$(event.target).toggleClass('active');
        this.$('.facility-order-form__additional-options-slider').slideToggle();
      }
    }
  });
});