define('ember-components/engagements/invoice-pay/route', ['exports', 'ember-components/libs/base/route'], function (exports, _route) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var defaultErrorMessage = 'Something wrong.';

  exports.default = _route.default.extend({
    store: Ember.inject.service(),
    currentUser: Ember.inject.service(),

    model: function model(params) {
      var _this = this;

      var model = this.modelFor('engagements');
      var companyId = this.get('currentUser.user.buyer_company.id');
      return this.store.findRecord('engagement', params.engagement_id, {
        adapterOptions: { type: 'buyer' }
      }).then(function (engagement) {
        return _this.store.findRecord('invoice', params.invoice_id, {
          adapterOptions: { type: 'buyer' }
        }).then(function (invoice) {
          return Ember.RSVP.hash({
            engagement: engagement,
            invoice: invoice,
            customer: _this.store.queryRecord('customer', { me: true }),
            revisionRequest: _this.store.createRecord('revision-request'),
            card: _this.store.createRecord('card'),
            bankAccount: _this.store.createRecord('bank-account', {
              default: false
            }),
            cards: _this.store.findAll('card'),
            bankAccounts: _this.store.findAll('bank-account'),
            buyerCompanyModel: _this.store.findRecord('company', companyId),
            states: model.states
          });
        });
      });
    },
    afterModel: function afterModel(model) {
      model.invoice.loadServices();
      model.invoice.loadStorages();
      model.invoice.loadInvoicePdfUrl({ type: 'buyer' });
    },


    actions: {
      payInvoice: function payInvoice(invoice) {
        var _this2 = this;

        this.controller.set('paymentConfirmationModalOpen', false);
        this.controller.set('paymentProcessing', true);
        invoice.pay().then(function () {
          _this2.controller.set('success', true);
          _this2.controller.set('model.invoice', _this2.get('store').findRecord('invoice', _this2.controller.get('model.invoice.id'), { adapterOptions: { type: 'buyer' } }));
        }).catch(function (e) {
          var validation_errors = e.errors[0].detail === 'validation_error' ? e.errors[1].detail : e.errors[0].detail;
          _this2.controller.set('errorDetail', validation_errors || defaultErrorMessage);
          _this2.controller.set('fail', true);
        }).finally(function () {
          _this2.set('paymentProcessing', false);
        });
      },
      requestRevision: function requestRevision(request) {
        var _this3 = this;

        request.set('invoice', this.controller.get('model.invoice'));
        request.validate().then(function (_ref) {
          var validations = _ref.validations;

          if (validations.get('isValid')) {
            _this3.controller.get('model.invoice').requestRevision({ reason: request.get('reason') }).then(function () {
              // Reset value of form
              _this3.controller.set('model.revisionRequest', _this3.store.createRecord('revision-request'));
              _this3.controller.set('requestRevisionModalOpen', false);
              _this3.controller.set('revisionRequestSuccess', true);

              _this3.controller.set('model.invoice.status', 'on_review');
            }).catch(function (e) {
              Ember.Logger.error(e);
            });
          }
        });
      }
    }

  });
});