define('ember-components/components/interactive/facility-reservation/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      computed = Ember.computed;
  exports.default = Component.extend({
    UOM__CUFT: 'f',
    showUsageError: false,

    usageIsInvalid: computed('usage', function () {
      var fieldIsValid = this.get('usage').length > 0;

      return !fieldIsValid;
    }),

    remainingSpace: computed('storageSpace', 'volume', function () {
      var storageSpace = this.get('storageSpace');
      var volume = this.get('volume');
      var remainingSpace = storageSpace - volume;

      return remainingSpace < 0 ? 0 : remainingSpace;
    }),

    actions: {
      /** Handle close modal. */
      close: function close() {
        this.set('open', false);
      },


      /** Send 'checkAvailability' output action. */
      checkAvailability: function checkAvailability() {
        this.set('showVolumeError', true);
        this.sendAction('checkAvailability');
      },
      checkUsage: function checkUsage() {
        this.set('showUsageError', true);
      },
      hideDateRangeError: function hideDateRangeError() {
        this.set('showDateRangeError', false);
      },
      hideSpaceInputError: function hideSpaceInputError() {
        this.set('showVolumeError', false);
      },


      /** Hide usage error notice block. */
      hideSpaceUsageError: function hideSpaceUsageError() {
        this.set('showUsageError', false);
      },


      /** Handle submit form. */
      submit: function submit() {
        if (this.get('dateRangeIsInvalid')) {
          this.set('showDateRangeError', true);
          return;
        }

        if (this.get('volumeIsInvalid')) {
          this.set('showVolumeError', true);
          return;
        }

        if (this.get('usageIsInvalid')) {
          this.set('showUsageError', true);
          return;
        }

        this.sendAction('submit');
      }
    }
  });
});