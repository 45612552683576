define('ember-components/validators/remote-email', ['exports', 'fetch', 'ember-cp-validations/validators/base', 'ember-components/config/environment'], function (exports, _fetch, _base, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get;


  var RemoteEmail = _base.default.extend({
    validate: function validate(value, options) {
      return (0, _fetch.default)('' + _environment.default.APP.API_HOST + _environment.default.APP.BASE_PATH + '/auth/check/' + value + '/').then(function (data) {
        if (data.status === 404) {
          return true;
        }
        return get(options, 'message');
      });
    }
  });

  RemoteEmail.reopenClass({
    getDependentsFor: function getDependentsFor() /* attribute, options */{
      return [];
    }
  });

  exports.default = RemoteEmail;
});