define('ember-components/components/alert-popup/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    didInsertElement: function didInsertElement() {
      var _this = this;

      $('#' + this.elementId).on('click', function () {
        $('#confirmModal-' + _this.elementId).modal();
        return false;
      });
    },

    text: 'Are you sure?',
    elementId: '',
    onYes: function onYes() {}
  });
});