define('ember-components/initializers/model-locale', ['exports', 'ember-model-validator/initializers/model-locale'], function (exports, _modelLocale) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _modelLocale.default;
    }
  });
  Object.defineProperty(exports, 'initialize', {
    enumerable: true,
    get: function () {
      return _modelLocale.initialize;
    }
  });
});