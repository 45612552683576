define('ember-components/components/search-page/custom-popup/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  exports.default = Ember.Component.extend({
    markerContext: null,
    popup: null,
    offsetLimits: { x: 300, y: 300 },
    customMarker: Ember.observer('markerContext.markerEl', function () {
      var customMarker = this.get('markerContext.markerEl');

      this.send('initActions', customMarker);
    }),

    createPopup: function createPopup() {
      var _this = this;

      /**
       * A customized popup on the map.
       * @param {!google.maps.LatLng} position
       * @param {!Element} content The bubble div.
       * @constructor
       * @extends {google.maps.OverlayView}
       */
      function Popup(position, content) {
        this.position = position;

        // This zero-height div is positioned at the bottom of the bubble.
        var bubbleAnchor = document.createElement('div');
        bubbleAnchor.classList.add('popup-bubble-anchor');
        bubbleAnchor.appendChild(content);

        // This zero-height div is positioned at the bottom of the tip.
        this.containerDiv = document.createElement('div');
        this.containerDiv.classList.add('custom-gmap-popup');
        this.containerDiv.appendChild(bubbleAnchor);

        // Optionally stop clicks, etc., from bubbling up to the map.
        google.maps.OverlayView.preventMapHitsAndGesturesFrom(this.containerDiv);
      }
      // ES5 magic to extend google.maps.OverlayView.
      Popup.prototype = Object.create(google.maps.OverlayView.prototype);

      /** Called when the popup is added to the map. */
      Popup.prototype.onAdd = function () {
        this.getPanes().floatPane.appendChild(this.containerDiv);
      };

      /** Called when the popup is removed from the map. */
      Popup.prototype.onRemove = function () {
        if (this.containerDiv.parentElement) {
          this.containerDiv.parentElement.removeChild(this.containerDiv);
        }
      };

      /** Called each frame when the popup needs to draw itself. */
      Popup.prototype.draw = function () {
        var divPosition = this.getProjection().fromLatLngToDivPixel(this.position);
        var markerOffset = _this.getMarkerPixelOffset(_this.get('markerContext.mapContext.map'), _this.get('markerContext.marker'));
        var offsetLimits = _this.get('offsetLimits');

        if (markerOffset.y > offsetLimits.y) {
          this.containerDiv.style.left = divPosition.x + 'px';
          this.containerDiv.style.top = divPosition.y - 35 + 'px';
          this.containerDiv.style.bottom = 'auto';
          this.containerDiv.style.transform = 'translateX(-50%) translateY(-100%)';
          this.containerDiv.classList.add('top');
          this.containerDiv.classList.remove('bottom');
        } else {
          this.containerDiv.style.left = divPosition.x + 'px';
          this.containerDiv.style.top = divPosition.y + 35 + 'px';
          this.containerDiv.style.bottom = 'auto';
          this.containerDiv.style.transform = 'translateX(-50%) translateY(0)';
          this.containerDiv.classList.add('bottom');
          this.containerDiv.classList.remove('top');
        }

        // Hide the popup when it is far out of view.
        var display = Math.abs(divPosition.x) < 4000 && Math.abs(divPosition.y) < 4000 ? 'block' : 'none';

        if (display === 'block') {
          this.containerDiv.style.position = 'absolute';
          this.containerDiv.style.zIndex = 999;
        }
        if (this.containerDiv.style.display !== display) {
          this.containerDiv.style.display = display;
        }
      };

      return Popup;
    },


    /**
     * Get marker offset in pixels.
     * @param map
     * @param marker
     * @return {google.maps.Point}
     */
    getMarkerPixelOffset: function getMarkerPixelOffset(map, marker) {
      // eslint-disable-next-line no-restricted-properties
      var scale = Math.pow(2, map.getZoom());
      var nw = new google.maps.LatLng(map.getBounds().getNorthEast().lat(), map.getBounds().getSouthWest().lng());

      var worldCoordinateNW = map.getProjection().fromLatLngToPoint(nw);
      var worldCoordinate = map.getProjection().fromLatLngToPoint(marker.getPosition());

      return new google.maps.Point(Math.floor((worldCoordinate.x - worldCoordinateNW.x) * scale), Math.floor((worldCoordinate.y - worldCoordinateNW.y) * scale));
    },


    /**
     * Google maps service.
     *
     * @property googleMaps
     * @type {object}
     * @public
     */
    googleMaps: service(),

    actions: {
      initActions: function initActions(marker) {
        var _this2 = this;

        this._super.apply(this, arguments);

        var _get = this.get('markerContext'),
            lat = _get.lat,
            lng = _get.lng,
            mapContext = _get.mapContext;

        var Popup = this.createPopup(mapContext);

        var popup = new Popup(new google.maps.LatLng(lat, lng), this.$()[0]);

        this.set('popup', popup);
        this.get('markerContext').set('popup', popup);

        marker.off().on('click', function () {
          if (_this2.get('popup').map) {
            _this2.get('popup').setMap(null);

            return;
          }

          _this2.get('context.childViews').forEach(function (elem) {
            elem.get('popup').setMap(null);
          });

          popup.setMap(mapContext.map);
        });
      }
    },

    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      if (this.get('popup')) {
        this.get('popup').setMap(null);
      }
    }
  });
});