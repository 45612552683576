define('ember-components/components/facility-setup-components/footer/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    constants: Ember.inject.service(),
    saveIsActive: true,

    currentStep: Ember.computed.readOnly('model.step'),

    isFirstStep: Ember.computed('currentStep', function () {
      return this.get('currentStep') === this.get('constants.FACILITY_EDITOR_STEPS.ONE');
    }),
    isTwoStep: Ember.computed('currentStep', function () {
      return this.get('currentStep') === this.get('constants.FACILITY_EDITOR_STEPS.TWO');
    }),
    isThreeStep: Ember.computed('currentStep', function () {
      return this.get('currentStep') === this.get('constants.FACILITY_EDITOR_STEPS.THREE');
    }),
    isLastStep: Ember.computed('currentStep', function () {
      return this.get('currentStep') === this.get('constants.FACILITY_EDITOR_STEPS.FOUR');
    }),

    isEmptyFacilityListings: Ember.computed('model.facility_listings.length', function () {
      var listingsLength = this.get('model.facility_listings.length');
      return listingsLength === 0;
    }),

    actions: {
      back: function back() {
        this.sendAction('back');
      },
      next: function next() {
        this.sendAction('next');
      },
      save: function save() {
        this.sendAction('save');
      },
      preview: function preview() {
        this.sendAction('preview');
      },
      setUpLater: function setUpLater() {
        this.sendAction('next', { withoutCardValidate: true });
      },
      publish: function publish() {
        this.sendAction('publish');
      },
      cancel: function cancel() {
        this.sendAction('cancel');
      }
    }
  });
});