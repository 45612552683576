define('ember-components/components/interactive/sa-panel/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = Ember.Component.extend({
    classNameBindings: ['panel', 'customClass'],

    headerChildBinding: computed.reads('viewModel.params.header.child'),
    customClassBinding: computed.reads('viewModel.params.customClass'),
    titleBinding: computed.reads('viewModel.params.header.title'),

    customClass: computed('customClassBinding', function () {
      return this.getWithDefault('customClassBinding', 'panel-default');
    })

  });
});