define('ember-components/serializers/amendment', ['exports', 'ember-data', 'ember-components/serializers/drf'], function (exports, _emberData, _drf) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var EmbeddedRecordsMixin = _emberData.default.EmbeddedRecordsMixin;
  exports.default = _drf.default.extend(EmbeddedRecordsMixin, {
    attrs: {
      persistent_services: { embedded: 'always' },
      engagement_data: { embedded: 'always' },
      changed_fields: { embedded: 'always' },
      changed_persistent_services: { embedded: 'always' }
    },

    normalizeFindRecordResponse: function normalizeFindRecordResponse(store, primaryModelClass, payload) {
      payload.amendment.changed_fields.id = payload.amendment.id;
      payload.amendment.changed_persistent_services.id = payload.amendment.id;
      return this._super.apply(this, arguments);
    },
    normalizeQueryResponse: function normalizeQueryResponse(store, primaryModelClass, payload) {
      payload.amendment.forEach(function (item) {
        item.changed_fields.id = item.id;
        item.changed_persistent_services.id = item.id;
      });
      return this._super.apply(this, arguments);
    }
  });
});