define('ember-components/components/presenter/notice-line/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['notice-line'],
    value: Ember.computed.reads('viewModel.params.value')

  });
});