define('ember-components/components/listing-details/availability-block/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      computed = Ember.computed,
      service = Ember.inject.service;
  exports.default = Component.extend({
    store: service(),

    /**
     * Proxy property to check if storage is available
     */
    isAvailaible: computed('store.storageAvailability', 'storageType', 'storageMethod', 'mode', {
      get: function get() {
        var storageType = this.get('storageType');
        return this.get('store.storageAvailability.is_available_' + storageType) && !(this.get('mode') === 'preview') && !(this.get('mode') === 'profile');
      }
    }),

    isEditorMode: computed('mode', function () {
      var mode = this.get('mode');

      return mode === 'preview';
    }),

    messageNo: computed('isProfile', 'isPreview', function () {
      var isProfile = this.get('isProfile');
      var isPreview = this.get('isPreview');

      if (isProfile || isPreview) {
        return '-';
      }
      return 'NO';
    }),

    getStorageType: computed(function () {
      if (window.location.href.slice(window.location.href.indexOf('st_type'))) {
        return window.location.href.slice(window.location.href.indexOf('st_type')).split('=')[1];
      }
    }),

    messageSpaceAvaliable: computed('isProfile', 'isPreview', function () {
      var isProfile = this.get('isProfile');
      var isPreview = this.get('isPreview');

      if (isProfile || isPreview) {
        return '';
      }
      return 'Space Avaliable';
    }),

    /**
     * Proxy property for getting storage rate
     */
    storageRate: computed('store.storageAvailability', 'storageType', 'getStorageType', {
      get: function get() {
        var storageType = this.get('storageType');
        var storageMethod = this.get('getStorageType') || 'f';
        if (storageMethod === 'p') {
          return this.get('store.storageAvailability.' + storageType + '_pallets_rate');
        } else if (storageMethod === 'f') {
          return this.get('store.storageAvailability.' + storageType + '_feet_rate');
        }
        return null;
      }
    }),

    /**
     * Proxy property for getting storage space
     */
    storageSpace: computed('store.storageAvailability', 'storageType', 'storageMethod', {
      get: function get() {
        var storageType = this.get('storageType');
        var storageMethod = this.get('storageMethod');

        if (storageMethod === 'p') {
          return this.get('store.storageAvailability.' + storageType + '_feet_amount');
        } else if (storageMethod === 'f') {
          return this.get('store.storageAvailability.' + storageType + '_feet_amount');
        }
        return null;
      }
    })
  });
});