define('ember-components/components/presenter/engagement-dashboard/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    overlay: Ember.inject.service(),
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    notify: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    api: Ember.inject.service(),
    isActive: Ember.computed.alias('model.engagement.isActiveEngagement'),
    isSendMessageModalOpen: false,
    isSupplier: Ember.computed.equal('type', 'supplier'),
    isBuyer: Ember.computed.equal('type', 'buyer'),
    engagement: Ember.computed.alias('model.engagement'),
    amendment: Ember.computed.alias('model.amendment'),
    changedFields: Ember.computed.alias('model.amendment.changed_fields'),
    changedPersistentServices: Ember.computed.alias('model.amendment.changed_persistent_services'),
    facility: Ember.computed.alias('engagement.facility'),
    showRates: Ember.computed('amendment', 'engagement.substatus', 'engagement.isOnReview', 'type', function () {
      var type = this.get('type');
      var amendment = this.get('amendment');
      var substatus = this.get('engagement.substatus');
      var isOnReview = this.get('engagement.isOnReview');

      return type === 'buyer' && amendment === undefined && substatus === 'upcoming' && isOnReview;
    }),

    addedPersistentServicesString: Ember.computed('changedPersistentServices', function () {
      var array = this.get('changedPersistentServices.added');
      return array.filter(function (item) {
        return item != null;
      }).join(', ');
    }),

    deletedPersistentServicesString: Ember.computed('changedPersistentServices', function () {
      var array = this.get('changedPersistentServices.deleted');
      return array.filter(function (item) {
        return item != null;
      }).join(', ');
    }),

    isMoveOutShown: Ember.computed('engagement.termination_id', function () {
      var hasSubmittedTermination = Boolean(this.get('engagement.termination_id'));
      return hasSubmittedTermination;
    }),

    isNotRequested: Ember.computed('engagement.status', function () {
      return this.get('engagement.status') !== 'buyer_requested';
    }),

    actions: {
      sendMessage: function sendMessage(facility, callback) {
        var _this = this;
        var message = this.get('message');

        message = message.trim();
        if (!message) {
          this.set('error', 'Message cannot be empty');
          return;
        }

        var msg = {
          text: message,
          facility: facility
        };

        this.get('api').messageAdmin(msg).then(callback).catch(function () {
          _this.set('error', 'You cannot post new messages in this conversation');
        });
      },
      cancelEngagement: function cancelEngagement() {
        this.sendAction('cancelEngagement');
      },
      signBySupplier: function signBySupplier() {
        this.sendAction('signBySupplier');
      },
      onEndEngagementComplete: function onEndEngagementComplete() {
        this.sendAction('onEndEngagementComplete');
      }
    }
  });
});