define('ember-components/components/new-listing-details/photo-gallery/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',
    allImages: [],
    selectedImage: null,
    currentImageIndex: Ember.computed('selectedImage', function () {
      var selectedImage = this.get('selectedImage');
      if (selectedImage) {
        return selectedImage.id;
      }
      return null;
    }),

    mainImage: Ember.computed('allImages.[]', function () {
      return this.get('allImages').get('firstObject');
    }),

    mainImageCSS: Ember.computed('mainImage', function () {
      var url = this.get('mainImage.file');
      if (!url) {
        return Ember.String.htmlSafe('');
      }
      return Ember.String.htmlSafe('background-image: url(' + url + ')');
    }),

    imagesCount: Ember.computed('allImages.[]', function () {
      return this.get('allImages.length');
    }),

    didInsertElement: function didInsertElement() {
      var _this = this;

      var images = this.get('photos');
      var mapImages = images.map(function (image, index) {
        return {
          id: index,
          file: image.get('file'),
          name: _this.getImageName(image.get('name'), index)
        };
      });

      this.set('allImages', mapImages);
    },
    setSelectedImage: function setSelectedImage(index) {
      var _this2 = this;

      var image = this.get('allImages.' + index);

      // This is necessary to reset previous image.
      this.set('selectedImage', -1);
      setTimeout(function () {
        _this2.set('selectedImage', image);
      }, 0);
    },
    getImageName: function getImageName(imageName) {
      var index = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;

      return 'warehouse image ' + (index + 1);
    },


    actions: {
      openImagePopup: function openImagePopup(index) {
        this.setSelectedImage(index);
      },
      prevImage: function prevImage() {
        var nextIndex = this.get('currentImageIndex') - 1;
        if (nextIndex < 0) {
          this.setSelectedImage(this.get('imagesCount') - 1);
        } else {
          this.setSelectedImage(nextIndex);
        }
      },
      nextImage: function nextImage() {
        var nextIndex = this.get('currentImageIndex') + 1;
        if (nextIndex >= this.get('imagesCount')) {
          this.setSelectedImage(0);
        } else {
          this.setSelectedImage(nextIndex);
        }
      },
      selectImage: function selectImage(index) {
        if (index >= this.get('imagesCount') || index < 0) {
          this.setSelectedImage(0);
        } else {
          this.setSelectedImage(index);
        }
      },
      closeImagePopup: function closeImagePopup() {
        this.set('selectedImage', null);
      }
    }
  });
});