define('ember-components/errors/401/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller,
      computed = Ember.computed;
  exports.default = Controller.extend({
    redirectPath: computed('', function () {
      return window.location.pathname;
    })
  });
});