define('ember-components/helpers/display-uom', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.extend({
    compute: function compute(params) {
      var uoms = {
        p: 'pallet',
        f: 'square feet'
      };

      return uoms[params[0]];
    }
  });
});