define('ember-components/models/photo', ['exports', 'ember-data', 'ember-cp-validations'], function (exports, _emberData, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _emberCpValidations.buildValidations)({
    file: (0, _emberCpValidations.validator)('presence', true),
    localFileName: (0, _emberCpValidations.validator)('presence', true)
  });

  var Model = _emberData.default.Model,
      attr = _emberData.default.attr;
  exports.default = Model.extend(Validations, {
    file: attr(),
    order: attr('number'),
    name: attr('string'),
    created: attr('string'),

    facility: _emberData.default.belongsTo('facility')
  });
});