define('ember-components/services/password-validation', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Service = Ember.Service;
  exports.default = Service.extend({

    /**
     * Check symbol is upper case
     *
     * @method _isUpperCase
     * @param {char} c
     * @return {boolean}
     * @private
     */
    _isUpperCase: function _isUpperCase(c) {
      var letter = /[a-zA-Z]/;
      return c.match(letter) && c === c.toUpperCase();
    },


    /**
     * Check symbol is lower case
     *
     * @method _isLowerCase
     * @param {char} c
     * @return {boolean}
     * @private
     */
    _isLowerCase: function _isLowerCase(c) {
      var letter = /[a-zA-Z]/;
      return c.match(letter) && c === c.toLowerCase();
    },


    /**
     * Check symbol is digit
     *
     * @method _isDigit
     * @param {char} c
     * @return {boolean}
     * @private
     */
    _isDigit: function _isDigit(c) {
      var digit = /[0-9]/;
      return c.match(digit);
    },


    /**
     * Check symbol is special character from list ["!", "@", "$", "%", "^", "&", "*"]
     *
     * @method _isSpecialChar
     * @param {char} c
     * @return {boolean}
     * @private
     */
    _isSpecialChar: function _isSpecialChar(c) {
      var specialChar = /[!@$%^&*]/;
      return c.match(specialChar);
    },


    /**
     * A walker which return true if at least one match found in string
     *
     * @method _walker
     * @param {function} func
     * @param {string} value
     * @return {boolean}
     * @private
     */
    _walker: function _walker(func, value) {
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = value[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var c = _step.value;

          if (func(c)) {
            return true;
          }
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      return false;
    },


    /**
     * Validate password to accordance of rules enumerated above
     *
     * @method validatePassword
     * @param {string} value password
     * @return {Array}
     * @public
     */
    validatePassword: function validatePassword(value) {
      var errorList = [];

      if (!value) {
        errorList.push('Should not be empty');
      } else {
        if (value.length < 8) {
          errorList.push('Password length should be more than 8');
        }
        if (!this._walker(this._isUpperCase, value)) {
          errorList.push('Your password should contain at least 1 character in upper case');
        }
        if (!this._walker(this._isLowerCase, value)) {
          errorList.push('Your password should contain at least 1 character in lower case');
        }
        if (!this._walker(this._isDigit, value)) {
          errorList.push('Your password should contain at least 1 digit');
        }
        if (!this._walker(this._isSpecialChar, value)) {
          errorList.push('Your password should contain at least 1 special char (!, @, #, $, %, ^, &, *)');
        }
      }

      return errorList;
    }
  });
});