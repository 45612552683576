define('ember-components/components/date-range-picker/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      computed = Ember.computed;

  var DateStringFormat = 'YYYY-MM-DD';

  exports.default = Component.extend({
    overlay: Ember.inject.service(),

    _datePicker: null,

    disabled: false,

    isOpened: false,

    isShowLabel: true,

    dropDownedClass: computed('dropdowned', function () {
      var dropdowned = this.get('dropdowned');
      var classes = dropdowned ? 'btn-parent-daterange2 dropdown-toggle' : '';
      return 'form-control large ' + classes;
    }),

    /**
     * Class names for ``ember-view`` wrapper.
     *
     * @type {Array}
     * @public
     */
    classNames: ['form-group', 'has-feedback', 'ico-left'],

    /**
     * Initialize jQuery daterangepicker component.
     *
     * @method didInsertElement
     * @return {void}
     * @public
     */
    didInsertElement: function didInsertElement() {
      var _this = this;

      var self = this;
      var el = $(this.$('[data-role="daterangepicker"]')).daterangepicker({
        autoApply: true,
        autoUpdateInput: true,
        isCustomDate: function isCustomDate(date) {
          // Make custom class as date to be able to use it in query selector.
          return date.format(DateStringFormat);
        },
        isInvalidDate: function isInvalidDate(date) {
          if (self.get('available-dates')) {
            return self.get('available-dates').filter(function (item) {
              return item.diff(date, 'seconds') === 0;
            }).length === 0;
          }
        },

        startDate: this.get('startDate'),
        endDate: this.get('endDate'),
        minDate: this.get('minDate'),
        maxDate: this.get('maxDate')
      }, function (start, end) {
        self.set('startDate', start);
        self.set('endDate', end);
      });

      this._datePicker = el.data('daterangepicker');

      $('[data-role="daterangepicker"]').on('hide.daterangepicker', function () {
        var overlaySearch = _this.get('overlay.search');
        overlaySearch.hide();
      });

      if (this.get('week-selection')) {
        this._setWeekSelection();
      }
      if (!this.get('startDate')) {
        this.set('startDate', moment.utc().add(1, 'day'));
      }
      if (!this.get('endDate')) {
        this.set('endDate', moment.utc().add(2, 'day'));
      }
    },


    /**
     * Fix internal component behavior to select only weeks.
     *
     * @method
     * @private
     * @return {void}
     */
    _setWeekSelection: function _setWeekSelection() {
      // Override default clickDate behavior to select start or end of week.
      this._datePicker.container.find('.calendar').off('mousedown.daterangepicker');
      this._datePicker.container.find('.calendar').on('mousedown.daterangepicker', 'td.available', $.proxy(this._weekClickDate, this._datePicker, this));

      // Override default hoverDate behavior to highlight whole week.
      this._datePicker.container.find('.calendar').off('mouseenter.daterangepicker');
      this._datePicker.container.find('.calendar').on('mouseenter.daterangepicker', 'td.available', $.proxy(this._weekHoverDate, this._datePicker, this));

      // Make input read only to do not allow set not week align range.
      this.$('[data-role="daterangepicker"]').prop('readonly', true);
    },
    _weekClickDate: function _weekClickDate(self, e) {
      self._datePicker.clickDate.call(this, e);
      if (!$(e.target).hasClass('available')) {
        return;
      }
      if (this.startDate) {
        this.setStartDate(this.startDate.startOf('week'));
      }
      if (this.endDate) {
        this.setEndDate(this.endDate.endOf('week'));
      }
      this.callback(this.startDate, this.endDate, this.chosenLabel);

      this.updateView();
    },
    _weekHoverDate: function _weekHoverDate(self, e) {
      self._datePicker.hoverDate.call(this, e); // virtual method call
      if (!$(e.target).hasClass('available') || !this.endDate) {
        return;
      }
      var title = $(e.target).attr('data-title'),
          row = title.substr(1, 1),
          col = title.substr(3, 1),
          cal = $(e.target).parents('.calendar'),
          date = cal.hasClass('left') ? this.leftCalendar.calendar[row][col] : this.rightCalendar.calendar[row][col],
          originalDate = date.clone();

      // Reset whole calendar.
      this.container.find('.calendar tbody td').each(function (index, el) {
        $(el).removeClass('daterangepicker-dt-bg-grey');
      });
      // Highlight whole week.
      var startOfWeek = originalDate.clone().startOf('week');
      var currentDate = originalDate.clone().startOf('day');
      while (startOfWeek.diff(currentDate, 'seconds') !== 0) {
        currentDate.add(-1, 'day');
        this.container.find('td.' + currentDate.format(DateStringFormat)).addClass('daterangepicker-dt-bg-grey');
      }
      var endOfWeek = originalDate.clone().endOf('week').add(-1, 'day');
      currentDate = originalDate.clone().startOf('day');
      while (endOfWeek.diff(currentDate, 'seconds') !== 0) {
        currentDate.add(1, 'day');
        this.container.find('td.' + currentDate.format(DateStringFormat)).addClass('daterangepicker-dt-bg-grey');
      }
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      // Allow to have available-dates as JSON string: ["2017-05-15", "2017-05-16", "2017-05-17"].
      var availableDatesString = this.get('available-dates');
      if (typeof availableDatesString === 'string') {
        this.set('available-dates', JSON.parse(availableDatesString));
        this.set('available-dates', this.get('available-dates').map(function (date) {
          return moment.utc(date);
        }));
      }
    },


    actions: {
      clickCaret: function clickCaret() {
        var overlaySearch = this.get('overlay.search');
        if (!overlaySearch.instance.get('ownerOpen')) {
          overlaySearch.instance.set('ownerOpen', 1);
          $('.btn-parent-daterange2').click();
        } else {
          overlaySearch.instance.set('ownerOpen', 0);
          $('.btn-parent-daterange2').blur();
        }
      }
    }

  });
});