define('ember-components/adapters/invoice', ['exports', 'ember-components/adapters/application', 'ember-components/config/environment'], function (exports, _application, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    namespace: '' + _environment.default.APP.API_NAMESPACE,
    addTrailingSlashes: true,

    pathForType: function pathForType() {
      return 'invoices';
    },
    urlForCreateRecord: function urlForCreateRecord() {
      return this._super.apply(this, arguments) + '/';
    },
    urlForQuery: function urlForQuery() {
      return this._super.apply(this, arguments) + '/';
    },
    urlForFindRecord: function urlForFindRecord(id, modelName, snapshot) {
      this.set('addTrailingSlashes', false);
      var url = this._super(id, modelName, snapshot);
      if (snapshot && snapshot.adapterOptions && snapshot.adapterOptions.type) {
        var _type = snapshot.adapterOptions.type;
        return url + '/?type=' + _type;
      }
      var type = 'supplier';
      return url + '/?type=' + type;
    },
    urlForUpdateRecord: function urlForUpdateRecord(id, modelName, snapshot) {
      this.set('addTrailingSlashes', true);
      var url = '';
      if (snapshot && snapshot.adapterOptions.postFix) {
        this.set('addTrailingSlashes', false);
        var _snapshot$adapterOpti = snapshot.adapterOptions,
            postFix = _snapshot$adapterOpti.postFix,
            type = _snapshot$adapterOpti.type;

        url = this._super.apply(this, arguments) + '/' + postFix + '/?type=' + type;
      } else {
        url = this._super.apply(this, arguments) + '/';
      }
      return url;
    }
  });
});