define('ember-components/validators/image-format', ['exports', 'ember-cp-validations/validators/base'], function (exports, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var ImageFormat = _base.default.extend({
    validate: function validate(value) {
      if (value) {
        if (['jpg', 'jpeg', 'png', 'bmp', 'gif'].includes(value.name.split('.').pop())) {
          return true;
        }
        return 'There is unallowed extension. Allowed are JPG, PNG, BMP or GIF';
      }
      return true;
    }
  });

  exports.default = ImageFormat;
});