define('ember-components/engagements-table/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route,
      service = Ember.inject.service,
      get = Ember.get,
      RSVP = Ember.RSVP;
  exports.default = Route.extend({
    titleToken: 'Engagements',

    queryParams: {
      tab: { refreshModel: true }
    },

    currentUser: service(),
    store: service(),

    afterModel: function afterModel() {
      var company = get(this, 'currentUser.user');
      if (!company) {
        this.transitionTo('errors/403');
      }
    },
    model: function model(params) {
      return RSVP.hash({
        counters: get(this, 'store').queryRecord('engagement-stat', {
          type: params.user_type,
          read: false
        }),
        userType: params.user_type
      });
    }
  });
});