define('ember-components/mixins/storage-hub-route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    afterModel: function afterModel(model) {
      var storageHub = this.get('storageHub');
      var facility = model.facility;
      storageHub.set('facility', facility);
    }
  });
});