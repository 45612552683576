define('ember-components/models/coordinator', ['exports', 'ember-components/models/obj-hash', 'ember-drag-drop/utils/proxy-unproxy-objects'], function (exports, _objHash, _proxyUnproxyObjects) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Object.extend(Ember.Evented, {
    objectMap: Ember.computed(function () {
      return _objHash.default.create();
    }),

    getObject: function getObject(id, ops) {
      ops = ops || {};
      var payload = this.get('objectMap').getObj(id);

      if (payload.ops.source && !payload.ops.source.isDestroying && !payload.ops.source.isDestroyed) {
        payload.ops.source.sendAction('action', payload.obj);
      }

      if (payload.ops.target && !payload.ops.target.isDestroying && !payload.ops.target.isDestroyed) {
        payload.ops.target.sendAction('action', payload.obj);
      }

      this.trigger("objectMoved", { obj: (0, _proxyUnproxyObjects.unwrapper)(payload.obj), source: payload.ops.source, target: ops.target });

      return (0, _proxyUnproxyObjects.unwrapper)(payload.obj);
    },

    setObject: function setObject(obj, ops) {
      ops = ops || {};
      return this.get('objectMap').add({ obj: obj, ops: ops });
    }
  });
});