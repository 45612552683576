define('ember-components/components/info-supplier/finding-success/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      service = Ember.inject.service;
  exports.default = Component.extend({
    session: service(),
    flashMessages: service(),

    actions: {
      becomeSupplier: function becomeSupplier() {
        this.get('flashMessages').warning('To become a Supplier change user type and enter Business Information.', {
          timeout: 10 * 1000
        });
      }
    }
  });
});