define('ember-components/models/changed-fields', ['exports', 'ember-data', 'ember-components/config/environment'], function (exports, _emberData, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr;
  var computed = Ember.computed;
  exports.default = Model.extend({
    STORAGE_TYPES: {
      a: 'Ambient',
      c: 'Cold',
      s: 'Special'
    },

    start: attr({
      previous: 'string',
      next: 'string'
    }),
    end: attr({
      previous: 'string',
      next: 'string'
    }),
    usage: attr({
      previous: 'string',
      next: 'string'
    }),
    storage_type: attr({
      previous: 'string',
      next: 'string'
    }),
    storage_original_rate: attr({
      previous: 'number',
      next: 'number'
    }),
    deposit: attr({
      previous: 'number',
      next: 'number'
    }),
    storage_uom: attr({
      previous: 'string',
      next: 'string'
    }),
    storage_units: attr({
      previous: 'number',
      next: 'number'
    }),

    startFormatted: computed('start', function () {
      return {
        previous: this._formatDate(this.get('start.previous')),
        next: this._formatDate(this.get('start.next'))
      };
    }),

    endFormatted: computed('end', function () {
      return {
        previous: this._formatDate(this.get('end.previous')),
        next: this._formatDate(this.get('end.next'))
      };
    }),

    storageTypeFormatted: computed('storage_type', function () {
      return {
        previous: this._formatStorageType(this.get('storage_type.previous')),
        next: this._formatStorageType(this.get('storage_type.next'))
      };
    }),

    _formatDate: function _formatDate(field) {
      if (field) {
        return moment.utc(field).format(_environment.default.date.engagementFormat);
      }
      return '-';
    },
    _formatStorageType: function _formatStorageType(field) {
      return this.STORAGE_TYPES[field];
    }
  });
});