define('ember-components/mixins/default-payment-property', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = Ember.Mixin.create({
    filteredData: computed.filterBy('cards', 'default', true),
    filteredACHData: computed.filterBy('bankAccounts', 'default', true),
    defaultCard: computed('filteredData', 'filteredACHData', function () {
      if (this.get('filteredData').length) {
        return 'card' + this.get('filteredData')[0].get('id');
      }
      if (this.get('filteredACHData').length) {
        return 'ach' + this.get('filteredACHData')[0].get('id');
      }
    })
  });
});