define('ember-components/models/alert', ['exports', 'ember-data', 'ember-api-actions'], function (exports, _emberData, _emberApiActions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;
  exports.default = Model.extend({
    user: belongsTo('user'),
    engagement: belongsTo('engagement'),
    title: attr('string'),
    text: attr('string'),
    visible_for: attr('string'),
    type: attr('string', { defaultValue: 'pr1' }),

    readAlert: (0, _emberApiActions.memberAction)({
      path: 'read/',
      type: 'post'
    })
  });
});