define('ember-components/config-provider/service', ['exports', 'ember-components/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = Ember.Service.extend({
    config: _environment.default,

    dateFormat: computed.reads('config.date.defaultFormat'),
    dateSlashesFormat: computed.reads('config.date.slashesFormat'),

    messageTimer: computed.reads('config.messageHub.timer')

  });
});