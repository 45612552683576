define('ember-components/mixins/redirect-to-home', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Mixin = Ember.Mixin;
  exports.default = Mixin.create({
    actions: {

      /**
       * Redirects to home after 1 second
       *
       * @method redirectToHome
       * @return {void}
       * @public
       */
      redirectToHome: function redirectToHome() {
        this.sendAction('redirectToHome');
      }
    }
  });
});