define('ember-components/models/invoice-storage', ['exports', 'ember-data', 'ember-cp-validations', 'ember-api-actions'], function (exports, _emberData, _emberCpValidations, _emberApiActions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _emberCpValidations.buildValidations)({
    description: (0, _emberCpValidations.validator)('presence', true),
    rate: (0, _emberCpValidations.validator)('presence', true),
    quantity: (0, _emberCpValidations.validator)('presence', true),
    sub_total: (0, _emberCpValidations.validator)('presence', true),
    prorated_quantity: (0, _emberCpValidations.validator)('number', {
      disabled: Ember.computed.not('model.is_prorated'),
      allowString: true,
      integer: true,
      gte: 1,
      lte: 30,
      message: 'Please enter the number of days from {gte} to {lte}.'
    })
  });

  var attr = _emberData.default.attr;
  exports.default = _emberData.default.Model.extend(Validations, {
    description: attr('string'),
    rate: attr('number'),
    quantity: attr('number'),
    sub_total: attr('number'),
    invoice: attr('number'),
    is_prorated: attr('boolean'),
    prorated_quantity: attr('number'),
    prorated_rate: attr('number'),
    prorated_sub_total: attr('number'),
    proratedDaysErrors: Ember.computed.alias('validations.attrs.prorated_quantity'),

    getInvoicePrecalculate: (0, _emberApiActions.memberAction)({
      path: '../precalculate/',
      type: 'post'
    }),

    getNewInvoicePrecalculate: (0, _emberApiActions.memberAction)({
      path: 'precalculate/',
      type: 'post'
    })
  });
});