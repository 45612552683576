define('ember-components/components/interactive/modals/checkout-completed-modal/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    actions: {
      submit: function submit() {
        var _this = this;

        this.sendAction('handlerSubmit', function () {
          _this.set('open', false);
        });
      },
      close: function close() {
        this.set('open', false);
      }
    }
  });
});