define('ember-components/messages/chat/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;


  function sorting(a, b) {
    var aid = Number(a.get('id'));
    var bid = Number(b.get('id'));
    if (aid > bid) {
      return 1;
    } else if (aid < bid) {
      return -1;
    }
    return 0;
  }

  exports.default = Ember.Controller.extend({
    unreadCount: computed.reads('messagesHub.unreadCount'),
    sortedMessages: computed.sort('model.messages', sorting)

  });
});