define('ember-components/components/facility-form/service-pricing/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    _countServices: function _countServices(facility, services) {
      var count = services.reduce(function (total, service) {
        return facility.get(service) ? total + 1 : total;
      }, 0);
      return count;
    },


    inboundServicesCount: Ember.computed('model.facility.service_pallet_receiving', 'model.facility.service_carton_receiving', function () {
      var facility = this.get('model.facility'),
          services = ['service_pallet_receiving', 'service_carton_receiving'];
      return this._countServices(facility, services);
    }),

    outboundServicesCount: Ember.computed('model.facility.service_carton_pick', 'model.facility.service_pallet_pick', 'model.facility.service_unit_pack', 'model.facility.service_unit_pick', function () {
      var facility = this.get('model.facility'),
          services = ['service_carton_pick', 'service_pallet_pick', 'service_unit_pack', 'service_unit_pick'];
      return this._countServices(facility, services);
    }),

    valueAddServicesCount: Ember.computed('model.facility.additional_cross_dock', 'model.facility.additional_carton_kitting', 'model.facility.additional_carton_labeling', 'model.facility.additional_price_labeling', 'model.facility.additional_price_ticketing', 'model.facility.additional_repacking', 'model.facility.additional_ship_labeling', function () {
      var facility = this.get('model.facility'),
          services = ['additional_cross_dock', 'additional_carton_kitting', 'additional_carton_labeling', 'additional_price_labeling', 'additional_price_ticketing', 'additional_repacking', 'additional_ship_labeling'];
      return this._countServices(facility, services);
    }),

    computedServiceUnitPick: Ember.computed('model.facility.service_unit_pick', 'model.facility.service_unit_pack', {
      get: function get(key) {
        var unitPick = this.get('model.facility.service_unit_pick');
        return unitPick;
      },
      set: function set(key, value) {
        if (!value) {
          this.set('model.facility.service_unit_pack', false);
        }
        this.set('model.facility.service_unit_pick', value);
        return value;
      }
    })
  });
});