define('ember-components/mixins/routes/scroll-to-top', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    activate: function activate() {
      if (window.scrollTo) {
        window.scrollTo(0, 0);
      }
      return true;
    }
  });
});