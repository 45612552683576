define('ember-components/pages/messages/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  exports.default = Controller.extend({
    messageList: fakeMessageList(),
    chatList: fakeChatList()
  });


  function fakeMessageList() {
    var items = [{
      name: 'IW-123456789',
      company: 'Warehouse1',
      date: 'Mar 12',
      mod: 'active'
    }];

    for (var i = 0; i < 12; i += 1) {
      items.push({
        name: 'TU-123456789',
        company: 'Warehouse2',
        date: 'Feb 10'
      });
    }

    return items;
  }

  function fakeChatList() {
    var items = [];

    for (var i = 0; i < 4; i += 1) {
      items.push({
        name: 'Username1234',
        message: 'Hi. I have a question about my engagement. ',
        isMy: 'isIm'
      }, {
        name: 'Saritasa (Irvine Facility)',
        message: 'Hi- yes, I can help you.',
        isMy: ''
      }, {
        name: 'Saritasa (Irvine Facility)',
        message: 'What’s your question?',
        isMy: ''
      }, {
        name: 'Saritasa (Irvine Facility)',
        message: 'Hi, are you still there?',
        isMy: ''
      }, {
        name: 'Saritasa (Irvine Facility)',
        message: 'We just spoke with “Bob from Accounting” on the phone. I think most of your questions have been cleared.',
        isMy: ''
      }, {
        name: 'Saritasa (Irvine Facility)',
        message: 'We just spoke with “Bob from Accounting” on the phone. I think most of your questions have been cleared. We just spoke with “Bob from Accounting” on the phone. I think most of your questions have been cleared. We just spoke with “Bob from Accounting” on the phone. I think most of your questions have been cleared. We just spoke with “Bob from Accounting” on the phone. I think most of your questions have been cleared.',
        isMy: ''
      });
    }
    return items;
  }
});