define('ember-components/mixins/validators/facility-model', ['exports', 'ember-cp-validations'], function (exports, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  // Whether rules field validation is disabled or not.
  var rulesValidationDisabled = Ember.computed('model.rule_type', function () {
    return this.get('model.rule_type') !== 'custom';
  });

  exports.default = (0, _emberCpValidations.buildValidations)({
    stepOne: (0, _emberCpValidations.validator)('dependent', {
      on: ['address', 'city', 'zip', 'storage_ambient_available', 'min_order_quantity', 'storage_ambient_rate', 'selectedAvailableDays', 'availableTimeRange', 'selectedIndustriesServed', 'service_labor_rate', 'receiving_doors', 'shipping_doors', 'max_doors_height', 'max_storage_height', 'max_storage_weight']
    }),

    stepTwo: (0, _emberCpValidations.validator)('dependent', {
      on: ['nickname', 'size', 'insurance', 'payment_terms', 'payment_frequency', 'photos', 'rules']
    }),

    // Step one
    address: (0, _emberCpValidations.validator)('presence', true),
    city: (0, _emberCpValidations.validator)('presence', true),
    zip: (0, _emberCpValidations.validator)('presence', true),

    storage_ambient_available: (0, _emberCpValidations.validator)('number', {
      allowString: true,
      integer: true,
      gt: 0
    }),
    min_order_quantity: (0, _emberCpValidations.validator)('number', {
      allowString: true,
      integer: true,
      gt: 0
    }),
    storage_ambient_rate: (0, _emberCpValidations.validator)('number', {
      allowString: true,
      integer: false,
      gt: 0
    }),
    service_labor_rate: (0, _emberCpValidations.validator)('number', {
      allowBlank: true,
      allowString: true,
      integer: false,
      positive: true
    }),
    receiving_doors: (0, _emberCpValidations.validator)('number', {
      allowBlank: true,
      allowString: true,
      integer: true,
      positive: true
    }),
    shipping_doors: (0, _emberCpValidations.validator)('number', {
      allowBlank: true,
      allowString: true,
      integer: false,
      positive: true
    }),
    max_doors_height: (0, _emberCpValidations.validator)('number', {
      allowBlank: true,
      allowString: true,
      integer: false,
      positive: true
    }),
    max_storage_weight: (0, _emberCpValidations.validator)('number', {
      allowBlank: true,
      allowString: true,
      integer: false,
      positive: true
    }),
    max_storage_height: (0, _emberCpValidations.validator)('number', {
      allowBlank: true,
      allowString: true,
      integer: false,
      positive: true
    }),

    selectedAvailableDays: (0, _emberCpValidations.validator)(function (value) {
      if (!value.length) {
        return 'You have to choose at least one day of week';
      }

      return true;
    }),

    availableTimeRange: (0, _emberCpValidations.validator)(function (value) {
      if (value < 0 || value === 0) {
        return 'End time must be greater than Begin time';
      }

      return true;
    }),

    selectedIndustriesServed: (0, _emberCpValidations.validator)(function (value) {
      if (!value.length) {
        return 'Please select product types.';
      }

      return true;
    }),

    // Step two
    nickname: (0, _emberCpValidations.validator)('presence', true),
    size: (0, _emberCpValidations.validator)('number', {
      allowString: true,
      integer: false,
      gt: 0
    }),

    insurance: (0, _emberCpValidations.validator)('number', {
      allowString: true,
      integer: false,
      gt: 0
    }),
    payment_terms: (0, _emberCpValidations.validator)('presence', true),
    payment_frequency: (0, _emberCpValidations.validator)('presence', true),

    photos: [(0, _emberCpValidations.validator)('length', {
      min: 1,
      message: 'At least one photo have to be uploaded'
    })],

    // TODO: Has many validator not work with 'dependent' validator. Need check manual.
    videos: (0, _emberCpValidations.validator)('has-many'),

    rules: (0, _emberCpValidations.validator)('length', {
      min: 1,
      message: 'Please fill custom facility rules',
      disabled: rulesValidationDisabled
    })
  });
});