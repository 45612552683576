define('ember-components/models/message', ['exports', 'ember-data', 'ember-api-actions', 'ember-components/libs/computed/format-date', 'ember-components/libs/types-shims', 'ember-components/mixins/model-validator'], function (exports, _emberData, _emberApiActions, _formatDate, _typesShims, _modelValidator) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend(_modelValidator.default, {
    sender: _typesShims.string,
    text: _typesShims.string,
    read: _typesShims.boolean,
    created: _typesShims.string,
    receiver: _typesShims.number,
    facility: _typesShims.number,
    conversation: (0, _typesShims.belongsTo)('conversation'),
    normolizedCreated: (0, _formatDate.default)('created', 'MMM DD'),
    unreadCount: (0, _emberApiActions.memberAction)({ path: 'unread-count/', type: 'get' })
  });
});