define('ember-components/helpers/sec-to-days', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.extend({
    compute: function compute(sec) {
      return (Math.floor(sec / 1000 / 3600 / 24) || 0) + 1;
    }
  });
});