define('ember-components/models/facility-listing', ['exports', 'ember-data', 'moment', 'ember-cp-validations'], function (exports, _emberData, _moment, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _emberCpValidations.buildValidations)({
    storage_ambient_available: (0, _emberCpValidations.validator)('number', {
      allowString: true,
      integer: false,
      gt: 0
    }),
    storage_ambient_rate: (0, _emberCpValidations.validator)('number', {
      allowString: true,
      integer: false,
      gt: 0
    }),
    dateRange: (0, _emberCpValidations.validator)(function (value) {
      if (value < 6) {
        return 'End date must be greater than Begin Date for at least a week';
      }

      return true;
    })
  });

  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;
  exports.default = Model.extend(Validations, {
    facility: belongsTo('facility'),
    start: attr('string'),
    end: attr('string'),
    storage_ambient_available: attr('number'),
    storage_ambient_rate: attr('number'),
    storage_ambient_uom: attr('string', { defaultValue: 'f' }),

    dateRange: Ember.computed('start', 'end', function () {
      var start = _moment.default.utc(this.get('start'), 'YYYY-MM-DD');
      var end = _moment.default.utc(this.get('end'), 'YYYY-MM-DD');

      return end.diff(start, 'days', true);
    }),

    storage_ambient_uom_changed: Ember.observer('storage_ambient_uom', function () {
      this.safeStorageUomSync(this.get('storage_ambient_uom'));
    }),

    safeStorageUomSync: function safeStorageUomSync(uom) {
      this.set('storage_ambient_uom', uom);
    }
  });
});