define('ember-components/components/dashboard-counter/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      computed = Ember.computed,
      service = Ember.inject.service;
  exports.default = Component.extend({
    store: service(),
    currentUser: service(),
    pendingNotificationsCount: computed('model.engagementStats', function () {
      return this.get('model.engagementStats.supplier_signed') + this.get('model.engagementStats.buyer_signed') + this.get('model.engagementStats.buyer_requested');
    }),
    upcomingNotificationsCount: computed('model.engagementStats', function () {
      return this.get('model.engagementStats.upcoming');
    }),
    currentNotificationsCount: computed('model.engagementStats', function () {
      return this.get('model.engagementStats.current');
    })
  });
});