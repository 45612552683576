define('ember-components/current-route/service', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var getOwner = Ember.getOwner;
  exports.default = Ember.Service.extend({
    getRoute: function getRoute() {
      var appController = getOwner(this).lookup('controller:application');
      var path = appController.get('currentPath');
      var route = getOwner(this).lookup('route:' + path);
      return route;
    },
    getController: function getController() {
      var route = this.getRoute();
      return route.get('controller');
    },
    getCurrentPath: function getCurrentPath() {
      var appController = getOwner(this).lookup('controller:application');
      return appController.get('currentPath');
    }
  });
});