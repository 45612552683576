define('ember-components/models/license-agreement-preview', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr;
  exports.default = Model.extend({
    title: attr('string'),
    content: attr('string'),
    version: attr('number'),
    created: attr('date'),
    url: attr('string'),

    html: Ember.computed.alias('content')
  });
});