define('ember-components/components/interactive/tables/invoice/service-fees/component', ['exports', 'ember-components/libs/columns-definitions/invoices/service-fees/outbound-services', 'ember-components/libs/columns-definitions/invoices/service-fees/inbound-services'], function (exports, _outboundServices, _inboundServices) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    outDefinitions: _outboundServices.default,
    inDefinitions: _inboundServices.default

  });
});