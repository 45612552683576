define('ember-components/components/password-reset-confirm-form/component', ['exports', 'fetch', 'ember-components/config/environment'], function (exports, _fetch, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      get = Ember.get,
      service = Ember.inject.service;
  exports.default = Component.extend({

    store: service(),
    notify: service(),

    didInsertElement: function didInsertElement() {
      $('#passwordResetConfirmModal').modal();
    },

    actions: {
      resetPasswordConfirm: function resetPasswordConfirm(model) {
        var _this = this;

        model.validate().then(function (_ref) {
          var validations = _ref.validations;

          if (validations.get('isValid')) {
            var key = model.get('key');
            var uid = model.get('uid');
            var password1 = model.get('password1');
            var password2 = model.get('password2');
            var passwordResetData = new FormData();
            passwordResetData.append('token', key);
            passwordResetData.append('uid', uid);
            passwordResetData.append('new_password1', password1);
            passwordResetData.append('new_password2', password2);

            (0, _fetch.default)('' + _environment.default.APP.API_HOST + _environment.default.APP.BASE_PATH + '/auth/password/reset/confirm/', {
              method: 'post',
              body: passwordResetData
            }).then(function (data) {
              if (data.status === 200) {
                get(_this, 'notify').success('Password successfully reset');
                _this.$('#passwordResetConfirmModal').modal('hide');
              } else {
                get(_this, 'notify').error('Something went wrong');
              }
            });
          }
        });
      },
      changePasswordVisibility: function changePasswordVisibility(inputName) {
        var input = $('#reset-' + inputName + '-input');
        var button = $('#reset-' + inputName + '-show-button');

        if (input.attr('type') === 'password') {
          input.prop('type', 'text');
          button.addClass('ic__eye_off');
        } else {
          input.prop('type', 'password');
          button.removeClass('ic__eye_off');
        }
      }
    }
  });
});