define('ember-components/components/info-resources/privacy-policy/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',

    contentWithWrap: Ember.computed('content', function () {
      return Ember.String.htmlSafe(this.get('content'));
    })
  });
});