define('ember-components/messages/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = Ember.Controller.extend({
    unreadCount: computed.reads('messagesHub.unreadCount')
  });
});