define('ember-components/serializers/end-engagement', ['exports', 'ember-components/serializers/drf'], function (exports, _drf) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _drf.default.extend({
    serialize: function serialize() {
      var json = this._super.apply(this, arguments);
      json.termination_date = moment.utc(json.termination_date).format('YYYY-MM-DD');

      return json;
    }
  });
});