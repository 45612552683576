define('ember-components/components/new-listing-details/facility-videos/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function isEmbedUrl(url) {
    if (!url) {
      return false;
    }

    return ['matterport.com/show/', 'vimeo.com/video/', 'youtube.com/embed/'].some(function (p) {
      return url.indexOf(p) !== -1;
    });
  }

  exports.default = Ember.Component.extend({
    isPopupOpen: false,
    currentIndex: null,

    videos: Ember.computed.alias('facility.videos'),

    embedVideos: Ember.computed('videos.@each.url', 'defaultVideo', function () {
      var facilityVideos = this.get('videos').map(function (item) {
        return {
          id: item.get('id'),
          url: item.get('url')
        };
      }).filter(function (video) {
        return isEmbedUrl(video.url);
      });

      var defaultVideo = this.get('defaultVideo');
      if (defaultVideo) {
        var mappedDefaultVideo = {
          id: defaultVideo.get('id'),
          url: defaultVideo.get('video'),
          isDefault: true
        };
        return [mappedDefaultVideo].concat(facilityVideos);
      }
      return facilityVideos;
    }),

    embedVideosCount: Ember.computed('embedVideos', function () {
      return this.get('embedVideos.length');
    }),

    otherVideos: Ember.computed.filter('videos.@each.url', function (video) {
      var url = video.get('url');
      return !isEmbedUrl(url);
    }),

    otherVideosCount: Ember.computed('otherVideos.[]', function () {
      return this.get('otherVideos.length');
    }),

    selectedVideo: Ember.computed('embedVideos', 'embedVideosCount', 'currentIndex', function () {
      var index = this.get('currentIndex');
      var length = this.get('embedVideosCount');
      if (index === null || index > length || index < 0) {
        return null;
      }
      var selectedVideo = this.get('embedVideos.' + index);
      return selectedVideo || null;
    }),

    setSelectedVideoIndex: function setSelectedVideoIndex(index) {
      var _this = this;

      // This is necessary to reset previous video.
      this.set('currentIndex', -1);
      setTimeout(function () {
        _this.set('currentIndex', index);
      }, 0);
    },


    actions: {
      openVideoPopup: function openVideoPopup() {
        this.setSelectedVideoIndex(0);
      },
      closeVideoPopup: function closeVideoPopup() {
        this.setSelectedVideoIndex(null);
      },
      prevVideo: function prevVideo() {
        var nextIndex = this.get('currentIndex') - 1;
        if (nextIndex < 0) {
          this.setSelectedVideoIndex(this.get('embedVideosCount') - 1);
        } else {
          this.setSelectedVideoIndex(nextIndex);
        }
      },
      nextVideo: function nextVideo() {
        var nextIndex = this.get('currentIndex') + 1;
        if (nextIndex >= this.get('embedVideosCount')) {
          this.setSelectedVideoIndex(0);
        } else {
          this.setSelectedVideoIndex(nextIndex);
        }
      }
    }
  });
});