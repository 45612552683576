define('ember-components/components/search-page/custom-g-map/component', ['exports', 'ember-components/components/g-map'], function (exports, _gMap) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  exports.default = _gMap.default.extend({
    /**
     * Google maps service.
     *
     * @property googleMaps
     * @type {object}
     * @public
     */
    googleMaps: service(),

    /**
     * Setup markers cluster for map.
     *
     * @method didInsertElement
     * @public
     * @return {void}
     */
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      var self = this;
      var markers = this.get('markers').map(function (marker) {
        return marker.get('marker');
      });

      this._changePoistionForCoincidentMarkers(markers);

      var map = this.get('map');

      var markersCluster = new MarkerClusterer(map, markers, {
        imagePath: '/img/general/ic_location_cluster',
        averageCenter: true
      });
      markersCluster.styles_.forEach(function (style) {
        style.textColor = 'white';
        style.textSize = 14;
      });
      // When we click cluster the map position is changed. The listener below
      // updates lng and lat properties of Ember google map as well.
      var setLngLatWithDelay = function setLngLatWithDelay() {
        setTimeout(function () {
          var updateLatLng = self.get('updateLatLng');
          updateLatLng(map.center.lat(), map.center.lng());
        }, 50);
      };
      markersCluster.addListener('clusterclick', setLngLatWithDelay);
      map.addListener('zoom_changed', setLngLatWithDelay);
      this.set('markersCluster', markersCluster);
      this.set('redoSearch', true);

      var bounds = new google.maps.LatLngBounds();
      markers.forEach(function (marker) {
        var place = new google.maps.LatLng(marker.getPosition().lat(), marker.getPosition().lng());
        bounds.extend(place);
      });
      if (markers.length) {
        map.fitBounds(bounds);
      } else if (this.get('lat') && this.get('lng')) {
        setLngLatWithDelay();
      }
    },
    willDestroyElement: function willDestroyElement() {
      var markersCluster = this.get('markersCluster');
      if (markersCluster) {
        google.maps.event.clearInstanceListeners(markersCluster);
        this.set('markersCluster', null);
      }
      var map = this.get('map');

      if (map) {
        google.maps.event.clearInstanceListeners(map);
        this.set('map', null);
      }
    },
    _changePoistionForCoincidentMarkers: function _changePoistionForCoincidentMarkers(markers) {
      var googleMapsService = this.get('googleMaps');
      for (var i = 0; i < markers.length - 1; i += 1) {
        for (var j = i + 1; j < markers.length; j += 1) {
          googleMapsService.changeCoincidentMarkersPositions(markers[i], markers[j]);
        }
      }
    }
  });
});