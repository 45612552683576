define('ember-components/adapters/license-agreement-preview', ['exports', 'ember-components/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    urlForQueryRecord: function urlForQueryRecord(query) {
      var baseUrl = this.buildURL();
      if (query.amendment) {
        return baseUrl + 'terms/amendment-basic-license/preview/';
      }
      return baseUrl + 'terms/basic-license/preview/';
    }
  });
});