define('ember-components/adapters/customer', ['exports', 'ember-components/adapters/application', 'ember-components/config/environment'], function (exports, _application, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    namespace: _environment.default.APP.API_NAMESPACE + '/payments',

    urlForQueryRecord: function urlForQueryRecord() {
      var url = this._super.apply(this, arguments);
      return url.substr(0, url.length - 1);
    },
    urlForCreateRecord: function urlForCreateRecord() {
      var url = this._super.apply(this, arguments);
      return url.substr(0, url.length - 1);
    },
    urlForUpdateRecord: function urlForUpdateRecord() {
      var url = this._super.apply(this, arguments);
      var index = url.indexOf('customers');
      return url.substr(0, index + 8);
    }
  });
});