define('ember-components/facility-create/step4/controller', ['exports', 'moment', 'ember-components/mixins/default-payment-property'], function (exports, _moment, _defaultPaymentProperty) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  exports.default = Ember.Controller.extend(_defaultPaymentProperty.default, {
    currentUser: service(),

    startDate: _moment.default.utc(),
    endDate: _moment.default.utc(),

    actions: {}
  });
});