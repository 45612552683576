define('ember-components/messages/route', ['exports', 'ember-components/libs/base/route'], function (exports, _route) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _route.default.extend({
    titleToken: 'Message',

    model: function model() {
      return Ember.RSVP.hash({
        conversations: this.store.findAll('conversation')
      });
    },
    afterModel: function afterModel(model, transition) {
      if (transition.targetName === 'messages.index') {
        var conversations = model.conversations;

        if (conversations.get('length') > 0) {
          this.transitionTo('messages.chat', conversations.get('firstObject.id'));
        }
      }
    }
  });
});