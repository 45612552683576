define('ember-components/adapters/photo', ['exports', 'ember-cli-form-data/mixins/form-data-adapter', 'ember-components/adapters/application'], function (exports, _formDataAdapter, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend(_formDataAdapter.default, {
    pathForType: function pathForType() {
      return 'facilities/photos';
    },


    // Workaround for ember-data related issue:
    // https://github.com/funtusov/ember-cli-form-data/issues/30#issuecomment-503610291
    ajaxOptions: function ajaxOptions() {
      var ajaxOptions = this._super.apply(this, arguments);
      delete ajaxOptions.headers['content-type'];

      return ajaxOptions;
    },
    updateRecord: function updateRecord(store, type, snapshot) {
      var _this = this;

      var baseUrl = this.buildURL();
      var data = this.serialize(snapshot, { includeId: true });
      var url = baseUrl + 'facilities/photos/' + data.id + '/';
      var changedAttributes = snapshot.changedAttributes();
      var requestType = 'PUT';

      if (!Object.keys(changedAttributes).length) {
        return data;
      }

      if (!('file' in changedAttributes)) {
        requestType = 'PATCH';
        delete data.file;
      }

      return new Ember.RSVP.Promise(function (resolve, reject) {
        $.ajax({
          type: requestType,
          url: url,
          headers: _this.get('headers'),
          data: data
        }).then(function (responseData) {
          resolve(responseData);
        }, function (error) {
          reject(error);
        });
      });
    }
  });
});