define('ember-components/components/includes/-header/nav-item-user-menu/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      get = Ember.get,
      service = Ember.inject.service;
  exports.default = Component.extend({
    store: service(),
    session: service('session'),
    route: service('-routing'),
    currentUser: service(),
    isMenuOpen: false,

    firstConversation: Ember.computed.reads('messagesHub.firstConversation'),

    actions: {
      toggleMenu: function toggleMenu() {
        $('#menu-list').slideToggle();

        var backdrop = $('.menu-backdrop');

        if (backdrop.hasClass('show')) {
          backdrop.removeClass('show');
        } else {
          backdrop.addClass('show');
        }
      },
      logout: function logout() {
        get(this, 'session').invalidate();
        localStorage.removeItem('isImpersonate');
        get(this, 'route').transitionTo('home');
      }
    }
  });
});