define('ember-components/preferences-buyer/route', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'ember-components/libs/base/route'], function (exports, _authenticatedRouteMixin, _route) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var hash = Ember.RSVP.hash;
  exports.default = _route.default.extend(_authenticatedRouteMixin.default, {
    titleToken: 'Payment Information',

    queryParams: {
      from: { refreshModel: true }
    },

    currentUser: Ember.inject.service(),

    model: function model() {
      var _this = this;

      var companyId = this.get('currentUser.user.buyer_company.id');

      return hash({
        customer: this.store.queryRecord('customer', { me: true }).catch(function () {
          return _this.store.createRecord('customer');
        }),
        card: this.store.createRecord('card'),
        bankAccount: this.store.createRecord('bank-account', {
          default: false
        }),
        cards: this.store.findAll('card').catch(function () {
          return [];
        }),
        bankAccounts: this.store.findAll('bank-account').catch(function () {
          return [];
        }),
        accountFile: this.store.createRecord('account-file'),
        buyerCompanyModel: this.store.findRecord('company', companyId),
        states: this.store.findAll('state')
      });
    }
  });
});