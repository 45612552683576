define('ember-components/models/facility-operator', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr;
  exports.default = _emberData.default.Model.extend({
    first_name: attr('string'),
    last_name: attr('string'),
    date_joined: attr('string'),

    fullName: Ember.computed('first_name', 'last_name', function () {
      var first_name = this.get('first_name');
      var last_name = this.get('last_name');

      return (first_name + ' ' + last_name).trim();
    })
  });
});