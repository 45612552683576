define('ember-components/engagements/buyer/route', ['exports', 'ember-components/libs/base/route'], function (exports, _route) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _route.default.extend({
    model: function model(params) {
      var model = this.modelFor('engagements');
      model.engagement = this.store.findRecord('engagement', params.engagement_id, {
        adapterOptions: { type: 'buyer' }
      });
      model.invoices = this.store.query('invoice', {
        engagement: model.engagement.get('id'),
        type: 'buyer'
      });
      model.reason = this.store.createRecord('reason');

      return Ember.RSVP.hash(model);
    },
    afterModel: function afterModel(model) {
      model.engagement.get('invoices').forEach(function (invoice) {
        return invoice.loadInvoicePdfUrl({ type: 'buyer' });
      });
    }
  });
});