define('ember-components/validators/password-comparison', ['exports', 'ember-cp-validations/validators/base'], function (exports, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var PasswordComparison = _base.default.extend({
    validate: function validate(value, params) {
      return value === params.password ? true : 'Passwords must match';
    }
  });

  exports.default = PasswordComparison;
});