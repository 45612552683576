define('ember-components/facility-order/controller', ['exports', 'ember-components/mixins/default-payment-property'], function (exports, _defaultPaymentProperty) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      service = Ember.inject.service;


  var DATE_FORMAT = 'YYYY-MM-DD';

  var UNIT_PACK = 'service_unit_pack';
  var UNIT_PICK = 'service_unit_pick';
  var CARTON_PICK = 'service_carton_pick';
  var PALLET_PICK = 'service_pallet_pick';
  var PALLET_RECEIVING = 'service_pallet_receiving';
  var CARTON_RECEIVING = 'service_carton_receiving';

  var ENGAGEMENT_UNIT_PACK = 'model.engagement.' + UNIT_PACK;
  var ENGAGEMENT_UNIT_PICK = 'model.engagement.' + UNIT_PICK;
  var ENGAGEMENT_CARTON_PICK = 'model.engagement.' + CARTON_PICK;
  var ENGAGEMENT_PALLET_PICK = 'model.engagement.' + PALLET_PICK;
  var ENGAGEMENT_PALLET_RECEIVING = 'model.engagement.' + PALLET_RECEIVING;
  var ENGAGEMENT_CARTON_RECEIVING = 'model.engagement.' + CARTON_RECEIVING;

  function _setService(serviceFlag, value, _this) {
    _this.set(serviceFlag, value);
    if (!value) {
      _this.set(serviceFlag + '_volume', 0);
    }
    _this.send('calculate');
    return value;
  }

  // constants for storageMethod values
  var UOM__PALLET = 'p';
  var UOM__CUFT = 'f';

  function computedEnable(path) {
    return computed(path, {
      get: function get() {
        return this.get(path);
      },
      set: function set(key, value) {
        return _setService(path, value, this);
      }
    });
  }

  function computedDate(path) {
    var format = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : DATE_FORMAT;

    return computed(path, function () {
      return moment.utc(this.get(path), format);
    });
  }

  function computedMomentFormat(path, format) {
    return computed(path, function () {
      return this.get(path).format(format);
    });
  }

  exports.default = Ember.Controller.extend(_defaultPaymentProperty.default, {
    currentUser: service(),
    notify: service(),
    session: service(),
    isDefaultCardExists: computed('model.cards', 'model.bankAccounts', function () {
      return this.get('model.cards.length') + this.get('model.bankAccounts.length');
    }),
    savedBankAccounts: computed.filterBy('model.bankAccounts', 'isNew', false),
    msgForConfirmModal: 'By requesting an engagement, you must agree to Terms of Service which includes a Non-Circumvention agreement. ',
    openModalPaymentMethod: false,
    cards: computed.reads('model.cards'),
    bankAccounts: computed.reads('model.bankAccounts'),

    queryParams: ['latitude', 'longitude', 'storageMethod', 'storageType', 'volume', 'startDate', 'endDate', 'from', 'stateCode', 'cityCode'],

    volume: 100,
    storageMethod: 'p',
    storageType: 'ambient',
    startDate: moment.utc(new Date()).format(DATE_FORMAT),
    endDate: moment.utc(new Date()).add(3, 'months').format(DATE_FORMAT),
    from: '',
    availableStorage: {},

    isPaymentErrors: computed('model.card.validations.isValid', 'isSubmited', function () {
      var isValid = this.get('model.card.validations.isValid') || this.get('model.bankAccount.validations.isValid');
      var isSubmited = this.get('isSubmited');
      return !isValid && isSubmited;
    }),

    msgPaymentErrors: computed('isPaymentErrors', function () {
      var msg = '* One or more required field is incomplete or incorrect. Please check.';
      return this.get('isPaymentErrors') ? msg : '';
    }),

    servicePalletReceivingEnabled: computedEnable(ENGAGEMENT_PALLET_RECEIVING),
    serviceCartonReceivingEnabled: computedEnable(ENGAGEMENT_CARTON_RECEIVING),
    serviceCartonPickEnabled: computedEnable(ENGAGEMENT_CARTON_PICK),
    servicePalletPickEnabled: computedEnable(ENGAGEMENT_PALLET_PICK),
    serviceUnitPackEnabled: computedEnable(ENGAGEMENT_UNIT_PACK),

    /**
     * This property is used to disable unit pack service on disabling Unit
     * Pick service
     */
    serviceUnitPickEnabled: computed(ENGAGEMENT_UNIT_PICK, {
      get: function get() {
        return this.get(ENGAGEMENT_UNIT_PICK);
      },
      set: function set(key, value) {
        if (value === false) {
          this.set(ENGAGEMENT_UNIT_PACK, false);
          this.set(ENGAGEMENT_UNIT_PACK + '_volume', 0);
        }
        return _setService(ENGAGEMENT_UNIT_PICK, value, this);
      }
    }),

    startDateAsMoment: computedDate('startDate'),
    endDateAsMoment: computedDate('endDate'),
    startDateFormatted: computedMomentFormat('startDateAsMoment', 'MM/DD/YY'),
    endDateFormatted: computedMomentFormat('endDateAsMoment', 'MM/DD/YY'),

    facilityPhoto: computed.reads('model.facility.photos.firstObject'),

    /**
     * Returns min order quantity according to current storage method
     */
    minOrderQuantity: computed({
      get: function get() {
        var storageMethod = this.get('storageMethod');
        if (storageMethod === UOM__PALLET) {
          return this.get('model.facility.min_order_quantity_in_pallets');
        } else if (storageMethod === UOM__CUFT) {
          return this.get('model.facility.min_order_quantity_in_feet');
        }
        return null;
      }
    }),

    /**
     * Shows available volume in facility for selected period in selected UOM
     */
    availableStorageVolume: computed('model.storageAvailability', {
      get: function get() {
        var storageType = this.get('storageType');
        var storageMethod = this.get('storageMethod');
        var uom = 'pallets';
        if (storageMethod === UOM__CUFT) {
          uom = 'feet';
        }
        return this.get('model.storageAvailability.' + storageType + '_' + uom + '_amount');
      }
    }),

    /**
     * Boolean property which shows if storage is available now
     */
    isStorageAvailable: computed('model.storageAvailability', {
      get: function get() {
        var storageType = this.get('storageType');
        return this.get('model.storageAvailability.is_available_' + storageType);
      }
    }),

    inboundServicesSelected: computed(ENGAGEMENT_CARTON_RECEIVING, ENGAGEMENT_PALLET_RECEIVING, {
      get: function get() {
        return this.get(ENGAGEMENT_CARTON_RECEIVING) || this.get(ENGAGEMENT_PALLET_RECEIVING);
      }
    }),

    outboundServicesSelected: computed('model.engagement.service_carton_pick', 'model.engagement.service_pallet_pick', 'model.engagement.service_unit_pick', 'model.engagement.service_unit_pack', {
      get: function get() {
        return this.get('model.engagement.service_carton_pick') || this.get('model.engagement.service_pallet_pick') || this.get('model.engagement.service_unit_pick') || this.get('model.engagement.service_unit_pack');
      }
    }),

    servicesAreValid: computed('outboundServicesSelected', 'inboundServicesSelected', {
      get: function get() {
        return this.get('inboundServicesSelected') && this.get('outboundServicesSelected');
      }
    }),

    showCalculations: computed('model.storageAvailability', {
      get: function get() {
        return this.get('model.storageAvailability.is_available_ambient') !== undefined;
      }
    }),

    appFeePercent: computed.reads('model.calculation.buyer_app_fee_percent'),
    outboundFee: computed.reads('model.calculation.service_outbound_fee'),
    subtotalFee: computed.reads('model.calculation.subtotal_fee'),
    storageFee: computed.reads('model.calculation.storage_fee'),
    inboundFee: computed.reads('model.calculation.service_inbound_fee'),
    daysCount: computed.reads('model.calculation.days_count'),
    totalFee: computed.reads('model.calculation.buyer_total_fee'),
    appFee: computed.reads('model.calculation.buyer_app_fee'),

    storageOriginalRateDaily: computed.reads('model.calculation.storage_original_rate_daily'),
    storageOriginalRate: computed.reads('model.calculation.storage_original_rate'),
    volumeInUom: computed.reads('model.calculation.storage_units'),

    getModelErrors: function getModelErrors(errorKeys) {
      var errors = this.get('model.engagement.errors');
      if (!errors) {
        return [];
      }

      var result = [];
      errorKeys.forEach(function (errorKey) {
        var fieldErrors = errors.get(errorKey);

        if (!fieldErrors) {
          return;
        }

        fieldErrors.forEach(function (error) {
          error.message.forEach(function (message) {
            if (result.indexOf(message) === -1) {
              result.push(message);
            }
          });
        });
      });

      return result;
    },


    inboundServicesErrors: computed('model.engagement.errors.' + PALLET_RECEIVING + '_volume', 'model.engagement.errors.' + CARTON_RECEIVING + '_volume', 'model.engagement.errors.inboundServices', {
      get: function get() {
        var errorKeys = [PALLET_RECEIVING + '_volume', CARTON_RECEIVING + '_volume', 'inboundServices'];
        return this.getModelErrors(errorKeys);
      }
    }),

    outboundServicesErrors: computed('model.engagement.errors.' + UNIT_PICK + '_volume', 'model.engagement.errors.' + UNIT_PACK + '_volume', 'model.engagement.errors.' + CARTON_PICK + '_volume', 'model.engagement.errors.' + PALLET_PICK + '_volume', 'model.engagement.errors.outboundServices', {
      get: function get() {
        var errorKeys = [UNIT_PICK + '_volume', UNIT_PACK + '_volume', CARTON_PICK + '_volume', PALLET_PICK + '_volume', 'outboundServices'];
        return this.getModelErrors(errorKeys);
      }
    }),

    skuInfoErrors: computed('model.engagement.errors.carton_cube_min_volume', 'model.engagement.errors.carton_cube_max_volume', 'model.engagement.errors.carton_weight_min_volume', 'model.engagement.errors.carton_weight_max_volume', 'model.engagement.errors.skuInfoServices', {
      get: function get() {
        var errorKeys = ['carton_cube_min_volume', 'carton_cube_max_volume', 'carton_weight_min_volume', 'carton_weight_max_volume', 'skuInfoServices'];
        return this.getModelErrors(errorKeys);
      }
    }),

    skuErrors: computed('model.engagement.errors.sku_count', {
      get: function get() {
        return this.getModelErrors(['sku_count']);
      }
    }),

    showError: function showError(error) {
      this.get('notify').error(error);
    },
    prepareEngagementModel: function prepareEngagementModel(engagement) {
      engagement.set('start', this.get('startDateAsMoment').format(DATE_FORMAT));
      engagement.set('end', this.get('endDateAsMoment').format(DATE_FORMAT));
      engagement.set('facility', this.get('model.facility'));
      engagement.set('storage_type', this.get('storageType')[0]);
      engagement.set('storage_uom', this.get('storageMethod'));
      engagement.set('storage_units', this.get('volume'));
    },
    prapareRequestParams: function prapareRequestParams(engagement) {
      var params = {
        start: this.get('startDate'),
        end: this.get('endDate'),
        facility: this.get('model.facility.id'),
        storage_uom: this.get('storageMethod'),
        storage_type: this.get('storageType')[0],
        storage_units: this.get('volume')
      };

      var servicesFields = [PALLET_RECEIVING, PALLET_RECEIVING + '_volume', CARTON_RECEIVING, CARTON_RECEIVING + '_volume', CARTON_PICK, CARTON_PICK + '_volume', PALLET_PICK, PALLET_PICK + '_volume', UNIT_PICK, UNIT_PICK + '_volume', UNIT_PACK, UNIT_PACK + '_volume'];

      servicesFields.forEach(function (item) {
        params[item] = engagement.get(item);
      });

      return params;
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.calculate();
    },
    calculate: function calculate() {
      var _this2 = this;

      var engagement = this.get('model.engagement');
      this.prepareEngagementModel(engagement);
      var params = this.prapareRequestParams(engagement);

      engagement.calculate(params).then(function (feesData) {
        _this2.set('model.calculation', feesData);
      });
    },
    isValidForm: function isValidForm() {
      var engagement = this.get('model.engagement');
      if (!this.get('isStorageAvailable')) {
        return false;
      }
      if (!engagement.validate()) {
        this.showError('Please fill in these required fields');
        return false;
      }
      return true;
    },
    showPopupConfirm: function showPopupConfirm() {
      this.set('showModalConfirm', true);
    },
    sendForm: function sendForm() {
      var _this3 = this;

      var engagement = this.get('model.engagement');
      var detail = '';

      this.prepareEngagementModel(engagement);
      engagement.save().then(function () {
        _this3.get('notify').success('Your engagement successfully registered and sent to the' + ' facility\'s supplier');
        _this3.set('showModalRequestSent', true);
      }).catch(function (error) {
        if (engagement.get('errors.length')) {
          var isDetail = true;
          engagement.get('errors').forEach(function (element) {
            if (element.attribute === 'detail' && isDetail) {
              detail = element.message;
            }
            if (element.attribute === 'validation_errors') {
              detail = element.message.errors.toString();
              isDetail = false;
            }
          });
        } else {
          detail = error.message;
        }
        _this3.showError(detail);
      });
    },
    checkDefaultCard: function checkDefaultCard() {
      var defaultCard = this.get('defaultCard');
      if (!!defaultCard) {
        this.sendForm();
      } else {
        this.set('isSubmited', false);
        this.set('openModalPaymentMethod', true);
        var storageHub = this.get('storageHub');
        storageHub.set('handlerAfterCreatePayment', this.get('sendForm').bind(this));
      }
    },

    actions: {
      successModalConfirmation: function successModalConfirmation() {
        var _this4 = this;

        this.set('showModalConfirm', false);
        if (this.get('session.isAuthenticated')) {
          this.checkDefaultCard();
        } else {
          $('#loginModal').modal();
          var storageHub = this.get('storageHub');
          storageHub.set('handlerAfterLogin', function () {
            var tmp = _this4.get('model.engagement');
            // @todo: need refactoring
            setTimeout(function () {
              _this4.set('model.engagement', tmp);
              _this4.checkDefaultCard.call(_this4);
            }, 2000);
          });
        }
      },
      submitPaymentMethod: function submitPaymentMethod() {
        var eventEmitter = this.get('eventEmitter');
        eventEmitter.trigger('submit-form-payment-method');
        this.set('isSubmited', true);
      },
      redirectToRegistration: function redirectToRegistration() {
        return null;
      },
      calculate: function calculate() {
        this.calculate();
      },
      submitEngagement: function submitEngagement() {
        if (this.isValidForm()) {
          this.showPopupConfirm();
        } else {
          return null;
        }
      },
      processCard: function processCard(card, callback) {
        var accountFile = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;

        var _this5 = this;

        var account = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : null;
        var customer = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : null;

        var detail = '';
        if (customer) {
          customer.validate().then(function (_ref) {
            var validations = _ref.validations;

            if (validations.get('isValid')) {
              customer.save().then(function () {
                card.save().then(function () {
                  _this5.set('model.card', _this5.store.createRecord('card'));
                  _this5.set('model.bankAccount', _this5.store.createRecord('bank-account'));
                  callback();
                }).catch(function (e) {
                  if (e.validation_errors.length) {
                    e.validation_errors.forEach(function (element) {
                      if (element.field === 'detail') {
                        detail = element.errors;
                      }
                    });
                  } else {
                    detail = e.detail;
                  }
                  _this5.get('flashMessages').danger(detail);
                });
              });
            }
          });
        }
      }
    }
  });
});