define('ember-components/components/interactive/forms/payment-method/component', ['exports', 'ember-components/constants'], function (exports, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var FLASH_MESSAGES_TIMEOUT = 10000;

  var computed = Ember.computed,
      get = Ember.get,
      set = Ember.set,
      service = Ember.inject.service;


  function computedIsValid(propertyName) {
    return computed('card.validations.attrs.' + propertyName + '.isValid', function () {
      var isValid = this.get('card.validations.attrs.' + propertyName + '.isValid');
      var changes = Object.keys(this.get('card').changedAttributes());
      return !isValid && changes.includes(propertyName) ? 'has-error' : '';
    });
  }

  function computedIsValid2(propertyName) {
    return computed('bankAccount.validations.attrs.' + propertyName + '.isValid', function () {
      var isValid = this.get('bankAccount.validations.attrs.' + propertyName + '.isValid');
      var changes = Object.keys(this.get('bankAccount').changedAttributes());
      return !isValid && changes.includes(propertyName) ? 'has-error' : '';
    });
  }

  exports.default = Ember.Component.extend(Ember.Evented, {
    stripe: service(),
    flashMessages: service(),

    showCustomerForm: Ember.computed('type', 'customer.isActive', 'customer.fullDataIsSaved', function () {
      return this.get('type') === _constants.default.USAGE_TYPE.BUYER && !this.get('customer.isActive') && !this.get('customer.fullDataIsSaved');
    }),

    isErrors: computed('showCardNumberError', 'showCVCError', 'showExpirityError', 'showBankRouteError', 'showAccountNameError', function () {
      var showCVCError = this.get('showCVCError');
      var showCardNumberError = this.get('showCardNumberError');
      var showExpirityError = this.get('showExpirityError');

      var showBankRouteError = this.get('showBankRouteError');
      var showAccountNameError = this.get('showAccountNameError');

      return showExpirityError || showCVCError || showCardNumberError || showBankRouteError || showAccountNameError;
    }),

    msgErrors: '* One or more required field is incomplete or incorrect. Please check.',

    setErrors: function setErrors(vector) {
      set(this, 'showCardNumberError', vector);
      set(this, 'showCVCError', vector);
      set(this, 'showExpirityError', vector);

      set(this, 'showBankRouteError', vector);
      set(this, 'showAccountNumberError', vector);
      set(this, 'showAccountNameError', vector);
    },


    cvcError: computedIsValid('cvc'),
    numberError: computedIsValid('number'),
    expirityError: computedIsValid('expirity'),

    routingNumberError: computedIsValid2('routing_number'),
    accountNameError: computedIsValid2('account_holder_name'),

    titleACH: computed(function () {
      if (this.get('type') === 'buyer') {
        return 'ACH';
      }
      return 'Bank Account';
    }),

    titleCard: computed(function () {
      if (this.get('type') === 'buyer') {
        return 'Credit Card';
      }
      return 'Debit Card';
    }),

    secondLineClass: computed('account.fields_needed.day_of_birth', 'account.fields_needed.ssn_last_4', 'account.fields_needed.business_tax_id', function () {
      var fieldsNumber = this.get('account.fields_needed.day_of_birth') + this.get('account.fields_needed.ssn_last_4') + this.get('account.fields_needed.business_tax_id');

      switch (fieldsNumber) {
        case 1:
          return 'col-md-12';
        case 2:
          return 'col-md-6';
        case 3:
          return 'col-md-4';
        default:
          return 'col-md-4';
      }
    }),

    didInsertElement: function didInsertElement() {
      var _this = this;

      var eventEmitter = this.get('eventEmitter');
      eventEmitter.on('submit-form-payment-method', function () {
        var callback = _this.get('run').bind(_this);
        callback();
      });
      this.set('chosen', 'card');
      if (this.get('account')) {
        this.set('account.account_type', 'company');

        /*
         * TODO: FIX ME
         * API does not return these fields as required even it is first creation.
        */
        this.set('account.fields_needed.day_of_birth', true);
        this.set('account.fields_needed.first_name', true);
        this.set('account.fields_needed.last_name', true);
      }
    },
    willDestroyElement: function willDestroyElement() {
      var eventEmitter = this.get('eventEmitter');
      eventEmitter.off('submit-form-payment-method');
    },
    getCurrentRoute: function getCurrentRoute() {
      var appController = Ember.getOwner(this).lookup('controller:application');
      var path = appController.get('currentPath');
      return Ember.getOwner(this).lookup('route:' + path);
    },
    getCurrentController: function getCurrentController() {
      var route = this.getCurrentRoute();
      return route.get('controller');
    },
    run: function run() {
      var _this2 = this;

      this.set('showCardNumberError', true);
      this.set('showCVCError', true);
      this.set('showExpirityError', true);
      this.set('showBankRouteError', true);
      this.set('showAccountNumberError', true);
      this.set('showAccountNameError', true);
      this.set('showScanError', true);
      this.set('showCompanyError', true);
      this.set('showFirstNameError', true);
      this.set('showLastNameError', true);
      this.set('showDayOfBirthError', true);
      this.set('showPersonalIDError', true);
      this.set('showTaxIDError', true);
      this.set('showCityError', true);
      this.set('showZipError', true);
      this.set('showAddressError', true);
      this.set('showTOSError', true);

      var card = this.get('card');
      var bankAccount = this.get('bankAccount');
      var stripe = get(this, 'stripe');

      var chosenMethod = this.get('chosen');

      card.validate().then(function (_ref) {
        var validations = _ref.validations;

        if (chosenMethod === 'card' && validations.get('isValid')) {
          var simpleCardObject = {
            number: card.get('number'),
            exp_year: card.get('expYear'),
            exp_month: card.get('expMonth'),
            cvc: card.get('cvc')
          };
          var withCurrency = get(_this2, 'withCurrency');
          if (withCurrency) {
            simpleCardObject.currency = 'usd';
          }
          stripe.card.createToken(simpleCardObject).then(function (response) {
            card.set('source', response.id);
            var contoller = _this2.getCurrentController();
            var action = contoller.get('actions.processCard').bind(contoller);
            action(card, function () {
              var storageHub = _this2.get('storageHub');
              var handlerAfterCreatePayment = storageHub.get('handlerAfterCreatePayment');
              if (handlerAfterCreatePayment) {
                handlerAfterCreatePayment();
              }
              _this2.set('open', false);
            }, _this2.get('accountFile'), _this2.get('account.fields_needed.fields_needed') ? _this2.get('account') : null, _this2.get('customer'), function () {
              if (_this2.get('customer.errors.length')) {
                _this2.get('customer.errors').forEach(function (element) {
                  if (element.attribute === 'validation_errors' && element.message.field === 'company') {
                    _this2.set('stripeCompanyValidator', true);
                  }
                });
              }
            });
          }).catch(function (e) {
            var message = '';
            if (e.error) {
              message = e.error.message;
            } else {
              message = e.message;
            }
            get(_this2, 'flashMessages').danger(message, {
              timeout: FLASH_MESSAGES_TIMEOUT
            });
          });
        } else if (chosenMethod === 'ach') {
          if (_this2.get('type') === 'buyer') {
            bankAccount.set('account_holder_name', _this2.get('customer.first_name') + ' ' + _this2.get('customer.last_name'));
          } else {
            bankAccount.set('account_holder_name', _this2.get('account.first_name') + ' ' + _this2.get('account.last_name'));
          }
          bankAccount.validate().then(function (_ref2) {
            var validations = _ref2.validations;

            if (validations.get('isValid')) {
              var simpleBankAccountObject = {
                country: 'us',
                currency: 'usd',
                routing_number: bankAccount.get('routing_number'),
                account_number: bankAccount.get('account_number'),
                account_holder_name: bankAccount.get('account_holder_name'),
                account_holder_type: bankAccount.get('account_holder_type')
              };

              stripe.bankAccount.createToken(simpleBankAccountObject).then(function (response) {
                bankAccount.set('source', response.id);
                bankAccount.set('token', response.id);

                var contoller = _this2.getCurrentController();
                var action = contoller.get('actions.processCard').bind(contoller);
                action(bankAccount, function () {
                  var storageHub = _this2.get('storageHub');
                  var handlerAfterCreatePayment = storageHub.get('handlerAfterCreatePayment');
                  if (handlerAfterCreatePayment) {
                    handlerAfterCreatePayment();
                  }
                  _this2.set('open', false);
                }, _this2.get('accountFile'), _this2.get('account.fields_needed.fields_needed') ? _this2.get('account') : null, _this2.get('customer'));
              }).catch(function (e) {
                get(_this2, 'flashMessages').danger(e.error.message, {
                  timeout: FLASH_MESSAGES_TIMEOUT
                });
              });
            } else {
              _this2.setErrors(true);
            }
          });
        }
      });
    },


    actions: {
      chooseFile: function chooseFile(evt) {
        var file = evt.target.files[0];
        this.set('accountFile.upload', file);
      }
    }
  });
});