define('ember-components/initializers/link-action-enhancer', ['exports', 'ember-link-action/initializers/link-action-enhancer'], function (exports, _linkActionEnhancer) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _linkActionEnhancer.default;
    }
  });
  Object.defineProperty(exports, 'initialize', {
    enumerable: true,
    get: function () {
      return _linkActionEnhancer.initialize;
    }
  });
});