define('ember-components/models/invoices-additional-service', ['exports', 'ember-data', 'ember-components/libs/types-shims', 'ember-components/mixins/model-validator'], function (exports, _emberData, _typesShims, _modelValidator) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend(_modelValidator.default, {
    title: _typesShims.string,
    fee: _typesShims.number,

    invoice: (0, _typesShims.belongsTo)('invoice'),

    validations: {
      title: {
        presence: true,
        length: {
          maximum: {
            value: 10,
            message: 'No more than 255 characters allowed.'
          }
        }
      },
      fee: {
        presence: true,
        numericality: {
          greaterThan: 0
        },
        length: {
          maximum: {
            value: 10,
            message: 'No more than 10 digits in total allowed.'
          }
        },
        custom: {
          validation: function validation(key, value) {
            if (typeof value === 'string') {
              if (value.split('.')[0].length > 8) {
                return false;
              }
              if (value.split('.')[1]) {
                if (value.split('.')[1].length > 2) {
                  return false;
                }
              }
            }
            return true;
          },
          message: function message() {
            return 'Ensure there are no more than 2 decimal places and no ' + 'more than 8 digits before the decimal point.';
          }
        }
      }
    }
  });
});